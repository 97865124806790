import React, {useReducer} from 'react';
import {observer} from 'mobx-react';
import CurrencyFormat from 'react-number-format';
import {languageStore, notificationStore, dictionariesStore} from '../../../stores';
import RegistrationCaptcha from './RegistrationCaptcha';
import {getData, utils} from "../../../actions";

const RegistrationForm = observer(props => {
    const {setPopupIsOpened} = props;
    const {getLanguageUsed} = languageStore;
    const {getLexemes} = dictionariesStore;
    const lexemes = getLexemes[getLanguageUsed].registration_request;
    const {SEND_REQUEST, NOT_A_ROBOT, REQUIRED_FIELDS} = lexemes;

    const fields = [
        {name: 'name', required: true},
        {name: 'business_name', required: true},
        {name: 'address', required: true},
        {name: 'city_town', required: false},
        {name: 'url', required: false},
        {name: 'email', required: true},
        {name: 'phone', required: true},
        {name: 'description', required: false, placeholder: 'YOUR_MESSAGE_PLACEHOLDER'}
    ];

    const reducerRegistration = (state, action) => {
        if (action.hasOwnProperty('isPassedCaptcha')) {
            return {
                ...state,
                ...action
            }
        } else {
            const newFields = {
                ...state.fieldsForm,
                ...action
            }
            const isCompleted = checkedIsCompletedForm(newFields);

            return {
                fieldsForm: {...newFields},
                isCompletedForm: isCompleted,
                isPassedCaptcha: state.isPassedCaptcha
            }
        }
    }

    const checkedIsCompletedForm = (state) => {

        let isCompleted = true;
        for (const key in state) {
            const field = fields.find(item => {
                return item.name === key;
            })
            if (field.required && state[key] === '') {
                isCompleted = isCompleted && false;
            } else if (['email', 'phone'].includes(key)) {
                isCompleted = isCompleted && isValidField(key, state[key])
            }
        }

        return isCompleted;
    };

    const submitRegistrationForm = (event) => {
        event.preventDefault();
        return utils.sendRequest('POST', '/api/v2/app/send-email/registration', stateRegistration.fieldsForm)
            .then(response => {
                setPopupIsOpened(false);
                if (response.data.isResult) {
                    getData.getSuccessfullyNotification({
                        languageStore,
                        dictionariesStore,
                        notificationStore
                    }, 'SUCCESSFUL_SEND_REGISTRATION_FORM')
                } else {
                    getData.getUnsuccessfullyNotification({
                        languageStore,
                        dictionariesStore,
                        notificationStore
                    }, 'UNSUCCESSFUL_SEND_REGISTRATION_FORM')
                }
            })
    };

    const [stateRegistration, setStateRegistration] = useReducer(reducerRegistration, {
        fieldsForm: {
            name: '',
            business_name: '',
            address: '',
            city_town: '',
            url: '',
            email: '',
            phone: '',
            description: ''
        },
        isCompletedForm: false,
        isPassedCaptcha: false
    })

    return <div className="registration-form">
        <form id="registrationForm" className="registration" onSubmit={submitRegistrationForm}>

            {fields.map(field => {
                return <div className="registration-field" key={field.name}>
                    <label className={field.required ? 'is-required' : ''}
                        htmlFor={field.name}>{lexemes[field.name.toUpperCase()]}</label>
                    {field.name === 'description'
                        ?
                        <textarea id={field.name} required={field.required} placeholder={lexemes[field.placeholder]}
                                  value={stateRegistration[field.name]} onChange={event => {
                            setStateRegistration({[field.name]: event.target.value})
                        }}/>
                        :
                        field.name === 'phone'
                            ?
                            <CurrencyFormat id={field.name} format="+# (###) ###-####" mask="_"
                                            value={stateRegistration.phone} onValueChange={(values) => {
                                setStateRegistration({phone: '+' + values.value})
                            }}/>
                            :
                            <input id={field.name} type="text" required={field.required}
                                   value={stateRegistration[field.name]} onChange={event => {
                                setStateRegistration({[field.name]: event.target.value})
                            }}/>
                    }
                </div>
            })}
        </form>

        <div className="captcha-container">
            <RegistrationCaptcha data={{setPassedCaptcha: setStateRegistration, placeholder: NOT_A_ROBOT}}/>
        </div>

        {<button form="registrationForm" className="send-request"
                 disabled={!(stateRegistration.isCompletedForm && stateRegistration.isPassedCaptcha)}>{SEND_REQUEST}</button>}

        <div className="required-fields-message">
            {REQUIRED_FIELDS}
        </div>
    </div>


});

export default RegistrationForm;

function isValidField(field, value) {

    let valid = false;
    let regexpCollection = {
        'email': new RegExp('^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\\.[a-zA-Z]{2,10}$'),
        'phone': new RegExp('^((8|\\+7)[\\- ]?)?(\\(?\\d{3}\\)?[\\- ]?)?[\\d\\- ]{6,10}$')
    }

    let validField = value.match(regexpCollection[field]);
    if (validField) valid = true;

    return valid;
}