import React, {useEffect, useState} from 'react'
import {useLocation} from 'react-router-dom';
import MenuLinks from './MenuLinks';
import PageHeader from './PageHeader';
import AdminRouter from './AdminRouter';
import Header from './Header';
import FeedbackForm from '../FeedbackForm';
import PopupWidget from '../../common/PopupWidget';
import HeadTags from '../../common/HeadTags';
import TokenController from '../TokenController';

export default function AdminMainPage(props) {
    const {payeeId} = props.match.params;
    const location = useLocation();
    const [titleHeader, setTitleHeader] = useState(getActiveTitle(location));

    useEffect(() => {
       setTitleHeader(getActiveTitle(location))
    }, [location]);

    const [popupFeedbackIsOpened, setPopupFeedbackIsOpened] = useState(false);

    return (<>
        <HeadTags data={{isAdmin: true}}/>
        <TokenController/>
        <div className="admin-container">
            <Header payeeId={payeeId} setPopupFeedbackIsOpened={setPopupFeedbackIsOpened}/>
            <div className="admin-page">
                <MenuLinks payeeId={payeeId} titleHeader={titleHeader}/>
                <div className="admin-components">
                    <PageHeader titleHeader={titleHeader}/>
                    <div className="components-container">
                        <AdminRouter/>
                    </div>
                </div>
                <PopupWidget data={{isOpened: popupFeedbackIsOpened, actionClose: setPopupFeedbackIsOpened}}>
                    <FeedbackForm data={{...props.data, setPopupFeedbackIsOpened}}/>
                </PopupWidget>
            </div>
        </div>
    </>)
}

function getActiveTitle(location) {
    let page = location.pathname.split('/')[3];
    if (page === 'project') page += 's'
    return page;
}