import React from 'react';
import {observer} from 'mobx-react';
import {adminActions, urlGenerate, stringActions} from '../../../../actions';
import {languageStore, dictionariesStore, globalSettingsStore, notificationStore} from '../../../../stores';
import ImageContainer from './ImageContainer';
import Logo from '../../../common/Logo'

const GalleryContainer = observer(props => {
    const {payeeId, depNo, logo, isProgram} = props.data;
    const {statePhotos, dispatchPhotos} = props.editedPhotos;
    const {setNewInformation} = props;
    const {getLanguageUsed} = languageStore;
    const {getLexemes} = dictionariesStore;
    const {
        LOGO_OF_FUND,
        PROJECT_LOGO,
        SELECT_FOR_COVER,
        DO_COVER,
        UPLOAD,
        UPLOAD_DOCUMENTS_MSG,
        PRESS_SAVE_FOR_ADD_FILES,
        FILES_FOR_UPLOAD
    } = getLexemes[getLanguageUsed].admin_edit;
    const {getGlobalSettings} = globalSettingsStore;
    const {max_size_image} = getGlobalSettings.download_files;

    let msg = stringActions.setVariablesToString(UPLOAD_DOCUMENTS_MSG, ['size'], {size: max_size_image});

    return <div className="gallery-container">
        <div className="image-logo-container">
            <div className="title-logo">
                {isProgram ? PROJECT_LOGO : LOGO_OF_FUND}
            </div>
            <div className="image-logo">
                {(logo && logo !== '') ?
                    <Logo url={urlGenerate.photoUrl(payeeId, depNo, logo)}/>
                    :
                    <div>{SELECT_FOR_COVER}</div>}
            </div>
            <div className="download-container">
                <label className="custom-file-upload">
                    <input type="file" accept="image/jpeg,image/png" multiple={true} onChange={(e) => {
                        const downloadFiles = adminActions.checkSizeUploadFiles([...e.target.files], max_size_image, {
                            notificationStore,
                            languageStore,
                            dictionariesStore
                        })
                        dispatchPhotos({downloadFilesArr: [...downloadFiles]})
                    }}/>
                    {UPLOAD}
                </label>
            </div>
            <div className="message-gallery">
                {msg}
            </div>
            <div className="message-gallery">
                {PRESS_SAVE_FOR_ADD_FILES}
            </div>
            {(adminActions.checkChangesToDownloadPhotos(statePhotos)) && <div className="message-gallery">
                {stringActions.setVariablesToString(FILES_FOR_UPLOAD, ['count'], {count: statePhotos.downloadFilesArr.length})}
            </div>}
        </div>
        <div className="edit-images-container">
            <div className="all-images">
                {statePhotos.hasOwnProperty('initial') && statePhotos.initial.photos.map(img => {
                    return <ImageContainer key={img} data={{
                        payeeId,
                        depNo,
                        img,
                        isLogo: img === logo,
                        dispatchPhotos,
                        DO_COVER,
                        setNewInformation,
                        isRender: isRenderImageContainer(statePhotos, img)
                    }}/>
                })}
            </div>

        </div>
    </div>
});

export default GalleryContainer;

function isRenderImageContainer(statePhotos, img) {
    if (adminActions.checkChangesToDeletePhotos(statePhotos)) {
        const delImg = statePhotos.deleteImages.find(item => {
            return item === img
        });
        return !delImg;
    } else return true;

}