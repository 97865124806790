import React from 'react';
import HeadTags from '../../common/HeadTags';
import SearchContainer from './SearchContainer';

export default function SearchPage(props) {

    return (
        <React.Fragment>
            <HeadTags/>
            <div className="user-container">
                <div className="search-page-container">
                    <div className="bg-photo-search-page"/>
                    <div className="search-block">
                        <SearchContainer/>
                    </div>
                </div>
            </div>
        </React.Fragment>)
}