import {action, observable} from 'mobx';

class MobileMenuStore {
    @observable isOpenMobileMenu = false;

    @action setIsOpenMobileMenu = isOpen => {
        this.isOpenMobileMenu = isOpen;
    }
}

export default MobileMenuStore;