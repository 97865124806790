import React, {useEffect, useRef} from 'react';
import QRCode from 'qrcode';

export default function QRLink(props) {
    const {mobicashLink} = props.data;
    const qrCode = useRef(null);

    useEffect(() => {
        QRCode.toCanvas(mobicashLink, {errorCorrectionLevel: 'H'}, function (err, canvas) {
            if (err) throw err;
            let qrCodePlace = qrCode.current;
            qrCodePlace.appendChild(canvas);
        })
    },[mobicashLink]);

    return <div className="qr-link-container">
        <div ref={qrCode}/>
    </div>
}
