import React, {useEffect, useState} from 'react';
import {observer} from 'mobx-react';
import {Route, Switch} from 'react-router-dom';
import './scss/App.scss';
import {
    languageStore,
    globalSettingsStore,
    fundInformationStore,
    authorisedAdminStore,
    notificationStore,
    dictionariesStore
} from './stores'
import Header from './components/user/header/Header';
import MainPage from './components/user/mainPage/MainPage';
import FundPage from './components/user/fundPage';
import SearchPage from './components/user/searchPage/SearchPage';
import LogonWidget from './components/logon-widget/LogonWidget';
import DonateResultPage from './components/user/donate/DonateResultPage';
import Footer from './components/user/footer/Footer';
import AdminMainPage from './components/admin/AdminMainPage';
import ErrorPage from './components/common/ErrorPage';
import Notification from './components/common/Notification';
import {cookiesActions} from './actions'

const App = observer(() => {

    const {getLanguageUsed} = languageStore;
    const {getGlobalSettings} = globalSettingsStore;
    const {getAllFundInformationStore} = fundInformationStore;
    const {getAdmin} = authorisedAdminStore;
    const {setNotificationObject} = notificationStore;
    const {getLexemes, getResponseCodes, getCategories} = dictionariesStore;
    const [isRender, setIsRender] = useState(false);

    useEffect(() => {
        if (getLanguageUsed) {
            let lexemes = getLexemes[getLanguageUsed];
            let storeFunds = getAllFundInformationStore(getLanguageUsed);
            let responseCodes = getResponseCodes[getLanguageUsed];
            let categories = getCategories[getLanguageUsed];
            if (getLanguageUsed
                &&
                lexemes && Object.keys(lexemes).length > 0
                &&
                getGlobalSettings && Object.keys(getGlobalSettings).length > 0
                &&
                storeFunds && Object.keys(storeFunds).length > 0
                &&
                responseCodes && Object.keys(responseCodes).length > 0
                &&
                categories && Object.keys(categories).length > 0) {
                setIsRender(true);
            }
        }
    }, [getLanguageUsed, getLexemes, getAllFundInformationStore, getResponseCodes, getCategories, getGlobalSettings])

    if (!cookiesActions.getCookiesOnKey('pview') && isRender) {
        const {COOKIES_MESSAGE, BUTTON_MESSAGE} = getLexemes[getLanguageUsed].cookies_notification;

        setNotificationObject({
            type: 'cookies',
            message: COOKIES_MESSAGE,
            activityButton: {
                message: BUTTON_MESSAGE,
                action: setCookiesService
            }
        });
    }

    return (
        <div className='app-container'>
            {isRender &&
            <React.Fragment>
                {Object.keys(getAdmin).length === 0 && <Header/>}
                <Switch>
                    <Route exact path="/" component={MainPage}/>
                    <Route path="/fund/:payeeId" component={FundPage}/>
                    <Route path="/search" component={SearchPage}/>
                    <Route path="/login" component={LogonWidget}/>
                    <Route exact path="/donate/:result/:payeeId" component={DonateResultPage}/>
                    <Route exact path="/donate/:result/:payeeId/project/:depNo" component={DonateResultPage}/>
                    {Object.keys(getAdmin).length > 0 && <Route path="/admin/:payeeId" component={AdminMainPage}/>}
                    <Route path="/error/:admin" component={ErrorPage}/>
                    <Route><ErrorPage/></Route>
                </Switch>

                {Object.keys(getAdmin).length === 0 && <Footer/>}
            </React.Fragment>
            }
            <Notification/>
        </div>
    );
});

function setCookiesService() {
    if (!cookiesActions.getCookiesOnKey('pview')) {
        cookiesActions.setCookiesOnKey('pview', '1')
    }
}

export default App;
