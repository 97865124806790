import React, {useEffect, useRef, useState} from 'react';
import {observer} from 'mobx-react';
import {languageStore, dictionariesStore, fundInformationStore} from '../../../../stores';
import {urlGenerate} from '../../../../actions';

const ReportsFundComponents = observer(props => {
    const _isMounted = useRef(true);
    const [reports, setReports] = useState(null);

    const {payeeId} = props.match.params;
    const {getReportsOnPayeeId} = fundInformationStore;
    const {getLanguageUsed} = languageStore;
    const {getLexemes} = dictionariesStore;
    const {NOT_REPORTS_FUND} = getLexemes[getLanguageUsed].fund_page;

    useEffect(() => {
        return () => {
            _isMounted.current = false;
        }
    }, [])

    useEffect(() => {
        getReportsOnPayeeId(payeeId)
            .then(reports => {
                if (_isMounted.current) {
                    setReports(reports);
                }
            })

    }, [getLanguageUsed])

    const getReportWithLink = doc => {
        const {fileName, label} = doc;
        const docLink = urlGenerate.reportUrl(payeeId, fileName);
        const nameLink = label[getLanguageUsed] !== '' ? label[getLanguageUsed] : fileName

        return <div className="item" key={fileName}>
            <a href={docLink} target="_blank" rel="noopener noreferrer">{nameLink}</a>
        </div>
    }

    return <div className="documents-component-container">
        <div className="documents-container">
            <div className="content">
                {reports && reports.length > 0 ?
                    reports.map(doc => {
                        return getReportWithLink(doc)
                    })
                    :
                    NOT_REPORTS_FUND}
            </div>
        </div>
    </div>
})

export default ReportsFundComponents;