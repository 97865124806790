import React from 'react';
import {action, computed, observable, runInAction, toJS} from 'mobx';
import {utils} from '../actions';

class DictionariesStore {
    @observable lexemes = {};
    @observable responseCodes = {};
    @observable categories = {};

    constructor() {
        this.getInformation()
            .then(response => {
                runInAction(() => {
                    this.setLexemes(response['lexemes']);
                    this.setResponseCodes(response['responseCodes']);
                    this.setCategories(response['categories'])
                })
            })
    }

    @computed get getLexemes() {
        return toJS(this.lexemes, {recurseEverything: true});
    }

    @action setLexemes = data => {
        this.lexemes = data.reduce((obj, item) => {
            obj[item.lang] = JSON.parse(item.data).lexemes;
            return obj;
        }, {});
    };

    @computed get getResponseCodes() {
        return toJS(this.responseCodes, {recurseEverything: true});
    }

    @action setResponseCodes = data => {
        this.responseCodes = data.reduce((obj, item) => {
            obj[item.lang] = JSON.parse(item.data).codes;
            return obj;
        }, {});
    };

    @computed get getCategories() {
        return toJS(this.categories, {recurseEverything: true});
    }

    getCategoriesFormatted = lang => {
        return this.getCategories[lang]
            .reduce((array, category) => {
                const {value, label, subcategories} = category;
                array.push({value: value, label: <b><span>*</span>{label}</b>});

                if (subcategories.length > 0) {
                    array.push(...subcategories);
                }

                return array;
            }, []);
    };

    @action setCategories = data => {
        this.categories = data.reduce((obj, item) => {
            obj[item.lang] = JSON.parse(item.data).categories;
            return obj;
        }, {});
    };

    getInformation() {
        return new Promise((resolve, reject) => {
            return utils.sendRequest('GET', '/api/v2/app/dictionaries')
                .then(response => {
                    if (response.data.hasOwnProperty('error')) throw new Error(response.data.error);

                    resolve(response.data);
                })
                .catch(error => reject(error))
        })
    }

}

export default DictionariesStore;