import React, {useEffect, useRef, useState} from 'react';
import {observer} from 'mobx-react';
import {languageStore, fundInformationStore, dictionariesStore} from '../../../../stores';
import ErrorPage from '../../../common/ErrorPage';
import PhotoCarousel from '../../../common/PhotoCarousel';
import ShareStory from '../../../common/ShareStory';
import {urlGenerate, stringActions} from '../../../../actions';
import Logo from '../../../common/Logo';

const OneNews = observer(props => {
    const _isMounted = useRef(true);
    const [news, setNews] = useState(null);
    const {payeeId, newsId} = props.match.params;
    const {getNewsOnPayeeIdByLang} = fundInformationStore;
    const {getLanguageUsed} = languageStore;
    const {getLexemes} = dictionariesStore;
    const {months} = getLexemes[getLanguageUsed];
    const {SHARE_NEWS, WAS_UPDATED} = getLexemes[getLanguageUsed].fund_page;

    useEffect(() => {
        return () => {
            _isMounted.current = false;
        }
    }, [])

    useEffect(() => {
        getNewsOnPayeeIdByLang(payeeId, getLanguageUsed)
            .then(newsFund => {
                const _news = newsFund.find(item => {
                    return item.newsId === newsId
                });
                if (!!(_isMounted.current && _news)) {
                    setNews(_news);
                }
            })
    }, [getLanguageUsed])

    return (
        !news ? <ErrorPage/>
            : <div className="content-template">
                <div className="date">
                    <div>{stringActions.getTextDate(getLanguageUsed, months, news.date)}</div>
                    <div className="updated">
                        {news.isUpdated && <div>{WAS_UPDATED}</div>} </div>
                </div>
                <div className="title-content">
                    {news.text.title}
                </div>
                <div className="news-info-block">
                    <div className={"logo-content"}>
                        <Logo url={urlGenerate.newsImgUrl(payeeId, newsId, news.logo)}/>
                    </div>
                    <div className="info-content">
                        <div className="description-content">
                            {news.text.description}
                        </div>
                        <div className="photo-block">
                            <PhotoCarousel data={{payeeId, newsId, logo: news.logo}}/>
                        </div>
                        <div className="share-story">
                            <div className="title-share">{SHARE_NEWS}</div>
                            <ShareStory data={{payeeId: payeeId, newsId: newsId}}/>
                        </div>
                    </div>
                </div>
            </div>
    )
});

export default OneNews;
