import React, {useEffect} from 'react';
import {observer} from 'mobx-react';
import Select from 'react-select';
import {languageStore, dictionariesStore} from '../../stores';

const SelectNews = observer(({selectedSort, setSelectedSort}) => {
    const {getLanguageUsed} = languageStore;
    const {getLexemes} = dictionariesStore;
    const {SORT_SHOW, NEW_FIRST, OLD_FIRST} = getLexemes[getLanguageUsed].fund_page;
    const options = [
        {value: '0', label: NEW_FIRST},
        {value: '1', label: OLD_FIRST},
    ];

    useEffect(() => {
        let newSelectedSort = {
            ...options.find(item => {
                return item.value === selectedSort.value
            })
        }
        setSelectedSort(newSelectedSort);
    }, [getLanguageUsed])

    return (<div className="sort-container">
        <div className="sort-title">
            {SORT_SHOW}
        </div>
        <div className="sort">
            <div className="select-categories">
                <Select
                    options={options}
                    onChange={(event) => {
                        setSelectedSort(event)
                    }}
                    value={selectedSort}
                    isMulti={false}
                />
            </div>
        </div>
    </div>)
});

export default SelectNews;