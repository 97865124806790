import React from 'react'
import {observer} from 'mobx-react';
import {languageStore, dictionariesStore} from '../../../stores';

const Tagline = observer(() => {
    const {getLanguageUsed} = languageStore;
    const {getLexemes} = dictionariesStore;
    const {TAGLINE_FIRST, TAGLINE_SECOND} = getLexemes[getLanguageUsed].main_page.tagline;

    return <div className="tagline">
        <div className="tagline-first">
            {TAGLINE_FIRST}
        </div>
        <br/>
        <div className="tagline-second">
            {TAGLINE_SECOND}
        </div>
    </div>
});

export default Tagline;