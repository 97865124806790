import React, {useState} from 'react';
import {observer} from 'mobx-react';
import {languageStore, dictionariesStore} from '../../../stores';
import PopupWidget from '../../common/PopupWidget';
import DonateForm from './DonateForm';

const DonateButton = observer(props => {

    const {getLanguageUsed} = languageStore;
    const {getLexemes} = dictionariesStore;
    const {DONATE} = getLexemes[getLanguageUsed].donate;

    const [popupIsOpened, setPopupIsOpened] = useState(false);

    return (
        <div className="button-donate-container">
            <button className="btn-donate" onClick={() => {
                setPopupIsOpened(true);
            }}>
                {DONATE}
            </button>

            <PopupWidget data={{isOpened: popupIsOpened, actionClose: setPopupIsOpened}}>
                <DonateForm {...props}/>
            </PopupWidget>
        </div>
    )
});

export default DonateButton;