import React, {useEffect, useReducer, useRef, useState} from 'react';
import {observer} from 'mobx-react';
import PageFooter from '../AdminMainPage/PageFooter';
import EditTemplate from '../editing-templates/EditTemplate';
import SaveChangesButton from '../editing-templates/SaveChangesButton';
import CancelChangesButton from '../editing-templates/CancelChangesButton';
import DeleteProgram from './DeleteProgramButton';
import {
    fundInformationStore,
    globalSettingsStore,
    languageStore,
    notificationStore,
    dictionariesStore,
    authorisedAdminStore
} from '../../../stores';
import {adminActions, getData} from '../../../actions';
import PopupWidget from '../../common/PopupWidget';
import ConfirmAction from '../editing-templates/ConfirmAction';

const ProgramEditPage = observer(props => {
    const _isMounted = useRef(true);
    const {depNo, payeeId} = props.match.params;
    const isNewProgram = useRef(!depNo);
    const initialState = useRef({});
    const {getFundInformation, getProgramPhotos} = fundInformationStore;
    const [state, dispatch] = useReducer(adminActions.reducerEditedInformation, {});
    const [statePhotos, dispatchPhotos] = useReducer(adminActions.reducerEditedPhotos, {});
    const {getLanguageUsed} = languageStore;
    const {getLexemes} = dictionariesStore;
    const {CONFIRM_SAVING_CHANGES, CONFIRM_REVERTING_CHANGES, CONFIRM_DELETING_PROJECT} = getLexemes[getLanguageUsed].admin_edit;

    useEffect(() => {
        return () => {
            _isMounted.current = false;
        }
    }, []);

    useEffect(() => {
        if (depNo && !isNewProgram.current) {
            const informationFund = getFundInformation(payeeId);
            getProgramPhotos({payeeId, depNo})
                .then(responsePhotos => {
                    const project = informationFund.projects.find(item => {
                        return item.depNo === depNo
                    })

                    if (_isMounted.current && project) {
                        initialState.current = JSON.parse(JSON.stringify(Object.assign({}, props.match.params, project)));
                        isNewProgram.current = false;
                        dispatch({isSetInitialState: true, initialData: initialState.current})
                        dispatchPhotos({
                            initial: {
                                photos: responsePhotos,
                                logo: initialState.current.logoProg
                            }
                        });
                    }

                })

        } else if (!depNo && isNewProgram.current) {
            adminActions.getCurrentDate()
                .then(date => {
                    const {getGlobalSettings} = globalSettingsStore;
                    const {languages} = getGlobalSettings;

                    const settings = {
                        settings: {
                            sum: 0,
                            startDateProg: date,
                            expDate: date,
                            status: 'draft',
                            categoriesProg: ['00']
                        }
                    }

                    const information = languages.reduce((resultObj, item) => {
                        const {lang} = item;
                        return {
                            ...resultObj,
                            [lang]: {
                                titleProg: '',
                                descriptionProg: ''
                            }
                        }
                    }, {});

                    const init = JSON.parse(JSON.stringify({
                        ...props.match.params, ...settings, information: information,
                        logoProg: ''
                    }))

                    if (_isMounted.current) {
                        initialState.current = Object.assign({}, init);
                        dispatch({
                            isSetInitialState: true,
                            initialData: init
                        })

                        dispatchPhotos({initial: {photos: [], logo: ''}});
                    }
                })
        }

    }, [fundInformationStore.informationList, fundInformationStore.photoList, isNewProgram.current])

    const [isOpenPopup, setOpenPopup] = useState(false);
    const namePopup = useRef('');
    const setNamePopup = name => {
        namePopup.current = name;
    }
    const getNamePopup = () => {
        return namePopup.current;
    }

    const notConfirm = () => {
        setOpenPopup(false);
        setNamePopup('');
    }

    const confirmSave = () => {
        let emptyLang = adminActions.checkEmptyTextInformation(state.information);
        return submitForm(state, statePhotos, isNewProgram.current)
            .then(depNoResponse => {
                if (isNewProgram.current) {
                    isNewProgram.current = false;
                    props.history.push(`/admin/${payeeId}/project/${depNoResponse}/edit`);
                }

                getData.getSuccessfullyNotificationWithLang({
                    languageStore,
                    notificationStore,
                    dictionariesStore
                }, emptyLang)
            })
            .catch((error) => {
                if (error.message === 'TOKEN_EXPIRED_ERROR') {
                    const {setNotificationObject} = notificationStore;
                    const {getResponseCodes} = dictionariesStore;
                    const message = getResponseCodes[getLanguageUsed]['TOKEN_EXPIRED_ERROR'];
                    const {setAdmin} = authorisedAdminStore;
                    setNotificationObject({
                        type: 'warning',
                        message: message,
                        activityButton: {
                            message: 'Ok',
                            isCloseNotification: false,
                            action: () => {
                                setAdmin({});
                                props.history.push('/login');
                            }
                        }
                    });
                } else {
                    getData.getUnsuccessfullyNotification({
                        languageStore,
                        notificationStore,
                        dictionariesStore
                    }, 'CHANGES_SAVE_UNSUCCESSFULLY')
                }

            })
            .finally(() => {
                notConfirm();
            })
    }

    const confirmRevert = () => {
        dispatch({isSetInitialState: true, initialData: initialState.current})
        if (adminActions.checkChangesToDownloadPhotos(statePhotos) || adminActions.checkChangesToDeletePhotos(statePhotos)) {
            dispatchPhotos({isSetInitialState: true})
        }

        notConfirm();
    }

    const confirmDelete = () => {
        const {deleteProject} = fundInformationStore;

        return deleteProject(props.match.params)
            .then(response => {
                if (response.result) {
                    props.history.push(`/admin/${payeeId}/projects/`);
                    getData.getSuccessfullyNotification({
                        languageStore, notificationStore, dictionariesStore
                    }, 'CHANGES_SAVE_SUCCESSFULLY')
                }
            })
            .catch(error => {
                if (error.message === 'TOKEN_EXPIRED_ERROR') {
                    const {setNotificationObject} = notificationStore;
                    const {getResponseCodes} = dictionariesStore;
                    const message = getResponseCodes[getLanguageUsed]['TOKEN_EXPIRED_ERROR'];
                    const {setAdmin} = authorisedAdminStore;
                    setNotificationObject({
                        type: 'warning',
                        message: message,
                        activityButton: {
                            message: 'Ok',
                            isCloseNotification: false,
                            action: () => {
                                setAdmin({});
                                props.history.push('/login');
                            }
                        }
                    });
                } else {
                    getData.getUnsuccessfullyNotification({
                        languageStore,
                        notificationStore,
                        dictionariesStore
                    }, 'CHANGES_SAVE_UNSUCCESSFULLY')
                }
            })
            .finally(() => {
                notConfirm();
            })

    }

    return (Object.keys(state).length > 0 && <React.Fragment>
        <div className="edit-program-container">
            <form id="edit-program" onSubmit={(event) => {
                event.preventDefault();
                setOpenPopup(true);
                setNamePopup('save')
            }}>
                <EditTemplate data={state} setNewInformation={dispatch} isProgram={true}
                              isFirstOpen={isNewProgram.current}
                              editedPhotos={{statePhotos, dispatchPhotos}}/>
            </form>
        </div>
        <PopupWidget data={{isOpened: isOpenPopup, actionClose: setOpenPopup}}>
            {getNamePopup() === 'save' && <ConfirmAction
                data={{confirmMessage: CONFIRM_SAVING_CHANGES, actionYes: confirmSave, actionNo: notConfirm}}/>}
            {getNamePopup() === 'revert' && <ConfirmAction
                data={{confirmMessage: CONFIRM_REVERTING_CHANGES, actionYes: confirmRevert, actionNo: notConfirm}}/>}
            {getNamePopup() === 'delete' && <ConfirmAction data={{
                confirmMessage: CONFIRM_DELETING_PROJECT, actionYes: confirmDelete, actionNo: notConfirm
            }}/>}
        </PopupWidget>
        <PageFooter>
            <div className="edit-cancel-btns">
                <SaveChangesButton formName={"edit-program"} stateIsDisabledBtn={isDisabledBtn(state, statePhotos)}/>
                <CancelChangesButton data={{
                    setOpenPopupRevert: setOpenPopup,
                    setNamePopup: setNamePopup,
                    stateIsDisabledBtn: isDisabledBtn(state, statePhotos)
                }}/>
            </div>
            {!(isNewProgram.current) && <div className="delete-btn">
                <DeleteProgram data={{setOpenPopupDelete: setOpenPopup, setNamePopup: setNamePopup}}/>
            </div>}

        </PageFooter>
    </React.Fragment>)
});

export default ProgramEditPage;

function saveImagesFromForm(payeeId, depNo, statePhotos) {
    const {saveNewPhotos} = fundInformationStore;
    const url = `/api/v2/fund-data/photos/project/admin/${payeeId}/${depNo}`

    if (adminActions.checkChangesToDownloadPhotos(statePhotos)) {
        return saveNewPhotos({payeeId, depNo, url, newPhotos: statePhotos.downloadFilesArr})
    } else {
        return Promise.resolve()
    }

}

function deleteImagesFromForm(payeeId, depNo, statePhotos) {
    const {deletePhotos} = fundInformationStore;
    if (adminActions.checkChangesToDeletePhotos(statePhotos)) {
        return deletePhotos({payeeId, depNo, deleteImages: statePhotos.deleteImages})
    } else {
        return Promise.resolve()
    }
}

function submitForm(stateInfo, statePhotos, isNewProgram) {
    const {payeeId} = stateInfo;
    let depNo = '';

    if (!isNewProgram) {
        depNo = stateInfo.depNo;
    }

    const {saveNewInformation, saveNewProject} = fundInformationStore;
    const saveInformationFromForm = (stateInfo, isNewProgram) => {
        if (isNewProgram) {
            return saveNewProject(stateInfo)
        } else {
            return saveNewInformation(stateInfo);
        }
    }

    return new Promise((resolve, reject) => {
        return saveInformationFromForm(stateInfo, isNewProgram)
            .then(response => {
                if (isNewProgram) {
                    depNo = response.depNo
                }
                return saveImagesFromForm(payeeId, depNo, statePhotos)
            })
            .then((response) => {
                return deleteImagesFromForm(payeeId, depNo, statePhotos)
            })
            .then(() => {
                resolve(depNo);
            })
            .catch(error => {
                reject(error)
            })
    })
}

function isDisabledBtn(state, statePhotos) {
    if (Object.keys(state).length > 0 || Object.keys(statePhotos).length > 0) {
        if (!state.isInitial) {
            return false;
        } else {
            return !(
                adminActions.checkChangesToDownloadPhotos(statePhotos)
                ||
                adminActions.checkChangesToDeletePhotos(statePhotos))
        }
    } else {
        return true;
    }
}