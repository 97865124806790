import React, {useEffect, useRef, useState} from 'react';
import {observer} from 'mobx-react';
import CurrencyFormat from 'react-number-format';
import {languageStore, dictionariesStore} from '../../../stores';
import {getData, stringActions, utils} from '../../../actions';
import ProgressBar from './ProgressBar';

const CalcTotals = observer(props => {
    const _isMounted = useRef(true);

    const [total, setTotal] = useState();
    const {expDate, sum, defaultCurrency} = props.data;
    const {isFullDress} = props.format;

    let isShowPercent = props.format.isShowPercent !== undefined ? props.format.isShowPercent : expDate !== '' && sum !== '';

    const {getLanguageUsed} = languageStore;
    const {getLexemes} = dictionariesStore;
    const {RAISED, SUPPORTERS, DAYS_LEFT, GOAL, FUNDED_ON, STATUS} = getLexemes[getLanguageUsed].calc_totals;
    const {months, currency_list} = getLexemes[getLanguageUsed];
    const currency = currency_list.find(item => {
        return item.code === defaultCurrency
    });

    useEffect(() => {
        updateTotal();
    }, [])

    const updateTotal = () => {
        calcTotals(props.data)
            .then(response => {
                if (!response.error && Object.keys(response).length > 0) {
                    if (_isMounted.current) {
                        setTotal(getTotalsAndCount(response));
                    }
                }
            })
    }

    let percent = 0;
    if (total && sum !== '') percent = getPercent(total.sum, sum);

    return (total && <div className="calc-totals-container">
            {isFullDress ?
                <div className="full-dress">
                    <div className="title-full-dress">
                        <div className="title-status">
                            {STATUS}
                        </div>
                        <div className="refresh-supporters" onClick={updateTotal}/>
                    </div>
                    <div className="total-support-container">
                        <div className="total">
                            <div className="total-sign">
                                {RAISED}
                            </div>
                            <div className="total-value">
                                <CurrencyFormat value={total.sum} suffix={currency.symbol} displayType="text"
                                                thousandSeparator=" "/>
                            </div>
                        </div>

                        <div className="support">
                            <div className="support-sign">
                                {SUPPORTERS}
                            </div>
                            <div className="support-value">
                                {total.count}
                            </div>
                        </div>
                    </div>
                    {isShowPercent &&
                    <React.Fragment>
                        <div className="funded-on-today">
                            <p>{percent}% {FUNDED_ON}{stringActions.getTextDate(getLanguageUsed.toString(), months)}</p>
                        </div>

                        <ProgressBar data={{percent: percent}}/>

                        <div className="days-goal-container">
                            <div className="days-left">
                                <p><b>{DAYS_LEFT}: </b>{getDaysLeft(expDate)}</p>
                            </div>
                            <div className="goal">
                                <p><b>{GOAL}: </b><CurrencyFormat value={sum} suffix={currency.symbol}
                                                                  displayType="text" thousandSeparator=" "/></p>
                            </div>
                        </div>
                    </React.Fragment>}
                </div>
                :
                <div className="not-full-dress">
                    {isShowPercent && <ProgressBar data={{percent: percent}}/>}
                    <div className="total-support-percent-container">
                        <div className="total-support">
                            <div className="total">
                                <div className="total-value">
                                    <CurrencyFormat value={total.sum} suffix={currency.symbol} displayType="text"
                                                    thousandSeparator=" "/>
                                </div>
                                <div className="total-sign">
                                    {RAISED.toLowerCase()}
                                </div>
                            </div>
                            <div className="support">
                                <p>{total.count} {SUPPORTERS.toLowerCase()}</p>
                            </div>
                        </div>
                        {isShowPercent &&
                        <div className="percent" style={{color: getData.getColorPercent(percent)}}>
                            {percent}%
                        </div>}

                    </div>
                </div>}
        </div>
    )
});

export default CalcTotals;

function calcTotals(data) {
    return new Promise((resolve, reject) => {
        return utils.sendRequest('POST', '/api/v2/app/tx/CalcTotals', data)
            .then(response => {
                if (!response.data.hasOwnProperty('error') && Object.keys(response.data).length > 0) {
                    resolve(response.data.CalcTotals)
                } else {
                    resolve({});
                }
            })
            .catch(error => reject(error))
    })
}

function getTotalsAndCount(data) {
    let _sum = 0;
    let _count = 0;
    let _discount = 0;

    if (Object.keys(data).length > 0) {
        const {SubTotal} = data.Total;
        const {Count, Sum} = data.Total._attributes;
        _count += parseInt(Count);
        _sum += parseFloat(Sum);
        if (SubTotal) {
            if (SubTotal.length && SubTotal.length > 0) {
                _discount = SubTotal.reduce((resultDiscount, item) => {
                    const {Discount} = item._attributes;
                    if (Discount) {resultDiscount += parseFloat(Discount)}
                    return resultDiscount;
                }, 0)
            } else {
                const {Discount} = SubTotal._attributes;
                if (Discount) {_discount = parseFloat(Discount)}
            }
        }
    }

    return {
        sum: (_sum - _discount).toFixed(2).toString(),
        count: _count
    }
}

function getDaysLeft(expDate) {
    const today = new Date();

    if (expDate === '') expDate = new Date();
    else expDate = new Date(expDate);

    const left = expDate - today;

    if (left > 0) {
        return Math.ceil(left / 1000 / 60 / 60 / 24);
    } else return 0;

}

function getPercent(totalSum, requiredSum) {
    if (requiredSum > 0) {
        return ((totalSum / requiredSum) * 100).toFixed(2);
    } else {
        return 100;
    }
}