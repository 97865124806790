import React from 'react';
import {observer} from 'mobx-react';
import {languageStore, dictionariesStore} from '../../../stores';

const SaveChangesButton = observer(props => {
    const {formName, stateIsDisabledBtn} = props;
    const {getLanguageUsed} = languageStore;
    const {getLexemes} = dictionariesStore;

    const {SAVE} = getLexemes[getLanguageUsed].admin_edit;

    return (<button form={formName} disabled={stateIsDisabledBtn} className="save-button">
        {SAVE}
    </button>)
});

export default SaveChangesButton;