import React, {useEffect, useReducer, useRef, useState} from 'react';
import {observer} from 'mobx-react';
import {
    languageStore,
    dictionariesStore,
    fundInformationStore,
    authorisedAdminStore,
    notificationStore, globalSettingsStore
} from '../../../stores';
import {adminActions, getData} from '../../../actions';
import PopupWidget from '../../common/PopupWidget';
import ConfirmAction from '../editing-templates/ConfirmAction';
import PageFooter from '../AdminMainPage/PageFooter';
import SaveChangesButton from '../editing-templates/SaveChangesButton';
import CancelChangesButton from '../editing-templates/CancelChangesButton';
import DragNDropContainer from "./DragNDropContainer";

const TeamEditPage = observer(props => {
    const _isMounted = useRef(true);
    const {payeeId} = props.match.params;
    const teamInitialState = useRef({
        edit: [],
        delete: [],
        add: {},
        isEdited: false
    })
    const [editTeamState, setEditTeamState] = useReducer(teamReducer, {...teamInitialState.current})
    const {getGlobalSettings} = globalSettingsStore;
    const {max_size_document} = getGlobalSettings.download_files;
    const {getLanguageUsed, getSortedLanguageList} = languageStore;
    const {getLexemes} = dictionariesStore;
    const {CONFIRM_SAVING_CHANGES, CONFIRM_REVERTING_CHANGES, UPLOAD} = getLexemes[getLanguageUsed].admin_edit;
    const {getTeamOmPayeeId} = fundInformationStore;

    useEffect(() => {
        return () => {
            _isMounted.current = false;
        }
    }, [])

    useEffect(() => {
        getTeamOmPayeeId(payeeId, getLanguageUsed)
            .then(teamFund => {
                if (_isMounted.current) {
                    teamInitialState.current.edit = teamFund;
                    setEditTeamState({
                        setInitial: {...teamInitialState.current}
                    });
                }
            })

    }, [fundInformationStore.teams, getLanguageUsed])

    const [isOpenPopup, setOpenPopup] = useState(false);
    const namePopup = useRef('');
    const setNamePopup = name => {
        namePopup.current = name;
    }
    const getNamePopup = () => {
        return namePopup.current;
    }

    const notConfirm = () => {
        setOpenPopup(false);
        setNamePopup('');
    }

    const confirmSave = () => {
        return submitForm(editTeamState)
            .then(() => {
                notConfirm();
            })
    }

    const confirmRevert = () => {
        setEditTeamState({
            setInitial:
                {...teamInitialState.current}
        })
        notConfirm();
    }

    const submitForm = (editTeamState) => {
        return uploadPhotoNewMembers(payeeId, editTeamState.add)
            .then(() => {
                return savingEdited(payeeId, editTeamState)
            })
            .then(() => {
                return deletingMembers(payeeId, editTeamState.delete)
            })
            .then(() => {
                getData.getSuccessfullyNotification({
                    languageStore,
                    notificationStore,
                    dictionariesStore
                }, 'CHANGES_SAVE_SUCCESSFULLY')
            })
            .catch(error => {
                if (error.message === 'TOKEN_EXPIRED_ERROR') {
                    const {setNotificationObject} = notificationStore;
                    const {getResponseCodes} = dictionariesStore;
                    const message = getResponseCodes[getLanguageUsed]['TOKEN_EXPIRED_ERROR'];
                    const {setAdmin} = authorisedAdminStore;
                    setNotificationObject({
                        type: 'warning',
                        message: message,
                        activityButton: {
                            message: 'Ok',
                            isCloseNotification: false,
                            action: () => {
                                setAdmin({});
                                props.history.push('/login');
                            }
                        }
                    });
                } else {
                    getData.getUnsuccessfullyNotification({
                        languageStore,
                        notificationStore,
                        dictionariesStore
                    }, 'CHANGES_SAVE_UNSUCCESSFULLY')
                }
            })
    }

    return <React.Fragment>
        <div className="team-edit-container">
            <div className="upload-container">
                <label className="custom-file-upload">
                    <input type="file" accept="image/jpeg,image/png" multiple={true}
                           onChange={(e) => {
                               let uploadFiles = adminActions.checkSizeUploadFiles([...e.target.files], max_size_document, {
                                   notificationStore,
                                   languageStore,
                                   dictionariesStore
                               });

                               uploadFiles = uploadFiles.map(item => {
                                   const info = getSortedLanguageList.reduce((result, itemLang) => {
                                       Object.assign(result, {[itemLang.lang]: {fio: '', position: ''}})
                                       return result;
                                   }, {})
                                   return {
                                       photoFile: item,
                                       info: info
                                   }
                               })
                               if (uploadFiles.length > 0) setEditTeamState({add: [...uploadFiles]})
                           }}/>
                    <div className="upload-btn">+ {UPLOAD}</div>
                </label>
            </div>
            <form id="edit-team" onSubmit={event => {
                event.preventDefault();
                setOpenPopup(true);
                setNamePopup('save')
            }}>
                <DragNDropContainer editTeamState={editTeamState} setEditTeamState={setEditTeamState} />
            </form>
        </div>
        <PopupWidget data={{isOpened: isOpenPopup, actionClose: setOpenPopup}}>
            {getNamePopup() === 'save' && <ConfirmAction
                data={{confirmMessage: CONFIRM_SAVING_CHANGES, actionYes: confirmSave, actionNo: notConfirm}}/>}
            {getNamePopup() === 'revert' && <ConfirmAction
                data={{confirmMessage: CONFIRM_REVERTING_CHANGES, actionYes: confirmRevert, actionNo: notConfirm}}/>}
        </PopupWidget>
        <PageFooter>
            <div className="edit-cancel-btns">
                <SaveChangesButton formName="edit-team" stateIsDisabledBtn={adminActions.checkChangesFromTeam(editTeamState)}/>
                <CancelChangesButton data={{
                    setOpenPopupRevert: setOpenPopup,
                    setNamePopup: setNamePopup,
                    stateIsDisabledBtn: adminActions.checkChangesFromTeam(editTeamState)
                }}/>
            </div>
        </PageFooter>
    </React.Fragment>
});

export default TeamEditPage;

function deletingMembers(payeeId, deleteArr) {
    if (deleteArr.length > 0) {
        const {deleteMemberFromTeam} = fundInformationStore;
        return deleteMemberFromTeam(payeeId, deleteArr)
    } else {
        return Promise.resolve();
    }
}

function savingEdited(payeeId, state) {
    if (adminActions.checkChangesFromTeam(state)) {
        return Promise.resolve()
    } else {
        const {savingEditedMembersTeam} = fundInformationStore;
        return savingEditedMembersTeam(payeeId, state.edit)
    }
}

function uploadPhotoNewMembers(payeeId, addArr) {
    if (Object.keys(addArr).length > 0) {
        const {uploadPhotoNewMembersInTeam} = fundInformationStore;
        return uploadPhotoNewMembersInTeam(payeeId, addArr)
    } else {
        return Promise.resolve();
    }
}

function teamReducer(state, action) {
    let _state = {...state};

    if (action.hasOwnProperty('setInitial')) {
        _state = {
            ...JSON.parse(JSON.stringify(action.setInitial)),
            isEdited: false
        };
    } else {

        if (action.hasOwnProperty('dnd')) {
            const {dragIndex, hoverIndex} = action.dnd;
            const dragItem = _state.edit[dragIndex]
            const hoverItem = _state.edit[hoverIndex]
            const updatedTeam = [..._state.edit]
            updatedTeam[dragIndex] = hoverItem
            updatedTeam[hoverIndex] = dragItem
            _state.edit = updatedTeam;
            _state.isEdited = true;
        }

        if (action.hasOwnProperty('editItem')) {
            const {editItem} = action;
            const {index, lang, type, val} = editItem;
            _state.edit[index].info[lang][type] = val;
            _state.isEdited = true;
        }

        if (action.hasOwnProperty('delete')) {
            _state.delete.push(action.delete);
        }

        if (action.hasOwnProperty('removeFromDelete')) {
            _state.delete = _state.delete.filter(item => {
                return item !== action.removeFromDelete
            })
        }

        if (action.hasOwnProperty('add')) {
            if (action.add.length > 0) {
                action.add.forEach(newMember => {
                    const newMemberId = (_state.edit.length + 1).toString();
                    Object.assign(newMember, {memberId: newMemberId, photo: newMember.photoFile.name.toString()});
                    _state.edit.push(newMember);
                    Object.assign(_state.add, {[newMemberId]: newMember.photoFile})
                })
            } else {
                _state.add = [];
            }
        }

        if (action.hasOwnProperty('removeFromAdd')) {
            delete _state.add[action.removeFromAdd];
            _state.edit = _state.edit.filter(item => {
                return item.memberId !== action.removeFromAdd
            })
        }

    }

    return _state;
}