import React, {useState} from 'react';
import {urlGenerate} from '../../../../actions';
import deleteBtn from '../../../../resources/images/delete.png';

export default function ImageContainer(props) {
    const {img, payeeId, depNo, isLogo, isRender, dispatchPhotos, setNewInformation, DO_COVER} = props.data;
    const [isRenderState, setIsRenderState] = useState(isRender);
    const [isShowBtnSetLogo, setIsShowBtnSetLogo] = useState(false)

    if (isRender !== isRenderState) {
        setIsRenderState(isRender);
    }

    return (isRenderState && <div className={isLogo ? "image-container-logo image-container" : "image-container"} key={img} onMouseEnter={() => setIsShowBtnSetLogo(true)}
                                  onMouseLeave={() => setIsShowBtnSetLogo(false)}>
        <div className="blur-container"/>

        <div className="image-position">
            <img className="image" src={urlGenerate.photoUrl(payeeId, depNo, img)} alt="item"/>
        </div>

        {(isShowBtnSetLogo && !isLogo) && (
            <div className="btn-delete-position">
                <div className="btn-delete-img"
                     onClick={(e) => setToDeleteList(payeeId, depNo, img, dispatchPhotos, setIsRenderState)}>
                    <img src={deleteBtn} alt="delete button"/>
                </div>
            </div>
        )}

        {(isShowBtnSetLogo && !isLogo) && (
            <div className="btn-set-logo-position">
                <div className="btn-set-logo" onClick={() => {setLogo(payeeId, depNo, img, setNewInformation)}}>
                    {DO_COVER}
                </div>
            </div>
        )}

    </div>)
}

function setToDeleteList(payeeId, depNo, img, dispatchPhotos, setIsRenderState) {
    setIsRenderState(false);
    dispatchPhotos({deleteImages: [img]})
}

function setLogo(payeeId, depNo, img, setNewInformation) {
    const keyLogo = depNo ? 'logoProg' : 'logoFund'
    setNewInformation({[keyLogo]: img});
}