import React from 'react';
import {observer} from 'mobx-react';
import {languageStore, dictionariesStore} from '../../../stores';

const ConfirmAction = observer(props => {
    const {confirmMessage, actionYes, actionNo} = props.data;
    const {getLanguageUsed} = languageStore;
    const {getLexemes} = dictionariesStore;
    const {YES_BTN, NO_BTN} = getLexemes[getLanguageUsed].admin_edit;

    return (<div className="confirm-popup-container">
        <div className="confirm-warning-message">
            {confirmMessage}
        </div>
        <div className="confirm-buttons-container">
            <button className="yes-button" onClick={actionYes}>
                {YES_BTN}
            </button>
            <button className="no-button" onClick={actionNo}>
                {NO_BTN}
            </button>
        </div>

    </div>)
})

export default ConfirmAction;