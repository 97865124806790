import React from 'react';
import {observer} from 'mobx-react';
import ReactQuill, {Quill} from 'react-quill';
import {languageStore, dictionariesStore} from '../../../stores';

const icons = Quill.import('ui/icons');

const TextInformation = observer(props => {
    const {data, setNewInformation} = props;
    const {title, description, isProgram, lang} = data;
    const {getLanguageUsed} = languageStore;
    const {getLexemes} = dictionariesStore;
    const {TITLE_OF_FUND, DESCRIPTION_OF_FUND, TITLE_OF_PROGRAM, DESCRIPTION_OF_PROGRAM} = getLexemes[getLanguageUsed].admin_edit;

    let dataNameTitle = isProgram ? 'titleProg' : 'titleFund';
    let dataNameDescription = isProgram ? 'descriptionProg' : 'descriptionFund';

    const modules = React.useMemo(() => ({
        toolbar: {
            container: [
                [{'header': [1, 2, 3, 4, 5, 6, false]}],
                [{'size': ['small', false, 'large', 'huge']}],
                ['bold', 'italic', 'underline', 'strike', 'blockquote'],
                [{'list': 'ordered'}, {'list': 'bullet'}, {'indent': '-1'}, {'indent': '+1'}],
                [{'color': []}, {'background': []}],
                [{'align': []}],
                ['link', 'image', 'video']
            ],
        },
        clipboard: { matchVisual: false }
    }), []);

    icons['code-block'] = '<svg viewBox="0 0 18 18">\n' +
        '<rect class="ql-stroke" height="10" width="12" x="3" y="4"></rect>' +
        '<line class="ql-stroke" x1="7" x2="11" y1="7" y2="11"></line>' +
        '        <path class="ql-even ql-stroke"\n' +
        '              d="M8.9,4.577a3.476,3.476,0,0,1,.36,4.679A3.476,3.476,0,0,1,4.577,8.9C3.185,7.5,2.035,6.4,4.217,4.217S7.5,3.185,8.9,4.577Z"></path>\n' +
        '        <path class="ql-even ql-stroke"\n' +
        '              d="M13.423,9.1a3.476,3.476,0,0,0-4.679-.36,3.476,3.476,0,0,0,.36,4.679c1.392,1.392,2.5,2.542,4.679.36S14.815,10.5,13.423,9.1Z"></path>\n' +
        '    </svg>';

    return (<div className="text-information-container">
        <div className="title-container">
            <label htmlFor={`title-${lang}`}>
                {isProgram ?
                    TITLE_OF_PROGRAM
                    :
                    TITLE_OF_FUND}
            </label>
            <input required={true} id={`title-${lang}`} data-name={dataNameTitle} value={title} onChange={(event) => {
                setNewInformation({information: {[lang]: getObj(event)}})
            }}/>
        </div>
        <div className="description-container">
            <label htmlFor={`description-${lang}`}>
                {isProgram ?
                    DESCRIPTION_OF_PROGRAM
                    :
                    DESCRIPTION_OF_FUND}
            </label>

            <ReactQuill
                required={true}
                theme='snow'
                modules={modules}
                value={description}
                onChange={(val,  delta, source) => {
                    if (source === 'user') {setNewInformation({information: {[lang]: {[dataNameDescription] : val}}})}
                }}
            />
        </div>
    </div>)


});

export default TextInformation;

function getObj(event) {
    return {[event.target.dataset['name']] : event.target.value}
}
