import React, {useState} from 'react';
import {observer} from 'mobx-react';
import {languageStore, dictionariesStore} from '../../../stores';
import PopupWidget from '../../common/PopupWidget';
import RegistrationForm from './RegistrationForm';

const RegistrationButton = observer(props => {
    const {getLanguageUsed} = languageStore;
    const {getLexemes} = dictionariesStore;
    const {START_FUNDRAISING} = getLexemes[getLanguageUsed].registration_request;

    const [popupIsOpened, setPopupIsOpened] = useState(false);

    return <React.Fragment>
        <button className="registration-button" onClick={() => setPopupIsOpened(true)}>
            {START_FUNDRAISING}
        </button>

        <PopupWidget
            data={{isOpened: popupIsOpened, actionClose: setPopupIsOpened}}>
            <RegistrationForm setPopupIsOpened={setPopupIsOpened}/>
        </PopupWidget>
    </React.Fragment>


});

export default RegistrationButton;