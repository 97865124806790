import React, {useEffect, useReducer, useRef} from 'react';
import {observer} from 'mobx-react';
import Slider from 'react-slick';
import SelectCategories from '../../common/SelectCategories';
import {languageStore, fundInformationStore, dictionariesStore} from '../../../stores';
import {getData, utils} from '../../../actions';
import InfoBlock from './InfoBlock';

const SortContainer = observer(props => {
    const _isMounted = useRef(true);

    useEffect(() => {
        return () => {
            _isMounted.current = false;
        }
    }, []);

    const {getLanguageUsed} = languageStore;
    const {getAllFundInformationStore} = fundInformationStore;
    const store = getAllFundInformationStore(getLanguageUsed);

    const settings = {
        dots: true,
        infinite: true,
        variableWidth: true,
        slidesToShow: 1
    };

    // let categoriesFromBlocks = generateCategoriesArr(store);
    // const [renderState, setRenderState] = useReducer(utils.reducerRenderSortContainer, {selectedCategories: [], categoriesFromBlocks: categoriesFromBlocks, lang: getLanguageUsed, renderBlocks: store, blockInformationArr: store})
    //
    // if (renderState.hasOwnProperty('selectedCategories') && renderState.selectedCategories.length > 0 && renderState.lang !== getLanguageUsed) {
    //     const {getCategoriesFormatted} = dictionariesStore;
    //
    //     const newCategories = renderState.selectedCategories.map(item => {
    //         const {value} = item;
    //         return getCategoriesFormatted(getLanguageUsed).find(cat => {return cat.value === value})
    //     });
    //
    //     setRenderState({lang: getLanguageUsed, selectedCategories: newCategories})
    // } else if (renderState.lang !== getLanguageUsed) {
    //     const storeUpd = getAllFundInformationStore(getLanguageUsed);
    //     setRenderState({lang: getLanguageUsed, blockInformationArr: storeUpd})
    // }

    return (
        <React.Fragment>
            <div className="block-container">
                <div className="title-sort-container">
                    {/*<SelectCategories*/}
                    {/*    data={{*/}
                    {/*        categoriesFromBlocks: renderState.categoriesFromBlocks,*/}
                    {/*        setSelectedCategories: setRenderState,*/}
                    {/*        selectedCategories: renderState.selectedCategories*/}
                    {/*    }}/>*/}
                </div>

                <Slider {...settings}>
                    {store.map((fund, index) => {
                        return <div className="block-fund" style={{width: 279}} key={`fund-${index}`}>
                            <InfoBlock data={fund}/>
                        </div>
                    })}
                </Slider>
            </div>
        </React.Fragment>
    )
});

export default SortContainer;

function generateCategoriesArr(blockInformationArr) {
    return blockInformationArr
        .reduce((arr, item) => {
            if (arr.length > 0) {
                for (let category of item.settings.categoriesFund) {
                    if (!arr.includes(category)) {
                        arr.push(category);
                    }
                }
            } else {
                arr.push(...item.settings.categoriesFund)
            }
            return arr
        }, []);
}