import React, {useReducer, useState} from 'react'
import {observer} from 'mobx-react';
import CurrencyFormat from 'react-number-format';
import {languageStore, dictionariesStore, fundInformationStore, notificationStore} from '../../stores';
import imgHidePassword from '../../resources/images/hidePswrd.png';
import imgShowPassword from '../../resources/images/showPswrd.png';
import PopupWidget from '../common/PopupWidget';
import FundListForAuth from './FundListForAuth';
import {adminActions, stringActions, utils} from '../../actions';

const LogonByPhone = observer(props => {
    const {getLanguageUsed} = languageStore;
    const {getLexemes, getResponseCodes} = dictionariesStore;
    const {setNotificationObject} = notificationStore;

    const {PIN, PHONE_NUMBER, SEND_SMS, SMS_CODE, SING_IN, SEND} = getLexemes[getLanguageUsed].logon;

    const [showPassword, setShowPassword] = useState(false);
    const [showFundList, setShowFundList] = useState(false);
    const initialStateLogon = {
        login: '',
        hash: '',
        sms: '',
        initTran: '',
        loginExtRid: '',
        fundListForAuth: [],
        isShowCheckPhoneBtn: true,
        isShowCheckSmsBtn: false
    }
    const [stateLogon, dispatchLogon] = useReducer(adminActions.reducerDefault, {...initialStateLogon});

    return <>
        <form id="sing-in-form">
            <div id="input-phone" className="input-logon">
                <label htmlFor="phone">{PHONE_NUMBER}</label>
                <CurrencyFormat id="phone" format="+# (###) ###-####" mask="_" onValueChange={(values) => {
                    dispatchLogon({login: '+' + values.value})
                }} value={stateLogon.login} required={true}/>
            </div>

            {stateLogon.isShowCheckPhoneBtn &&
            <button name="check-phone" className="btn-active" disabled={stateLogon.login === ''} onClick={(event) => {
                event.preventDefault();
                checkRegistration(stateLogon)
                    .then(data => {
                        dispatchLogon({initTran: data.initTran, isShowCheckPhoneBtn: false, isShowCheckSmsBtn: true});
                    })
                    .catch(error => {
                        console.error(error);
                        setNotificationObject({
                            type: 'warning',
                            message: getResponseCodes[getLanguageUsed][error.message],
                            activityButton: {
                                message: 'Ok',
                                isCloseNotification: true
                            }
                        });
                        dispatchLogon({...initialStateLogon})
                    })
            }}>
                {SEND_SMS}
            </button>}

            {(stateLogon.login !== '' && stateLogon.initTran !== '') && <>
                <div className="input-logon">
                    <label htmlFor="sms-code">{SMS_CODE}</label>
                    <input id="sms-code" type="text" className="login" required={true} maxLength="30"
                           onChange={(event) => {
                               dispatchLogon({sms: event.target.value})
                           }} value={stateLogon.sms}/>
                </div>
                {stateLogon.isShowCheckSmsBtn &&
                <button name="check-sms" className="btn-active" disabled={stateLogon.sms === ''} onClick={(event) => {
                    event.preventDefault();
                    verifyRegistration(stateLogon)
                        .then(data => {
                            dispatchLogon({
                                loginExtRid: data.loginExtRid, isShowCheckSmsBtn: false
                            });
                        })
                        .catch(error => {
                            setNotificationObject({
                                type: 'warning',
                                message: getResponseCodes[getLanguageUsed][error.message],
                                activityButton: {
                                    message: 'Ok',
                                    isCloseNotification: true
                                }
                            });
                            dispatchLogon({...initialStateLogon})
                        })
                }}>{SEND}</button>}
            </>
            }

            {(stateLogon.login !== '' && stateLogon.initTran !== '' && stateLogon.loginExtRid !== '') && <>
                <div className="input-logon">
                    <label htmlFor="password">{PIN}</label>
                    <input id="password"
                           type={showPassword ? "text" : "password"} className="password"
                           required={true} onChange={(event) => {
                        const hash = stringActions.maskingPassword(event.target.value)
                        dispatchLogon({hash: hash})
                    }} value={stringActions.unmaskingPassword(stateLogon.hash)}/>
                    <div className="show-icon"
                         onClick={() => showPassword ? setShowPassword(false) : setShowPassword(true)}>
                        <img src={showPassword ? imgShowPassword : imgHidePassword} alt="password is hide"/>
                    </div>
                </div>
                <button name="check-password" className="btn-active" disabled={stateLogon.hash === ''}
                        onClick={(event) => {
                            event.preventDefault();
                            readPayeeList(stateLogon)
                                .then(data => {
                                    const {list} = data;
                                    let fundList = filterFundForAuth(list);

                                    if (fundList) {
                                        dispatchLogon({fundListForAuth: fundList});
                                        setShowFundList(true);
                                    }
                                })
                                .catch(error => {
                                    setNotificationObject({
                                        type: 'warning',
                                        message: getResponseCodes[getLanguageUsed][error.message],
                                        activityButton: {
                                            message: 'Ok',
                                            isCloseNotification: true
                                        }
                                    });
                                    dispatchLogon({...initialStateLogon})
                                })
                        }}>{SING_IN}</button>
            </>
            }
        </form>
        <PopupWidget data={{isOpened: showFundList, actionClose: setShowFundList}}>
            <FundListForAuth data={stateLogon}/>
        </PopupWidget>
    </>
});

export default LogonByPhone;

function checkRegistration(body) {
    return new Promise((resolve, reject) => {
        return utils.sendRequest('POST', '/api/v2/app/tx/CheckRegistration', {login: body.login})
            .then(responseCheckRegistration => {
                if (responseCheckRegistration.data.hasOwnProperty('error')) throw new Error(responseCheckRegistration.data.error);
                const responseData = responseCheckRegistration.data;
                const {Status} = responseData.CheckRegistration._attributes;

                switch (Status) {
                    case 'Exists': {
                        const { Rrn } = responseData._attributes;
                        resolve({ initTran: Rrn });
                        break;
                    }
                    case 'New':
                    case 'Blocked': {
                      reject(new Error(`RESULT_REGISTRATION_${Status.toUpperCase()}`));
                      break;
                    }
                    default: {
                      reject(new Error(`AUTH_ERROR_BY_PHONE`));
                      break;
                    }
                }

            })
          .catch(error => reject(error))
    })
}

function filterFundForAuth(payeeList) {
    const {getLanguageUsed} = languageStore;
    const {getAllFundInformationStore} = fundInformationStore;

    const allFund = getAllFundInformationStore(getLanguageUsed);

    if (payeeList.length > 0) {
        return payeeList.filter(payee => {
            const rid = payee.Rid._text;
            if (allFund.find(fund => {
                return fund.payeeRid === rid
            })) {
                return payee;
            }
        })
    } else if (payeeList.hasOwnProperty('Rid')) {
        const rid = payeeList.Rid._text;
        if (allFund.find(fund => {
            return fund.payeeRid === rid
        })) {
            return [payeeList];
        }
    }

    return [];
}

function readPayeeList(authData) {
    return new Promise((resolve, reject) => {
        return utils.sendRequest('POST', '/api/v2/app/tx/CheckLogin', {login: authData.login, loginExtRid: authData.loginExtRid, hash: authData.hash} )
            .then(responseCheckLogin => {
                if (responseCheckLogin.data.hasOwnProperty('error')) throw new Error(responseCheckLogin.data.error);
                return authData;
            })
            .then(body => {
                return utils.sendRequest('POST', '/api/v2/app/tx/ReadPayeeList', {login: body.login, loginExtRid: body.loginExtRid, hash: body.hash} )
                    .then(responseReadPayeeList => {
                        if (responseReadPayeeList.data.hasOwnProperty('error')) throw new Error(responseReadPayeeList.data.error);
                        let dataReadPayeeList = responseReadPayeeList.data.ReadPayeeList;
                        const {Payee} = dataReadPayeeList;

                        if (Payee && (Payee.length > 0 || Payee.hasOwnProperty('Rid'))) {
                            resolve({list: Payee});
                        } else {
                            reject(new Error('AUTH_ERROR_BY_PHONE'));
                        }
                    })
            })
            .catch(error => reject(error))
    })
}

function verifyRegistration(authData) {
    return new Promise((resolve, reject) => {
        return utils.sendRequest('POST', '/api/v2/app/tx/VerifyRegistration', {login: authData.login, initTran: authData.initTran, sms: authData.sms})
            .then(responseVerifyRegistration => {
                if (responseVerifyRegistration.data.hasOwnProperty('error')) throw new Error(responseVerifyRegistration.data.error);
                const responseData = responseVerifyRegistration.data.VerifyRegistration;
                const {LoginExtRid} = responseData.Customer;

                resolve({
                    ...authData,
                    loginExtRid: LoginExtRid._text
                });
            })
            .catch(error => reject(error))
    })
}

