import React, {useEffect, useReducer, useRef, useState} from 'react';
import {observer} from 'mobx-react';
import {
    languageStore,
    dictionariesStore,
    fundInformationStore,
    authorisedAdminStore,
    notificationStore
} from '../../../stores';
import {adminActions, getData} from '../../../actions';
import DocumentsOrReportsEdit from '../editing-templates/DocumentsOrReportsEdit';
import PopupWidget from '../../common/PopupWidget';
import ConfirmAction from '../editing-templates/ConfirmAction';
import PageFooter from '../AdminMainPage/PageFooter';
import SaveChangesButton from '../editing-templates/SaveChangesButton';
import CancelChangesButton from '../editing-templates/CancelChangesButton';

const DocumentsEditPage = observer(props => {
    const _isMounted = useRef(true);
    const {payeeId} = props.match.params;
    const documentsInitialState = useRef({
        edit: [],
        delete: [],
        add: [],
        isEdited: false
    });
    const [editDocuments, setEditDocuments] = useReducer(adminActions.reducerEditedFiles, {...documentsInitialState.current})
    const {getLanguageUsed} = languageStore;
    const {getLexemes} = dictionariesStore;
    const {CONFIRM_SAVING_CHANGES, CONFIRM_REVERTING_CHANGES} = getLexemes[getLanguageUsed].admin_edit;

    const {getDocumentsOnPayeeId} = fundInformationStore;

    useEffect(() => {
        return () => {
            _isMounted.current = false;
        }
    }, [])

    useEffect(() => {
        getDocumentsOnPayeeId(payeeId)
            .then(documents => {
                if (_isMounted.current) {
                    documentsInitialState.current.edit = documents;
                    setEditDocuments({
                        setInitial: {...documentsInitialState.current}
                    })
                }
            })
            .catch(error => {
                console.log(error)
            })

    }, [fundInformationStore.documents, getLanguageUsed])


    const [isOpenPopup, setOpenPopup] = useState(false);
    const namePopup = useRef('');
    const setNamePopup = name => {
        namePopup.current = name;
    }
    const getNamePopup = () => {
        return namePopup.current;
    }

    const notConfirm = () => {
        setOpenPopup(false);
        setNamePopup('');
    }

    const confirmSave = () => {
        return submitForm(editDocuments)
            .then(() => {
                notConfirm();
            })
    }

    const confirmRevert = () => {
        setEditDocuments({
            setInitial:
                {...documentsInitialState.current}
        })
        notConfirm();
    }

    const submitForm = (editDocuments) => {
        return editingDocuments(payeeId, editDocuments.edit, editDocuments.isEdited)
            .then(() => {
                return deletingDocuments(payeeId, editDocuments.delete)
            })
            .then(() => {
                return uploadingDocuments(payeeId, editDocuments.add)
            })
            .then(() => {
                getData.getSuccessfullyNotification({
                    languageStore,
                    notificationStore,
                    dictionariesStore
                }, 'CHANGES_SAVE_SUCCESSFULLY')
            })
            .catch(error => {
                if (error.message === 'TOKEN_EXPIRED_ERROR') {
                    const {setNotificationObject} = notificationStore;
                    const {getResponseCodes} = dictionariesStore;
                    const message = getResponseCodes[getLanguageUsed]['TOKEN_EXPIRED_ERROR'];
                    const {setAdmin} = authorisedAdminStore;
                    setNotificationObject({
                        type: 'warning',
                        message: message,
                        activityButton: {
                            message: 'Ok',
                            isCloseNotification: false,
                            action: () => {
                                setAdmin({});
                                props.history.push('/login');
                            }
                        }
                    });
                } else {
                    getData.getUnsuccessfullyNotification({
                        languageStore,
                        notificationStore,
                        dictionariesStore
                    }, 'CHANGES_SAVE_UNSUCCESSFULLY')
                }
            })
    }

    return <React.Fragment>
        <div className="reports-edit-container">
            <form id="edit-documents" onSubmit={event => {
                event.preventDefault();
                setOpenPopup(true);
                setNamePopup('save')
            }}>
                <DocumentsOrReportsEdit payeeId={payeeId} state={editDocuments} setState={setEditDocuments} isReports={false}/>
            </form>
        </div>
        <PopupWidget data={{isOpened: isOpenPopup, actionClose: setOpenPopup}}>
            {getNamePopup() === 'save' && <ConfirmAction
                data={{confirmMessage: CONFIRM_SAVING_CHANGES, actionYes: confirmSave, actionNo: notConfirm}}/>}
            {getNamePopup() === 'revert' && <ConfirmAction
                data={{confirmMessage: CONFIRM_REVERTING_CHANGES, actionYes: confirmRevert, actionNo: notConfirm}}/>}
        </PopupWidget>
        <PageFooter>
            <div className="edit-cancel-btns">
                <SaveChangesButton formName="edit-documents" stateIsDisabledBtn={adminActions.checkChangesFromReportsOrDocuments(editDocuments)}/>
                <CancelChangesButton data={{
                    setOpenPopupRevert: setOpenPopup,
                    setNamePopup: setNamePopup,
                    stateIsDisabledBtn: adminActions.checkChangesFromReportsOrDocuments(editDocuments)
                }}/>
            </div>
        </PageFooter>
    </React.Fragment>
});

export default DocumentsEditPage;

function editingDocuments(payeeId, editArr, isEdited) {
    if (editArr.length > 0 && isEdited) {
        const {savingEditedDocuments} = fundInformationStore;
        return savingEditedDocuments(payeeId, editArr)
    } else {
        return Promise.resolve();
    }
}

function deletingDocuments(payeeId, deleteArr) {
    if (deleteArr.length > 0) {
        const {deleteDocuments} = fundInformationStore;
        return deleteDocuments(payeeId, deleteArr)
    } else {
        return Promise.resolve();
    }
}

function uploadingDocuments(payeeId, addArr) {
    if (addArr.length > 0) {
        const {uploadNewDocuments} = fundInformationStore;
        return uploadNewDocuments(payeeId, addArr)
    } else {
        return Promise.resolve();
    }
}