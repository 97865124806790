import React, {useState} from 'react';
import {observer} from 'mobx-react';
import BannerScript from './BannerScript';
import BannerDemo from './BannerDemo';
import {languageStore, fundInformationStore, dictionariesStore, globalSettingsStore} from '../../../stores';
import {urlGenerate, stringActions, utils} from '../../../actions';

const BannerContainer = observer(props => {
    const {payeeId, depNo} = props.data;

    const {getLanguageUsed} = languageStore;
    const {getFundInformation} = fundInformationStore;
    const {getGlobalSettings} = globalSettingsStore;
    const {getLexemes} = dictionariesStore;

    const lexemes = getLexemes[getLanguageUsed].share_story;
    const {current_host} = getGlobalSettings;
    const fundInformation = getFundInformation(payeeId, getLanguageUsed);

    const dataBanner = dataGenerateBanner(depNo, fundInformation);
    dataBanner.current_host = current_host;
    dataBanner.reloadTime = getGlobalSettings.reloadTime;
    dataBanner.pathLogoService = urlGenerate.photoUrl('', '', 'logoMobiCashDB_red.svg');
    dataBanner.urlStory = urlGenerate.pageUrl(payeeId, depNo, current_host);

    let requiredParametersForApi = depNo ? ['payeeId', 'depNo', 'startDate', 'expDate'] : ['payeeId', 'startDate', 'expDate'];
    let parameters = requiredParametersForApi.map(item => {
        return {param: item, value: dataBanner[item]}
    });
    dataBanner.api = urlGenerate.apiUrl('CalcTotals', current_host, parameters);

    const [total, setTotal] = useState('');

    if (total === '') {
        utils.sendRequest('POST', dataBanner.api)
            .then(response => {
                if (!response.data.hasOwnProperty('error')) {
                    let sum = (response.data.data.total.sum).toFixed(2).toString();
                    if (sum.indexOf('.') < 0) {
                        sum += '.00'
                    }
                    setTotal(sum)
                }
            })
    }

    dataBanner.total = total;

    return <div className="banner-container">
        <BannerScript data={dataBanner} lexemes={lexemes}/>
        <BannerDemo data={dataBanner} lexemes={lexemes}/>
    </div>
});

export default BannerContainer;

function dataGenerateBanner(depNo, fundInformation) {
    const {payeeId} = fundInformation;
    let expDate = '';

    if (depNo === '' || !depNo) {
        const {logoFund} = fundInformation
        const {titleFund, descriptionFund} = fundInformation.information;
        const {startDateFund} = fundInformation.settings;

        return {
            payeeId: payeeId,
            depNo: depNo,
            title: titleFund,
            description: stringActions.substringText(descriptionFund, 90),
            pathImageBanner: urlGenerate.photoUrl(payeeId, depNo, logoFund),
            startDate: startDateFund,
            expDate: expDate
        };
    } else {
        let programInfo = fundInformation.projects.find(itemProgram => {
            return itemProgram.depNo === depNo
        });

        return {
            payeeId: payeeId,
            depNo: depNo,
            title: programInfo.information.titleProg,
            description: stringActions.substringText(programInfo.information.descriptionProg, 90),
            pathImageBanner: urlGenerate.photoUrl(payeeId, depNo, programInfo.logoProg),
            startDate: programInfo.settings.startDateProg,
            expDate: programInfo.settings.expDate
        }
    }
}