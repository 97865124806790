import {action, computed, observable, toJS} from 'mobx';

class NotificationStore {
    @observable notificationObject = {};

    @computed get getNotificationObject() {
        return toJS(this.notificationObject, {recurseEverything: true});
    };

    /**
     * @param obj = {
     *     type: 'string': success, warning, information
     *     message: 'string'
     *     activityButton?: {
     *         action: 'function
     *         message: 'string'
     *         isCloseNotification: 'boolean'
     *     }
     * }
     */
    @action setNotificationObject = obj => {
        this.notificationObject = {
            ...obj
        }
    };

}

export default NotificationStore;