import React from 'react';

export default function Logo(props) {
    const {url} = props;
    return <div className="logo-container">
        <div className="blur-container" style={{background: `url(${url}) 50%/ cover border-box padding-box`}}/>
        <div className="image-position">
            <img className="logo" src={url}/>
        </div>

    </div>
}