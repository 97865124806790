import {action, computed, observable, runInAction} from 'mobx';
import {cookiesActions, utils} from '../actions'

class LanguageStore {
    @observable languageUsed = '';
    @observable languageList = []

    constructor() {
        this.getInformation()
            .then(response => {
                this.setLanguageList(this.sortedLanguageList(response.languages))
                const cookiesLang = cookiesActions.getCookiesOnKey('data_lang_DB');
                if (cookiesLang) {
                    this.setLanguageUsedAction(cookiesLang)
                } else {
                    runInAction(() => {
                        let lang = (response.languages.find(lang => lang.is_default === true)).lang;
                        this.setLanguageUsedAction(lang);
                    })
                }
            })
    }

    @computed get getLanguageUsed() {
        return this.languageUsed;
    }

    @computed get getSortedLanguageList() {
        return this.sortedLanguageList(this.languageList)
    }

    @computed get getSortedLanguageListStr() {
        return this.sortedLanguageList(this.languageList).reduce((resultStr, item, index) => {
            let _resultStr = resultStr;
            if (_resultStr !== '' && index < (this.languageList.length)) {_resultStr += ', '}
            _resultStr += item.lexeme
            return _resultStr
        }, '')
    }

    @computed get getDefaultLang() {
        return this.languageList.find(lang => {return lang.is_default})
    }

    @action setLanguageUsedAction = (lang) => {
        this.languageUsed = lang;
    };

    @action setLanguageList = (list) => {
        this.languageList = list;
    }

    getInformation() {
        return new Promise((resolve, reject) => {
            return utils.sendRequest('GET', '/api/v2/app/config')
                .then(response => {
                    if (response.data.hasOwnProperty('error')) throw new Error(response.data.error);
                    resolve(response.data);
                })
                .catch(error => reject(error))
        })
    }

    sortedLanguageList(list) {
        return list.reduce((resultArr, item) => {
            let _resultArr = [];
            if (resultArr.length > 0) {
                _resultArr.push(...resultArr)
            }

            const {lang} = item;
            if (lang === this.languageUsed) {
                _resultArr.unshift(item)
            } else {
                _resultArr.push(item)
            }

            return _resultArr;
        }, [])
    }
}

export default LanguageStore;