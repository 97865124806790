import React from 'react';
import CurrencyFormat from 'react-number-format';

export default function Bill(props) {
    const {date, message, donator, sum, currency} = props.data;

    return <div className="bill-container">
        <div className="bill-table">
            <div className="bill-row">
                <div className="date-bill">
                    {date}
                </div>
            </div>
            <div className="bill-row">
                <div className="donator-bill">
                    {donator}
                </div>
                <div className="sum-bill">
                    <CurrencyFormat value={sum} suffix={currency.symbol} displayType="text" thousandSeparator=" "/>
                </div>
            </div>
            {message !== "" &&
            <div className="bill-row">
                <div className="message-bill">
                    {message}
                </div>
            </div>
            }
        </div>
    </div>
}