import React, {useState} from 'react'
import {observer} from 'mobx-react';
import {languageStore, dictionariesStore} from '../../stores';
import LogonByPhone from './LogonByPhone';
import LogonByLogin from './LogonByLogin';

const Logon = observer(props => {
        const {getLanguageUsed} = languageStore;
        const {getLexemes} = dictionariesStore;

        const {SIGN_IN_PORTAL, BY_LOGIN, BY_PHONE_NUMBER} = getLexemes[getLanguageUsed].logon;

        const [isAuthByLogin, setIsAuthByLogin] = useState(true)

        return <div className="logon-container">
            <div className="logon-title">
                {SIGN_IN_PORTAL}
            </div>

            <form id="choice-auth">
                <div className="form-group">
                    <div className="choice-auth">
                        <div className="choice">
                            <input id="choice-login" type="radio" name="choice-auth" checked={isAuthByLogin}
                                   onChange={() => setIsAuthByLogin(!isAuthByLogin)}/>
                            <label htmlFor="choice-login">{BY_LOGIN}</label>
                        </div>
                        <div className="choice">
                            <input id="choice-phone" type="radio" name="choice-auth" checked={!isAuthByLogin}
                                   onChange={() => setIsAuthByLogin(!isAuthByLogin)}/>
                            <label htmlFor="choice-phone">{BY_PHONE_NUMBER}</label>
                        </div>
                    </div>
                </div>
            </form>

            {
                isAuthByLogin ?
                    <LogonByLogin/>
                    :
                    <LogonByPhone/>
            }
        </div>
    }
);

export default Logon;