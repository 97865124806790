import React from 'react';
import {observer} from 'mobx-react';
import Select from 'react-select';
import {languageStore, dictionariesStore} from '../../../stores';
import {getData} from '../../../actions';

const FundSettings = observer(props => {
    const {data, setNewInformation} = props;
    const {defaultCurrency, archProgram, lastOper, countLastOper, categoriesFund} = data.settings;
    const {getLanguageUsed} = languageStore;
    const {getCategoriesFormatted, getLexemes} = dictionariesStore;

    const {currency_list, admin_edit} = getLexemes[getLanguageUsed];
    const {CATEGORIES, CHOOSE_DEFAULT_CURRENCY, SHOW_ARCHIVED_PROJECTS, SHOW_LAST_PAYMENTS, QUANTITY_LAST_PAYMENTS} = admin_edit;
    const {ALL} = getLexemes[getLanguageUsed].sort_categories;

    const allCategories = getCategoriesFormatted(getLanguageUsed);

    return (<div className="fund-settings-container">
        <div className="categories-edit">
            <label htmlFor="select-categories">{CATEGORIES}</label>
            <Select options={allCategories}
                    value={getData.usedCategories({dictionariesStore, languageStore}, categoriesFund)} isMulti={true}
                    placeholder={ALL}
                    onChange={(event) => {
                        setNewInformation({settings: {categoriesFund: getData.getCategoryValues(event)}})
                    }}/>
        </div>
        <div className="settings-fund-edit">
            <div className="default-currency-edit">
                <label htmlFor="select-currency">{CHOOSE_DEFAULT_CURRENCY}</label>
                <select id="select-currency" value={defaultCurrency} onChange={(event) => {
                    setNewInformation({settings: {defaultCurrency: event.target.value}})
                }
                }>
                    {currency_list.map(item => {
                        const {code, title, alpha3} = item;
                        return (<option key={code} value={code}>{title} ({alpha3})</option>)
                    })}
                </select>
            </div>
            <div className="checkbox-form">
                <input id="arch-program" name="ArchProg" type="checkbox" checked={archProgram} onChange={(event) => {
                    setNewInformation({settings: {archProgram: event.target.checked}})
                }
                }/>
                <label htmlFor="arch-program">
                    {SHOW_ARCHIVED_PROJECTS}
                </label>
            </div>
            <div className="checkbox-form">
                <input id="last-operation" name="LastOperation" type="checkbox" checked={lastOper}
                       onChange={(event) => {
                           setNewInformation({settings: {lastOper: event.target.checked}})
                       }
                       }/>
                <label htmlFor="last-operation">
                    {SHOW_LAST_PAYMENTS}
                </label>
            </div>
            <div className="count-last-operation-edit">
                <label htmlFor="count-last-operation">{QUANTITY_LAST_PAYMENTS}</label>
                <input id="count-last-operation" name="CountLatOperation" type="number"
                       value={countLastOper} onChange={(event) => {
                    setNewInformation({settings: {countLastOper: event.target.value}})
                }
                }/>
            </div>
        </div>


    </div>)
});

export default FundSettings;