import {stringActions, utils} from './index';

class AdminActions {

    checkSizeUploadFiles(filesArr, max_size_document, stories) {
        const _max_size_byte = max_size_document * 1024 * 1024

        let uploadFiles = [];
        let unUploadFiles = [];

        filesArr.forEach(file => {
            if (file.size < _max_size_byte) {
                uploadFiles.push(file);
            } else {
                unUploadFiles.push(file);
            }
        });

        if (unUploadFiles.length > 0) {
            const {notificationStore, languageStore, dictionariesStore} = stories
            const {setNotificationObject} = notificationStore;
            const {getLanguageUsed} = languageStore;
            const {getLexemes} = dictionariesStore;
            const {UNUPLOAD_DOCUMENTS_MSG} = getLexemes[getLanguageUsed].admin_edit;

            let msg = stringActions.setVariablesToString(UNUPLOAD_DOCUMENTS_MSG, ['size'], {size: max_size_document});

            msg += '<ul>'

            unUploadFiles.forEach(file => {
                msg += '<li>';
                msg += file.name;
                msg += '</li>'
            })

            msg += '</ul>'

            setNotificationObject({
                type: 'information',
                message: msg,
                activityButton: {
                    message: 'Ok',
                    isCloseNotification: true
                }
            });
        }

        return uploadFiles;
    }

    /**
     * @param information {[lang]: {[nameFiled]: ''}}
     * @returns {*[]}
     */
    checkEmptyTextInformation(information) {
        const languages = Object.keys(information);
        return languages.reduce((resultArr, lang) => {
            const fieldArr = Object.keys(information[lang]);

            fieldArr.forEach(field => {

                if (/title/.test(field) || /description/.test(field)) {
                    if (information[lang][field].trim() === '' && !resultArr.includes(lang)) {
                        resultArr.push(lang)
                    }
                }
            })

            return resultArr;
        }, []);
    }

    checkChangesToDownloadPhotosNews(state) {
        return (state.hasOwnProperty('add') && Object.keys(state.add).length > 0)
    }

    checkChangesToDownloadPhotos(state) {
        return (state.hasOwnProperty('downloadFilesArr') && Object.keys(state.downloadFilesArr).length > 0)
    }

    checkChangesToDeletePhotos(state) {
        return (state.hasOwnProperty('deleteImages') && Object.keys(state.deleteImages).length > 0)
    }

    checkChangesFromReportsOrDocuments(state) {
        return !(state.delete.length > 0 || state.add.length > 0 || state.isEdited)
    }

    checkChangesFromTeam(state) {
        return !(state.delete.length > 0 || Object.keys(state.add).length > 0 || state.isEdited)
    }

    checkChangesToDeletePhotosNews(state) {
        return (state.hasOwnProperty('delete') && Object.keys(state.delete).length > 0)
    }

    getCurrentDate() {
        return new Promise((resolve, reject) => {
            return utils.sendRequest('GET', `/api/v2/app/current-date`, {})
                .then(response => {
                    resolve(response.data);
                })
                .catch(error => {
                    reject(error);
                })
        })
    }

    getLogoFromStatePhotos(statePhotos) {
        return statePhotos.hasOwnProperty('initial') && (statePhotos.initial).hasOwnProperty('logo') ? statePhotos.initial.logo : ''
    }

    getRedirectUrlOnAdminPage(payeeId, fundInformationStore) {
        const {getFundInformation} = fundInformationStore;
        const store = getFundInformation(payeeId);
        if (store) {
            return `/admin/${payeeId}/projects`;
        } else {
            return `/admin/${payeeId}/about/info/edit`
        }
    }

    getLineNumber(editIndex, addIndex = 0) {
        return addIndex + editIndex + 1
    }

    reducerDefault(state, action) {
        return {
            ...state,
            ...action
        };
    }

    reducerEditedInformation(state, action) {
        let _state = {};

        if (action.hasOwnProperty('isSetInitialState') && action.isSetInitialState) {
            _state = {
                ...JSON.parse(JSON.stringify(action.initialData)),
                isInitial: true
            };

        } else {
            _state = JSON.parse(JSON.stringify(state));

            if (Object.keys(_state).length > 0) {
                if (action.hasOwnProperty('information')) {
                    const languagesAction = Object.keys(action.information);
                    languagesAction.forEach(lang => {
                        if (_state.hasOwnProperty('information')) {

                            if (_state.information.hasOwnProperty(lang)) {
                                _state.information[lang] = {
                                    ..._state.information[lang],
                                    ...action.information[lang]
                                }
                            } else {
                                _state.information = {
                                    ..._state.information,
                                    [lang]: {...action.information[lang]}
                                }
                            }

                        } else {
                            _state = {
                                ..._state,
                                information: {
                                    [lang]: {...action.information[lang]}
                                }
                            }
                        }
                    })
                }

                if (action.hasOwnProperty('settings')) {
                    _state.settings = Object.assign(_state.settings, action.settings)
                }

                if (action.hasOwnProperty('logoFund')) {
                    _state.logoFund = action.logoFund
                }

                if (action.hasOwnProperty('logoProg')) {
                    _state.logoProg = action.logoProg
                }

            } else {
                _state = {
                    ...action
                }
            }

            _state = {
                ..._state,
                isInitial: false
            }
        }

        if (action.hasOwnProperty('depNo')) {
            _state = {
                ..._state,
                depNo: action.depNo
            }
        }
        return _state;
    }

    reducerEditedPhotos(state, action) {
        let _state = {
            ...state
        }

        if (action.hasOwnProperty('isSetInitialState') && action.isSetInitialState) {
            return {
                initial: _state.initial
            }
        }

        if (Object.keys(state).length === 0 || action.hasOwnProperty('initial')) {
            return {
                ...action
            };
        }

        if (action.hasOwnProperty('downloadFilesArr')) {
            if (_state.hasOwnProperty('downloadFilesArr')) {
                _state.downloadFilesArr.push(...action.downloadFilesArr);
            } else {
                _state = {
                    ..._state,
                    downloadFilesArr: [...action.downloadFilesArr]
                }
            }
        }

        if (action.hasOwnProperty('deleteImages')) {
            if (action.deleteImages.length > 0) {
                if (_state.hasOwnProperty('deleteImages')) {
                    _state.deleteImages.push(...action.deleteImages)
                } else {
                    _state = {
                        ..._state,
                        deleteImages: [...action.deleteImages]
                    }
                }
            } else {
                _state = {
                    ..._state,
                    deleteImages: []
                }
            }
        }
        return _state;
    }

    /**
     *
     * @param state
     * @param action:
     * .setInitial = {}; объект с начальным состоянием; пример объекта: {edit: [], delete: [], add: [], isEdited: false}
     * .editItem - Object {[idItem]: {[lang]: 'String'}}; редактирование name по языку элемента списка edit;
     * .add - []; список элементов, готовых к загрузке; каждый элемент: Object {file: 'File', fileName: 'String', label: {[lang]: 'String'}}
     * .removeFromAdd - String, имя файла; из списка add удалить элемент;
     * .editAddItem - Object {[idItem]: {[lang]: 'String'}}; редактирование name по языку элемента из списка add по языку
     */

    reducerEditedFiles(state, action) {
        let _state = {...state};

        if (action.hasOwnProperty('setInitial')) {
            _state = {
                ...JSON.parse(JSON.stringify(action.setInitial)),
                isEdited: false
            };
        } else {

            if (action.hasOwnProperty('editItem')) {
                const {editItem} = action
                const indexItem = Object.keys(editItem)[0];
                const lang = Object.keys(editItem[indexItem])[0];
                _state.edit[indexItem].label[lang] = editItem[indexItem][lang];
                _state.isEdited = true;
            }

            if (action.hasOwnProperty('delete')) {
                _state.delete.push(action.delete);
            }

            if (action.hasOwnProperty('removeFromDelete')) {
                _state.delete = _state.delete.filter(item => {
                    return item !== action.removeFromDelete
                })
            }

            if (action.hasOwnProperty('add')) {
                if (action.add.length > 0) {
                    _state.add = _state.add.concat(action.add)
                } else {
                    _state.add = [];
                }
            }

            if (action.hasOwnProperty('removeFromAdd')) {
                _state.add = _state.add.filter(item => {
                    return item.fileName !== action.removeFromAdd
                })
            }

            if (action.hasOwnProperty('editAddItem')) {
                const {editAddItem} = action
                const indexItem = Object.keys(editAddItem)[0];
                const lang = Object.keys(editAddItem[indexItem])[0];
                _state.add[indexItem].label[lang] = editAddItem[indexItem][lang];
            }

        }

        return _state;
    }

    reducerEditedNews(state, action) {
        let _state = {};

        if (action.hasOwnProperty('setInitial')) {
            _state = {
                info: {
                    ...JSON.parse(JSON.stringify(action.setInitial.info)),
                },
                photoList: action.setInitial.photoList,
                isInitial: true
            };
        } else {
            _state = {...JSON.parse(JSON.stringify(state)), isInitial: false};

            if (action.hasOwnProperty('text')) {
                const lang = Object.keys(action.text)[0];
                const textField = Object.keys(action.text[lang])[0];
                _state.info.text[lang][textField] = action.text[lang][textField]
            } else if (action.hasOwnProperty('logo')) {
                _state.info.logo = action.logo;
            }
        }

        return _state;
    }

    reducerEditedPhotosNews(state, action) {
        let _state = {
            ...state
        }

        if (action.hasOwnProperty('setInitial')) {
            _state = {
                ...action.setInitial
            }
        }

        if (action.hasOwnProperty('add')) {
            if (action.add.length > 0) {
                _state.add = _state.add.concat(action.add)
            } else {
                _state.add.length = 0;
            }
        }

        if (action.hasOwnProperty('delete')) {
            if (action.delete.length > 0) {
                _state.delete = _state.delete.concat(action.delete)
            } else {
                _state.delete.length = 0;
            }
        }
        return _state;
    }

}

export default AdminActions;