import React, {useEffect, useRef, useState} from 'react';
import {observer} from 'mobx-react';
import {languageStore, dictionariesStore, fundInformationStore} from '../../../../../stores';
import Member from './Member';

const Team = observer(props => {
    const _isMounted = useRef(true);
    const [team, setTeam] = useState(null)
    const {payeeId} = props.match.params;
    const {getTeamOmPayeeId} = fundInformationStore;
    const {getLanguageUsed} = languageStore;
    const {getLexemes} = dictionariesStore;
    const {NO_FUND_TEAM} = getLexemes[getLanguageUsed].fund_page;

    useEffect(() => {
        return () => {
            _isMounted.current = false;
        }
    }, [])

    useEffect(() => {
        getTeamOmPayeeId(payeeId)
            .then(teamFund => {
                if (_isMounted.current) {
                    setTeam(teamFund);
                }
            })
    }, [])


    return <div className="team-components">
        {(team && Object.keys(team).length > 0) ? <div className="members">{team.map(item => {
                return <Member key={item.memberId} member={item} payeeId={payeeId} lang={getLanguageUsed}/>
            })}</div>
            : <div className="no-team-message">{NO_FUND_TEAM}</div>
        }
    </div>
});

export default Team;