import {action, observable, toJS} from 'mobx';
import {utils} from '../actions';

class ReadPayeeStore {
    /**
     * @type {
     * {[payeeId]: {
     *     [lang]: {ReadPayeeObj}
     * }}}
     */
    @observable informationStore = {};

    @action setReadPayeeAction = (payeeId, lang, data) => {
        let newInformation = {};
        if (this.informationStore.hasOwnProperty(payeeId)) {
            Object.assign(newInformation, this.informationStore[payeeId], {[lang]: data})
        } else {
            Object.assign(newInformation, {[lang]: data})
        }

        this.informationStore = Object.assign({}, this.informationStore,  {[payeeId]: newInformation})

    };

    getFundReadPayee = (payeeId, lang) => {
        return new Promise((resolve, reject) => {
            if (this.informationStore.hasOwnProperty(payeeId) && this.informationStore[payeeId].hasOwnProperty(lang)) {
                resolve({
                    payeeId: payeeId,
                    information: toJS(this.informationStore[payeeId][lang], {recurseEverything: true})
                })
            } else {
                this.getInformation(payeeId, lang)
                    .then(result => {
                        let data = {payeeId: payeeId, information: result};
                        this.setReadPayeeAction(payeeId, lang, result);
                        resolve(data);
                    })
                    .catch(error => reject(error));
            }
        });

    };

    getInformation = (payeeId, lang) => {
        return new Promise((resolve, reject) => {
            return utils.sendRequest('POST', '/api/v2/app/tx/ReadPayee', {payeeId, lang})
                .then(response => {
                    if (!response.data.hasOwnProperty('error')) {
                        resolve(response.data.ReadPayee);
                    }
                })
                .catch(error => reject(error))
        })
    };

}

export default ReadPayeeStore;