import React from 'react';
import {observer} from 'mobx-react';
import parse from 'html-react-parser';
import {languageStore, fundInformationStore, dictionariesStore} from '../../../../../stores';

const GetHelp = observer(props => {
    const {payeeId} = props.match.params;
    const {getLanguageUsed} = languageStore;
    const {getFundInformation} = fundInformationStore;
    const {information} = getFundInformation(payeeId, getLanguageUsed);
    const {howGetHelp} = information;
    const {getLexemes} = dictionariesStore;
    const {NOT_INFO_ABOUT_GET_HELP} = getLexemes[getLanguageUsed].fund_page;

    return <div className="get-help-container">
        <div>
            {howGetHelp ? parse(howGetHelp) : NOT_INFO_ABOUT_GET_HELP}
        </div>
    </div>
});

export default GetHelp;