import React, {useCallback} from 'react';
import {observer} from 'mobx-react';
import ProjectDND from './ProjectDND';

const DragNDropContainer = observer(({editPriorityState, setEditPriorityState}) => {

    const moveListItem = useCallback(
        (dragIndex, hoverIndex) => {
            setEditPriorityState({dnd: {dragIndex, hoverIndex}})
        },
        [editPriorityState.blockInformationArr]
    );

    return <React.Fragment>
        <div className="table-container">
            <table>
                <tbody>
                {editPriorityState.blockInformationArr.map((project, index) => {
                    return <ProjectDND key={project.depNo} index={index} project={project} moveListItem={moveListItem}/>
                })}
                </tbody>
            </table>
        </div>
    </React.Fragment>
});

export default DragNDropContainer;