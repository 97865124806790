import React from 'react';
import {observer} from 'mobx-react';
import DocumentLinks from '../../common/DocumentLinks'
import ChangeLanguage from '../../common/ChangeLanguage';
import {languageStore, dictionariesStore} from '../../../stores';

const Footer = observer(props => {

    const {getLanguageUsed} = languageStore;
    const {getLexemes} = dictionariesStore;

    let lexemes = getLexemes[getLanguageUsed];

    return lexemes && (<React.Fragment>
            <div className='footer'>
                <div className="title-footer">
                    <div className="copyright-block">
                        <div className="logo-service">
                            <div className="logo-mobicash"/>
                            <div className="logo-db"/>
                        </div>
                        <p>{lexemes.footer.RIGHTS_RESERVED}</p>
                    </div>
                    <div className="links-language-block">
                        <DocumentLinks orientation="horizontal"/>
                        <ChangeLanguage isAdminPage={false}/>
                    </div>

                </div>
            </div>
    </React.Fragment>)
})

export default Footer;