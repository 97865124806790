import React from 'react';
import {observer} from 'mobx-react';
import {Link} from 'react-router-dom';
import CalcTotals from '../../../common/calcTotals/CalcTotals';
import Logo from '../../../common/Logo';
import {urlGenerate, stringActions} from '../../../../actions';
import {languageStore, dictionariesStore} from '../../../../stores';

const ProjectInfo = observer((props) => {
    const {payeeId, depNo, title, description, dataCalcTotals, photo, categories, status} = props.data;

    let logo = urlGenerate.photoUrl(payeeId, depNo, photo);

    const {getLanguageUsed} = languageStore;
    const {getLexemes} = dictionariesStore;
    const {CHARITY_IS_OVER} = getLexemes[getLanguageUsed].project_page;

    let _description = stringActions.substringText(description, 150)

    return <div className="program-container" data-categories={categories}>
        <div className="program-block-container">
            <Link to={`/fund/${payeeId}/project/${depNo}/about`}>
                <div className="program-block">
                    <div className="project-photo">
                        <Logo url={logo}/>
                    </div>
                    <div className="program-info">
                        <div className="info-content">
                            {status === 'active' ?
                                <div className="calc-totals-block"><CalcTotals data={dataCalcTotals}
                                                                               format={{isFullDress: false}}/></div>
                                :
                                <div className="charity-is-over">{CHARITY_IS_OVER}</div>}
                            <div className="title">
                                {title}
                            </div>
                            <div className="info">{_description}</div>
                        </div>
                    </div>
                </div>
            </Link>
        </div>
    </div>
})

export default ProjectInfo;