import React from 'react';
import {Route, Switch} from 'react-router-dom';
import ProjectContainer from './ProjectContainer';
import HeadTags from '../../common/HeadTags';

export default function ProjectPage(props) {
    const {payeeId, depNo} = props.match.params;

    return (
        <React.Fragment>
            <HeadTags data={{payeeId: payeeId, depNo: depNo}}/>
            <Switch>
                <Route exact path="/fund/:payeeId/project/:depNo/about" component={ProjectContainer}/>
            </Switch>
        </React.Fragment>
    )
};
