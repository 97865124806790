import React, {useEffect, useRef, useState} from 'react';
import {observer} from 'mobx-react';
import {fundInformationStore} from '../../stores';
import {urlGenerate} from '../../actions';
import AwesomeSlider from 'react-awesome-slider';
import withAutoplay from 'react-awesome-slider/dist/autoplay';
import AwesomeSliderStyles from 'react-awesome-slider/src/styles';

const AutoplaySlider = withAutoplay(AwesomeSlider);

const PhotoCarousel = observer(props => {
    const {payeeId, depNo, newsId, logo} = props.data;
    const {getProgramPhotos, getFundPhotos, getPhotosNews} = fundInformationStore;
    const [photos, setPhotos] = useState([]);
    const [indexSelected, setIndexSelected] = useState();

    const _isMounted = useRef(true);
    useEffect(() => {
        return () => {
            _isMounted.current = false;
        }
    }, []);

    useEffect(() => {
        const getPhotosFromStore = () => {
            if (depNo) {
                return getProgramPhotos({payeeId, depNo})
            } else if (newsId) {
                return getPhotosNews(payeeId, newsId);
            } else {
                return getFundPhotos(payeeId)
            }
        }

        getPhotosFromStore()
            .then(response => {
                if (_isMounted.current) {
                    let _response = depNo ? response : response.filter(item => {
                        return item !== logo
                    })

                    let linksPhotos = _response.map((item, index) => {
                        if (item === logo) setIndexSelected(parseInt(index));
                        if (newsId) {
                            return urlGenerate.newsImgUrl(payeeId, newsId, item)
                        } else {
                            return urlGenerate.photoUrl(payeeId, depNo, item)
                        }
                    });

                    if (!indexSelected) setIndexSelected(0);
                    setPhotos(linksPhotos);
                }
            });
    }, [])

    return (photos.length > 0 && <React.Fragment>
        <AutoplaySlider cssModule={AwesomeSliderStyles} mobileTouch={true} selected={indexSelected} play={true}
                        interval={5000}>
            {photos.map(photo => {
                return <div key={photo} data-src={photo}/>
            })}
        </AutoplaySlider>
    </React.Fragment>)
});

export default PhotoCarousel;