export default class StringActions {

    setVariablesToString(data, keysArr, valueObjects) {
        let script = data;
        keysArr.forEach(key => {
            if (script.indexOf(`{:${key}}`) >= 0) {
                let regexp = new RegExp(`\\\{:${key}\\\}`, 'g');
                script = script.replace(regexp, valueObjects[key])
            }
        });

        return script;
    }

    replaceTagsInText(str) {
        return str.replace(/<[^<]+>/g, '');
    }

    substringText(text, lengthStr) {
        const regexpSubstring = /[,.?;:<>\\ ]$/
        let _lengthStr = lengthStr;
        const textWithoutTags = this.replaceTagsInText(text);
        const _text = textWithoutTags.substr(0, _lengthStr);

        if (regexpSubstring.test(_text)) {
            _lengthStr -= 1;
        }

        return textWithoutTags.substr(0, _lengthStr) + '...'
    }

    getTextDate(lang, months, date = new Date()) {
        const _date = new Date(date);
        const year = _date.getFullYear();
        const day = _date.getDate();
        const textMonth = months[_date.getMonth().toString()];

        if (lang === 'ru') {
            return `${day} ${textMonth} ${year}`;
        } else {
            return `${textMonth} ${day} ${year}`;
        }
    }

    maskingPassword(pass) {
        const _pass = '#bm#' + pass + '#em#';
        return btoa(encodeURIComponent(_pass))
    }

    unmaskingPassword(hash) {
        let pass = Buffer.from(hash, 'base64').toString('binary')
        return pass.substring(8, pass.length - 8)
    }
}