import {utils} from './index';

class TokenActions {

    destroyToken(headerOptions) {
        return utils.sendRequest('DELETE', '/api/v2/token', null, headerOptions)
            .then(responseToken => {
                if (!responseToken.data.isResult) throw new Error(responseToken.data.message);
                return true;
            })
            .catch(error => {
                console.log(error)
                return true;
            })
    }

    getAuthToken(data) {
        return new Promise((resolve, reject) => {
            return utils.sendRequest('POST', '/api/v2/token', data)
                .then(responseToken => {
                    if (!responseToken.data.isResult) throw new Error(responseToken.data.message);
                    resolve(responseToken.data.message);
                })
                .catch(error => {
                    reject(error)
                })
        })
    }

    getNewToken(data) {
        return data.newToken;
    }
}

export default TokenActions;