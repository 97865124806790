import React, {useReducer, useRef, useState} from 'react';
import {observer} from 'mobx-react';
import {
    languageStore,
    dictionariesStore,
    fundInformationStore,
    authorisedAdminStore,
    notificationStore
} from '../../../stores';
import {getData} from '../../../actions';
import PopupWidget from '../../common/PopupWidget';
import ConfirmAction from '../editing-templates/ConfirmAction';
import PageFooter from '../AdminMainPage/PageFooter';
import SaveChangesButton from '../editing-templates/SaveChangesButton';
import CancelChangesButton from '../editing-templates/CancelChangesButton';
import DragNDropContainer from './DragNDropContainer';

const ProjectsPriorityEditPage = observer(props => {
    const {payeeId} = props.match.params;
    const {getLanguageUsed} = languageStore;

    const {getFundInformation} = fundInformationStore;
    const store = getFundInformation(payeeId, getLanguageUsed);
    const activeProjects = getData.listProgramOnStatus(store, 'active');
    const {projectPriorityList} = store;
    const blockInformationArr = getData.getActiveProjectsByPriority(activeProjects, projectPriorityList);

    const priorityInitialState = useRef({
        blockInformationArr: blockInformationArr,
        isInitial: true
    })
    const [editPriorityState, setEditPriorityState] = useReducer(priorityReducer, priorityInitialState.current);

    const {getLexemes} = dictionariesStore;
    const {CONFIRM_SAVING_CHANGES, CONFIRM_REVERTING_CHANGES_ABOUT_PRIORITY} = getLexemes[getLanguageUsed].admin_edit;

    const [isOpenPopup, setOpenPopup] = useState(false);
    const namePopup = useRef('');
    const setNamePopup = name => {
        namePopup.current = name;
    }
    const getNamePopup = () => {
        return namePopup.current;
    }

    const notConfirm = () => {
        setOpenPopup(false);
        setNamePopup('');
    }

    const confirmSave = () => {
        return submitForm(editPriorityState)
            .then(() => {
                notConfirm();
            })
    }

    const confirmRevert = () => {
        setEditPriorityState({
            setInitial: priorityInitialState.current
        })
        notConfirm();
    }

    const submitForm = (editPriorityState) => {
        return savingEdited(payeeId, editPriorityState)
            .then(() => {
                getData.getSuccessfullyNotification({
                    languageStore,
                    notificationStore,
                    dictionariesStore
                }, 'CHANGES_SAVE_SUCCESSFULLY')
            })
            .catch(error => {
                if (error.message === 'TOKEN_EXPIRED_ERROR') {
                    const {setNotificationObject} = notificationStore;
                    const {getResponseCodes} = dictionariesStore;
                    const message = getResponseCodes[getLanguageUsed]['TOKEN_EXPIRED_ERROR'];
                    const {setAdmin} = authorisedAdminStore;
                    setNotificationObject({
                        type: 'warning',
                        message: message,
                        activityButton: {
                            message: 'Ok',
                            isCloseNotification: false,
                            action: () => {
                                setAdmin({});
                                props.history.push('/login');
                            }
                        }
                    });
                } else {
                    getData.getUnsuccessfullyNotification({
                        languageStore,
                        notificationStore,
                        dictionariesStore
                    }, 'CHANGES_SAVE_UNSUCCESSFULLY')
                }
            })
    }

    return <React.Fragment>
        <div className="priority-edit-container">
            <form id="edit-priority" onSubmit={event => {
                event.preventDefault();
                setOpenPopup(true);
                setNamePopup('save')
            }}>
                <DragNDropContainer editPriorityState={editPriorityState} setEditPriorityState={setEditPriorityState}/>
            </form>
        </div>
        <PopupWidget data={{isOpened: isOpenPopup, actionClose: setOpenPopup}}>
            {getNamePopup() === 'save' && <ConfirmAction
                data={{confirmMessage: CONFIRM_SAVING_CHANGES, actionYes: confirmSave, actionNo: notConfirm}}/>}
            {getNamePopup() === 'revert' && <ConfirmAction
                data={{confirmMessage: CONFIRM_REVERTING_CHANGES_ABOUT_PRIORITY, actionYes: confirmRevert, actionNo: notConfirm}}/>}
        </PopupWidget>
        <PageFooter>
            <div className="edit-cancel-btns">
                <SaveChangesButton formName="edit-priority" stateIsDisabledBtn={editPriorityState.isInitial}/>
                <CancelChangesButton data={{
                    setOpenPopupRevert: setOpenPopup,
                    setNamePopup: setNamePopup,
                    stateIsDisabledBtn: editPriorityState.isInitial
                }}/>
            </div>
        </PageFooter>
    </React.Fragment>
});

export default ProjectsPriorityEditPage;

function savingEdited(payeeId, state) {
    const {saveNewInformation} = fundInformationStore;
    const {blockInformationArr} = state;

    const projectPriorityList = blockInformationArr.map(item => {
        return item.depNo;
    })

    return saveNewInformation({payeeId, projectPriorityList})
}

function priorityReducer(state, action) {
    let _state = {...state};

    if (action.hasOwnProperty('setInitial')) {
        _state = {
            ...JSON.parse(JSON.stringify(action.setInitial)),
            isInitial: true
        };
    } else {

        if (action.hasOwnProperty('dnd')) {
            const {dragIndex, hoverIndex} = action.dnd;
            const dragItem = _state.blockInformationArr[dragIndex]
            const hoverItem = _state.blockInformationArr[hoverIndex]
            const updatedItem = [..._state.blockInformationArr]
            updatedItem[dragIndex] = hoverItem
            updatedItem[hoverIndex] = dragItem
            _state.blockInformationArr = updatedItem;
            _state.isInitial = false;
        }
    }

    return _state;
}

