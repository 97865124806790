import React, {useReducer, useRef, useState, useEffect} from 'react'
import {observer} from 'mobx-react';
import {Tab, Tabs, TabList, TabPanel} from 'react-tabs';
import {
    authorisedAdminStore,
    dictionariesStore,
    fundInformationStore,
    languageStore,
    notificationStore
} from '../../../stores';
import PopupWidget from '../../common/PopupWidget';
import ConfirmAction from '../editing-templates/ConfirmAction';
import PageFooter from '../AdminMainPage/PageFooter';
import SaveChangesButton from '../editing-templates/SaveChangesButton';
import CancelChangesButton from '../editing-templates/CancelChangesButton';
import DeleteNews from './DeleteNewsButton';
import {adminActions, getData, stringActions} from '../../../actions';
import BlockNewsInformation from './BlockNewsInformation';
import GalleryNewsContainer from './GalleryNewsContainer';

const NewsEditPage = observer(props => {
    const {newsId, payeeId} = props.match.params;
    const _isMounted = useRef(true);
    const newNews = useRef({
        isNewNews: !newsId, newNewsId: ''
    });
    const initialState = useRef({info: {}, photoList: [], isInitial: true});
    const initialStatePhotos = useRef({delete: [], add: []})
    const [state, dispatch] = useReducer(adminActions.reducerEditedNews, {...initialState.current});
    const [stateEditPhotos, dispatchPhotos] = useReducer(adminActions.reducerEditedPhotosNews, {...initialStatePhotos.current});

    const {getNewsOnPayeeId, getPhotosNews} = fundInformationStore;
    const {getLanguageUsed, getSortedLanguageList, getSortedLanguageListStr} = languageStore;
    const {getLexemes} = dictionariesStore;
    const {
        CONFIRM_SAVING_CHANGES,
        CONFIRM_REVERTING_CHANGES,
        CONFIRM_DELETING_NEWS,
        GALLERY,
        RECOMMENDED_MSG
    } = getLexemes[getLanguageUsed].admin_edit;

    const allLanguages = getSortedLanguageList.map(item => {
        return item.lang
    });

    useEffect(() => {
        return () => {
            _isMounted.current = false;
        }
    }, []);

    useEffect(() => {
        if (newNews.current.isNewNews && !newNews.current.newNewsId) {
            let data = {
                logo: '',
                isUpdated: false,
                text: {}
            };

            data.text = allLanguages.reduce((resultObj, lang) => {
                return Object.assign(resultObj, {
                    [lang]: {
                        title: '',
                        description: '',
                    }
                })
            }, {});

            initialState.current.info = JSON.parse(JSON.stringify(data))
            dispatch({
                setInitial: initialState.current
            })
        } else {
            const _newsId = newsId ? newsId : newNews.current.newNewsId;
            getNewsOnPayeeId(payeeId)
                .then(responseNewsOnPayeeId => {
                    getPhotosNews(payeeId, _newsId)
                        .then(responsePhotoNews => {

                            if (_isMounted.current) {

                                let data = responseNewsOnPayeeId.find((news) => news.newsId === _newsId)

                                initialState.current.info = JSON.parse(JSON.stringify(data));
                                initialState.current.photoList = responsePhotoNews
                                dispatch({
                                    setInitial: {
                                        info: data,
                                        photoList: responsePhotoNews
                                    }
                                })

                                dispatchPhotos({
                                    setInitial: initialStatePhotos.current
                                })
                            }
                        })
                })
        }
    }, [getLanguageUsed, fundInformationStore.news, newNews.current.isNewNews, fundInformationStore.photoListNews])

    const [isOpenPopup, setOpenPopup] = useState(false);
    const namePopup = useRef('');
    const setNamePopup = name => {
        namePopup.current = name;
    }
    const getNamePopup = () => {
        return namePopup.current;
    }

    const notConfirm = () => {
        setOpenPopup(false);
        setNamePopup('')
    }

    const confirmSave = () => {
        return submitForm(state, stateEditPhotos)
    }

    const confirmRevert = () => {
        dispatch({
            setInitial: initialState.current
        });
        dispatchPhotos({
            setInitial: initialStatePhotos.current
        })
        notConfirm();
    }

    const confirmDelete = () => {
        const {deleteNews} = fundInformationStore;
        return deleteNews(props.match.params)
            .then(response => {
                if (response.result) {
                    props.history.push(`/admin/${payeeId}/news/`);
                    getData.getSuccessfullyNotification({
                        languageStore, notificationStore, dictionariesStore
                    }, 'CHANGES_SAVE_SUCCESSFULLY')
                }
            })
            .catch(error => {
                if (error.message === 'TOKEN_EXPIRED_ERROR') {
                    const {setNotificationObject} = notificationStore;
                    const {getResponseCodes} = dictionariesStore;
                    const message = getResponseCodes[getLanguageUsed]['TOKEN_EXPIRED_ERROR'];
                    const {setAdmin} = authorisedAdminStore;
                    setNotificationObject({
                        type: 'warning',
                        message: message,
                        activityButton: {
                            message: 'Ok',
                            isCloseNotification: false,
                            action: () => {
                                setAdmin({});
                                props.history.push('/login');
                            }
                        }
                    });
                } else {
                    getData.getUnsuccessfullyNotification({
                        languageStore,
                        notificationStore,
                        dictionariesStore
                    }, 'CHANGES_SAVE_UNSUCCESSFULLY')
                }
            })
            .finally(() => {
                notConfirm();
            })
    }

    const submitForm = (state, statePhotos) => {
        const {savingEditedNews, createNews} = fundInformationStore;
        const actionSave = () => {
            return newNews.current.isNewNews ? createNews(payeeId, state.info) : savingEditedNews(payeeId, newsId, state.info)
        }

        const deletePhotos = (payeeId, newsId, deleteArr) => {
            if (deleteArr.length > 0) {
                const {deletePhotosFromNewsById} = fundInformationStore;
                return deletePhotosFromNewsById(payeeId, newsId, deleteArr)
            } else {
                return Promise.resolve();
            }
        }

        const uploadPhotos = (payeeId, newsId, addArr) => {
            if (addArr.length > 0) {
                const {uploadPhotosForNewsById} = fundInformationStore;
                return uploadPhotosForNewsById(payeeId, newsId, addArr)
            } else {
                return Promise.resolve();
            }
        }
        let emptyLang = adminActions.checkEmptyTextInformation(state.info);

        actionSave()
            .then((response) => {
                if (newNews.current.isNewNews) {
                    const {newsNewsId} = response;
                    newNews.current.newNewsId = newsNewsId
                }
                return deletePhotos(payeeId, newsId, statePhotos.delete)
            })
            .then(() => {
                if (newNews.current.isNewNews) {
                    return uploadPhotos(payeeId, newNews.current.newNewsId, statePhotos.add)
                } else {
                    return uploadPhotos(payeeId, newsId, statePhotos.add)
                }
            })
            .then(() => {
                getData.getSuccessfullyNotificationWithLang({
                    languageStore,
                    notificationStore,
                    dictionariesStore
                }, emptyLang)

                if (newNews.current.isNewNews) {
                    newNews.current.isNewNews = false;
                    props.history.push(`/admin/${payeeId}/news/${newNews.current.newNewsId}/edit`)
                }
            })
            .catch(error => {
                if (error.message === 'TOKEN_EXPIRED_ERROR') {
                    const {setNotificationObject} = notificationStore;
                    const {getResponseCodes} = dictionariesStore;
                    const message = getResponseCodes[getLanguageUsed]['TOKEN_EXPIRED_ERROR'];
                    const {setAdmin} = authorisedAdminStore;
                    setNotificationObject({
                        type: 'warning',
                        message: message,
                        activityButton: {
                            message: 'Ok',
                            isCloseNotification: false,
                            action: () => {
                                setAdmin({});
                                props.history.push('/login');
                            }
                        }
                    });
                } else {
                    getData.getUnsuccessfullyNotification({
                        languageStore,
                        notificationStore,
                        dictionariesStore
                    }, 'CHANGES_SAVE_UNSUCCESSFULLY')
                }
            })
            .finally(() => {
                notConfirm();
            })
    }

    const _RECOMMENDED_MSG = stringActions.setVariablesToString(RECOMMENDED_MSG, ['languages'], {languages: getSortedLanguageListStr})

    return (Object.keys(state.info).length > 0 && <React.Fragment>
        <div className="edit-news-container">
            <div className="edit-container">
                <Tabs>
                    <TabList className="admin-edit-list">
                        {getSortedLanguageList.map(lang => {
                            return (<Tab className="default-edit-tab" selectedClassName="selected-edit-tab"
                                         key={`tab-${lang.lang}`}>{lang.lexeme}</Tab>)
                        })}
                        <Tab className="default-edit-tab" selectedClassName="selected-edit-tab">{GALLERY}</Tab>
                    </TabList>

                    <form id="edit-news" onSubmit={(event) => {
                        event.preventDefault();
                        setOpenPopup(true);
                        setNamePopup('save')
                    }}>
                        {getSortedLanguageList.map(item => {
                            const {lang} = item;
                            return (<TabPanel key={`tab-panel-${lang}`}>
                                <BlockNewsInformation data={state.info.text[lang]}
                                                      setNewInformation={dispatch} lang={lang}/>
                                <div className="recommended-container">
                                    {_RECOMMENDED_MSG}
                                </div>
                            </TabPanel>)
                        })}
                        <TabPanel>
                            <GalleryNewsContainer
                                data={{
                                    payeeId: payeeId,
                                    newsId: newsId,
                                    logo: state.info.logo,
                                    photoList: state.photoList
                                }}
                                setState={dispatch} setStateEditPhotos={dispatchPhotos}
                                stateEditPhotos={stateEditPhotos}/>
                        </TabPanel>
                    </form>
                </Tabs>
            </div>
        </div>
        <PopupWidget data={{isOpened: isOpenPopup, actionClose: setOpenPopup}}>
            {getNamePopup() === 'save' && <ConfirmAction
                data={{confirmMessage: CONFIRM_SAVING_CHANGES, actionYes: confirmSave, actionNo: notConfirm}}/>}
            {getNamePopup() === 'revert' && <ConfirmAction
                data={{confirmMessage: CONFIRM_REVERTING_CHANGES, actionYes: confirmRevert, actionNo: notConfirm}}/>}
            {getNamePopup() === 'delete' && <ConfirmAction data={{
                confirmMessage: CONFIRM_DELETING_NEWS, actionYes: confirmDelete, actionNo: notConfirm
            }}/>}
        </PopupWidget>
        <PageFooter>
            <div className="edit-cancel-btns">
                <SaveChangesButton formName="edit-news" stateIsDisabledBtn={isDisabledBtn(state, stateEditPhotos)}/>
                <CancelChangesButton data={{
                    setOpenPopupRevert: setOpenPopup,
                    setNamePopup: setNamePopup,
                    stateIsDisabledBtn: isDisabledBtn(state, stateEditPhotos)
                }}/>
            </div>
            {!(newNews.current.isNewNews) && <div className="delete-btn">
                <DeleteNews data={{setOpenPopupDelete: setOpenPopup, setNamePopup: setNamePopup}}/>
            </div>}
        </PageFooter>
    </React.Fragment>)
});

export default NewsEditPage;

function isDisabledBtn(state, stateEditPhotos) {
    return (state.isInitial && stateEditPhotos.add.length === 0 && stateEditPhotos.delete.length === 0)
}