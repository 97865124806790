import React from 'react';

export default function PageFooter(props) {
    return <div className="admin-page-footer">
            <div className="buttons-container">
                <div className="others-buttons-container">
                    {props.children}
                </div>
            </div>
        </div>
}