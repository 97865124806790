import React, {useRef} from 'react'
import {useDrag, useDrop} from 'react-dnd';
import {observer} from 'mobx-react';
import {urlGenerate} from '../../../actions';
import {
    authorisedAdminStore,
    dictionariesStore,
    languageStore
} from '../../../stores';
import deleteBtn from '../../../resources/images/delete_red.png';
import removeBtn from '../../../resources/images/remove.png';

const MemberDND = observer(({member, index, moveListItem, editTeamState, setEditTeamState}) => {

    const {getAdmin} = authorisedAdminStore;
    const {getLanguageUsed, getSortedLanguageList} = languageStore;
    const [{isDragging}, dragRef] = useDrag({
        type: 'item',
        item: {index},
        collect: (monitor) => ({
            isDragging: monitor.isDragging(),
        }),
    })

    const [spec, dropRef] = useDrop({
        accept: 'item',
        hover: (item, monitor) => {
            const dragIndex = item.index
            const hoverIndex = index
            const hoverBoundingRect = ref.current?.getBoundingClientRect()
            const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2
            const hoverActualY = monitor.getClientOffset().y - hoverBoundingRect.top

            if (dragIndex < hoverIndex && hoverActualY < hoverMiddleY) return
            if (dragIndex > hoverIndex && hoverActualY > hoverMiddleY) return

            moveListItem(dragIndex, hoverIndex)
            item.index = hoverIndex
        }
    })

    const ref = useRef(null)
    const dragDropRef = dragRef(dropRef(ref))

    const opacity = isDragging ? 0 : 1

    const {getLexemes} = dictionariesStore;
    const {FIO, POSITION} = getLexemes[getLanguageUsed].admin_edit;

    const getIcon = (member) => {
        if (member.hasOwnProperty('photo') && !editTeamState.delete.includes(member.photo)) {
            return deleteBtn
        } else {
            return removeBtn
        }
    }

    const actionOnDeleteBtn = (member) => {
        if (member.hasOwnProperty('photoFile')) {
            setEditTeamState({removeFromAdd: member.memberId})
        } else if (editTeamState.delete.includes(member.photo)) {
            setEditTeamState({removeFromDelete: member.photo})
        } else {
            setEditTeamState({delete: member.photo})
        }
    }

    return (<tr ref={dragDropRef} style={{opacity}} className={editTeamState.delete.includes(member.photo) ? "check-delete" : ""}>
            <td>
                <div className="dragging"/>
            </td>

            <td><img className="member-photo" src={
                member.hasOwnProperty('photoFile') ? URL.createObjectURL(member.photoFile):
                urlGenerate.teamImgUrl(getAdmin.payeeId, member.photo)}
                     alt="member"/></td>

            {getSortedLanguageList.map(itemLang => {
                return <td key={itemLang.lang}>
                    <div className="editing">
                        <div className="input-container">
                            <label htmlFor="input-fio">
                                {FIO}
                            </label>
                            <input type="text" id="input-fio" disabled={editTeamState.delete.includes(member.photo)} readOnly={editTeamState.delete.includes(member.photo)} value={member.info[itemLang.lang].fio} onChange={(e) => {setEditTeamState({editItem: {index: index, lang: itemLang.lang, type: 'fio', val: e.target.value}})}}/></div>
                        <div className="input-container">
                            <label htmlFor="input-position">
                                {POSITION}
                            </label>
                            <input type="text" id="input-position" disabled={editTeamState.delete.includes(member.photo)} readOnly={editTeamState.delete.includes(member.photo)}  value={member.info[itemLang.lang].position} onChange={(e) => {setEditTeamState({editItem: {index: index, lang: itemLang.lang, type: 'position', val: e.target.value}})}}/>
                        </div>
                    </div>
                </td>
            })}
            <td>
                <div className="delete-btn"
                     onClick={() => actionOnDeleteBtn(member)}><img
                    src={getIcon(member)} alt="delete button"/></div>
            </td>
        </tr>
    )
})

export default MemberDND;