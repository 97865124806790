import React, {useEffect} from 'react';
import {observer} from 'mobx-react';
import {fundInformationStore, authorisedAdminStore} from '../../../stores';

const TokenController = observer(() => {
    const {updateToken} = authorisedAdminStore;
    const {setNewTokenAuth} = fundInformationStore;

    useEffect(() => {
        const {token} = Object.keys(authorisedAdminStore.admin).length > 0 ? authorisedAdminStore.admin : '';
        const {tokenAuth} = fundInformationStore;
        if (token !== tokenAuth) {
            setNewTokenAuth(token);
        }
    }, [authorisedAdminStore.admin])

    useEffect(() => {
        const {token} = authorisedAdminStore.admin;
        const {tokenAuth} = fundInformationStore;
        if (token !== tokenAuth) {
            updateToken(tokenAuth)
        }
    }, [fundInformationStore.tokenAuth])

    return <></>
});

export default TokenController;