import React from 'react';
import {Link} from 'react-router-dom';
import {stringActions, urlGenerate} from '../../../../actions';
import Logo from '../../../common/Logo';

export default function PreviewNews({payeeId, data, lang, months}) {
    const _description = stringActions.substringText(data.text.description, 150)

    return <div className="news-container">
        <Link to={`/fund/${payeeId}/news/${data.newsId}/about`}>
            <div className="news-logo">
                <Logo url={urlGenerate.newsImgUrl(payeeId, data.newsId, data.logo)}/>
            </div>
            <div className="news-info">
                <div className="date">
                    {stringActions.getTextDate(lang, months, data.date)}
                </div>
                <div className="title">
                    {data.text.title}
                </div>
                <div className="description">
                    {_description}
                </div>
            </div>
        </Link>
    </div>
}


