import React from 'react';
import {observer} from 'mobx-react';
import {Link} from 'react-router-dom';
import {languageStore, dictionariesStore} from '../../../stores';
import ImgResult from '../../../resources/images/thanks.png';
import HeadTags from '../../common/HeadTags';

const DonateResultPage = observer(props => {
    const {result, payeeId, depNo} = props.match.params;
    const {getLanguageUsed} = languageStore;
    const {getLexemes} = dictionariesStore;
    const {SUCCESS_DONATE, RETURN_TO_PAGE, UNSUCCESS_DONATE} = getLexemes[getLanguageUsed].donate;

    return <>
        <HeadTags/>
        <div className="user-container">
            <div className="donate-result-container">
                <div className="img-result">
                    <img src={ImgResult} alt="result donate"/>
                </div>
                <div className="info-text-result">
                    <div className="title-result">
                        {result === 'success' ?
                            SUCCESS_DONATE : UNSUCCESS_DONATE}
                    </div>
                    <div className="link-return">
                        {depNo ?
                            <Link to={`/fund/${payeeId}/project/${depNo}/about`}>{RETURN_TO_PAGE}</Link>
                            :
                            <Link to={`/fund/${payeeId}/about`}>{RETURN_TO_PAGE}</Link>
                        }
                    </div>
                </div>
            </div>
        </div>
    </>
})

export default DonateResultPage;