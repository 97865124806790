import AuthorisedAdminStore from './AuthorisedAdminStore'
import DictionariesStore from './DictionariesStore';
import FundInformationStore from './FundInformationStore';
import GlobalSettingsStore from './GlobalSettingsStore';
import LanguageStore from './LanguageStore';
import NotificationStore from './NotificationStore';
import ReadPayeeStore from './ReadPayeeStore';
import MobileMenuStore from './MobileMenuStore';

export const authorisedAdminStore = new AuthorisedAdminStore();
export const dictionariesStore = new DictionariesStore();
export const fundInformationStore = new FundInformationStore();
export const globalSettingsStore = new GlobalSettingsStore();
export const languageStore = new LanguageStore();
export const notificationStore = new NotificationStore();
export const readPayeeStore = new ReadPayeeStore();
export const mobileMenuStore = new MobileMenuStore();