import React from 'react';
import {Link} from 'react-router-dom';
import LogoCircle from '../../common/LogoCircle';

export default function InfoBlock(props) {
    const {payeeId, logoFund, specialization} = props.data;
    const {categoriesFund} = props.data.settings;
    const {titleFund} = props.data.information;

    return <div className="block" data-categories={categoriesFund}>
        <Link to={`/fund/${payeeId}/about/info`}>
            <div className="content-block">
                <div className="info-content">
                    <LogoCircle logo={logoFund} payeeId={payeeId}/>
                    <div className="title">
                        {titleFund}
                    </div>
                    <div className="specialization">
                        {specialization}
                    </div>
                </div>
            </div>
        </Link>
    </div>
}