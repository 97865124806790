import React from 'react';
import SortContainer from './SortContainer';
import HeadTags from '../../common/HeadTags';
import Tagline from './Tagline';
import RegistrationButton from '../registrationRequest/RegistrationButton';

export default function MainPage() {
    return <React.Fragment>
        <HeadTags/>
        <div className="user-container">
            <div className="main-page-container">
                <div className="bg-photo-main-page">
                    <div className="tagline-container">
                        <Tagline/>
                        <RegistrationButton/>
                    </div>
                </div>
                <SortContainer/>
                <div className="image-main-page"></div>
            </div>
        </div>
    </React.Fragment>
}

