import React from 'react';
import {observer} from 'mobx-react';
import {Link} from 'react-router-dom';
import {languageStore, dictionariesStore} from '../../../stores';

const EditPriorityButton = observer(props => {
    const {payeeId} = props;
    const {getLanguageUsed} = languageStore;
    const {getLexemes} = dictionariesStore;
    const {EDIT_PRIORITY} = getLexemes[getLanguageUsed].admin_program_list;

    return (<div className="add-new-priority">
        <Link to={`/admin/${payeeId}/project/priority/edit`}>
            <div>{EDIT_PRIORITY}</div>
        </Link>
    </div>)
});

export default EditPriorityButton;