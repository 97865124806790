import TokenAction from './token-actions';
import AdminAction from './admin-actions';
import CookiesAction from './cookies-actions';
import UrlGenerate from './url-generate';
import GetData from './get-data';
import StringActions from './string-actions';
import Utils from './utils';

export const tokenActions = new TokenAction();
export const adminActions = new AdminAction();
export const cookiesActions = new CookiesAction();
export const urlGenerate = new UrlGenerate();
export const getData = new GetData();
export const stringActions = new StringActions();
export const utils = new Utils();