import React from 'react';
import {observer} from 'mobx-react';
import {languageStore, dictionariesStore} from '../../../stores';

const DeleteNewsButton = observer(props => {
    const {setOpenPopupDelete, setNamePopup} = props.data;
    const {getLanguageUsed} = languageStore;
    const {getLexemes} = dictionariesStore;

    const {DELETE} = getLexemes[getLanguageUsed].admin_edit;
    return (<button className="delete-button" onClick={() => {setNamePopup('delete'); setOpenPopupDelete(true)}}>{DELETE}</button>)
});

    export default DeleteNewsButton;
