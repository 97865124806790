import React, {useRef, useState, useEffect} from 'react';
import {observer} from 'mobx-react';
import AddNewsButton from './AddNewsButton';
import {dictionariesStore, fundInformationStore, languageStore} from '../../../stores';
import PageFooter from '../AdminMainPage/PageFooter';
import NewsInfo from './NewsInfo';
import SelectNews from '../../common/SelectNews';
import {getData} from '../../../actions';

const NewsListPage = observer(props => {
    const _isMounted = useRef(true);
    const [arrNews, setNews] = useState([]);
    const {payeeId, newsId} = props.match.params;
    const {getLanguageUsed} = languageStore;
    const {getLexemes} = dictionariesStore;
    const {getNewsOnPayeeIdByLang} = fundInformationStore;
    const {months} = getLexemes[getLanguageUsed];
    const {NO_FUND_NEWS, NEW_FIRST} = getLexemes[getLanguageUsed].admin_news_list;
    const [selectedSort, setSelectedSort] = useState({value: '0', label: NEW_FIRST});

    useEffect(() => {
        return () => {
            _isMounted.current = false;
        }
    }, []);

    useEffect(() => {
        getNewsOnPayeeIdByLang(payeeId, getLanguageUsed)
            .then(newsFund => {
                const _news = newsFund.find(item => {
                    return item.newsId === newsId
                });
                if (!!(_isMounted.current && _news)) {
                    setNews(_news);
                }
                if (_isMounted.current) {
                    const sortedArr = JSON.parse(JSON.stringify(getData.arrSorting(newsFund, selectedSort)))
                    setNews(sortedArr);
                }
            })
    }, [getLanguageUsed])

    useEffect(() => {
            if (arrNews.length > 0) {
                const sortedArr = [...getData.arrSorting(arrNews, selectedSort)]
                setNews(sortedArr);
            }
        },
        [selectedSort])

    return <React.Fragment>
        <div className="admin-news-list">
            {arrNews.length === 0 ? <div className="no-news-message">
                    {NO_FUND_NEWS}</div>
                : <React.Fragment>
                    <div className="select-news-block">
                        <SelectNews selectedSort={selectedSort} setSelectedSort={setSelectedSort}/>
                    </div>
                    <div className="admin-news-block">{arrNews.map(item => {
                        return <NewsInfo key={item.newsId} data={item} payeeId={payeeId} lang={getLanguageUsed}
                                         months={months}/>
                    })}</div>
                </React.Fragment>
            }
        </div>
        <PageFooter>
            <AddNewsButton payeeId={payeeId}/>
        </PageFooter>

    </React.Fragment>

});

export default NewsListPage;