import React from 'react';
import {observer} from 'mobx-react';
import {Link} from 'react-router-dom';
import {languageStore, dictionariesStore} from '../../../../stores';

const FundComponentLinks = observer(props => {
    const {payeeId, activeLink} = props.data;
    const {getLanguageUsed} = languageStore;
    const {getLexemes} = dictionariesStore;
    const {ABOUT, PROJECTS, DOCUMENTS, NEWS, REPORTS} = getLexemes[getLanguageUsed].fund_page;

    return   <div className="links-fund">
        <Link to={`/fund/${payeeId}/about/info`} className="links">
            <div
                className={activeLink === 'about' ? 'link link-active' : 'link'}>{ABOUT.toUpperCase()}</div>
        </Link>
        <Link to={`/fund/${payeeId}/news-list`} className="links">
            <div
                className={activeLink === 'news-list' ? 'link link-active' : 'link'}>{NEWS.toUpperCase()}</div>
        </Link>
        <Link to={`/fund/${payeeId}/reports`} className="links">
            <div
                className={activeLink === 'reports' ? 'link link-active' : 'link'}>{REPORTS.toUpperCase()}</div>
        </Link>
        <Link to={`/fund/${payeeId}/projects`} className="links">
            <div
                className={activeLink === 'projects' ? 'link link-active' : 'link'}>{PROJECTS.toUpperCase()}</div>
        </Link>
        <Link to={`/fund/${payeeId}/documents`} className="links">
            <div
                className={activeLink === 'documents' ? 'link link-active' : 'link'}>{DOCUMENTS.toUpperCase()}</div>
        </Link>
    </div>

})

export default FundComponentLinks;