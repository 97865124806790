import React, {useEffect, useRef, useState} from 'react';
import moment from 'moment';
import {observer} from 'mobx-react';
import {languageStore, dictionariesStore} from '../../../stores';
import {utils} from '../../../actions';
import Bill from './Bill';

const ReadBillList = observer(props => {
    const _isMounted = useRef(true);
    const {depNo, rowCount} = props.data;

    const [billList, setBillList] = useState([]);
    const countLastOper = useRef(rowCount);
    if (depNo || depNo !== '') {
        props.data.rowCount = '';
    }

    const {getLanguageUsed} = languageStore;
    const {getLexemes} = dictionariesStore;
    const {SUPPORTERS} = getLexemes[getLanguageUsed].read_bill_list;

    useEffect(() => {
        updateList()
    }, [])

    const updateList = () => {
        readBillList(props.data)
            .then(response => {
                    if (!response.error && Object.keys(response).length > 0) {
                        if (_isMounted.current) {
                            let bills = [];
                            if (response.Bill.length > 0) {
                                bills.push(...response.Bill);
                            } else if (Object.keys(response.Bill).length > 0) {
                                bills.push(response.Bill);
                            }
                            setBillList(bills)
                        }
                    }
                }
            )
    }

    return <React.Fragment>
        <div className="title-read-bill-list">
            <div className="title-supporters">{SUPPORTERS}</div>
            <div className="refresh-supporters" onClick={updateList}/>
        </div>

        {billList.length > 0 && <div className="read-bill-list-container">
            {
                (!depNo || depNo === '') ?
                    billList
                        .slice(0, countLastOper.current)
                        .map((bill, index) => {
                            return <Bill data={generateFormattedBill(bill)} key={index}/>
                        })
                    :
                    billList
                        .filter(bill => {
                            return bill._attributes.DepNo === depNo;
                        })
                        .slice(0, countLastOper.current)
                        .map((bill, index) => {
                            return <Bill data={generateFormattedBill(bill)} key={index}/>
                        })
            }
        </div>
        }
    </React.Fragment>
});

export default ReadBillList;

function readBillList(data) {
    return new Promise((resolve, reject) => {
        return utils.sendRequest('POST', '/api/v2/app/tx/ReadBillList', data)
            .then(response => {
                if (!response.data.hasOwnProperty('error') && Object.keys(response.data).length > 0) {
                    resolve(response.data.ReadBillList);
                } else {
                    resolve({});
                }
            })
            .catch(error => {
                reject(error);
            });
    })
}

function getNameDonator(bill) {
    const {ParamValue} = bill.PaymentParams;

    if (ParamValue) {
        let nameDonator = getParamOnRid(ParamValue, 'FIO');
        if (nameDonator && Object.keys(nameDonator.Val).length > 0) {
            return nameDonator.Val._text;
        }
    }

    const {getLanguageUsed} = languageStore;
    const {getLexemes} = dictionariesStore;
    const {ANONYMOUS} = getLexemes[getLanguageUsed].read_bill_list;
    return ANONYMOUS
}

function getMessageDonator(bill) {
    let message = '';
    const {ParamValue} = bill.PaymentParams;

    if (ParamValue) {
        message = getParamOnRid(ParamValue, 'AdditionalMessage');
        if (message) {
            return message.Val._text
        } else {
            message = getParamOnRid(ParamValue, 'ADDITIONAL_MESSAGE');
            if (message) {
                return message.Val._text
            }
        }
    }

    return message;
}

function getParamOnRid(params, name) {
    let arr = [];

    if (Array.isArray(params)) {
        arr = arr.concat(params)
    } else {
        arr.push(params)
    }

    return arr.find(param => {
        return param._attributes.Rid === name
    })
}

function generateFormattedBill(bill) {

    const {getLanguageUsed} = languageStore;
    const {getLexemes} = dictionariesStore;
    const currency = getLexemes[getLanguageUsed].currency_list.find(item => {return item.code === bill._attributes.Ccy});

    let message = getMessageDonator(bill);
    let donator = getNameDonator(bill);

    return {
        date: getFormatDateTime(bill._attributes.PaidTime),
        message: message,
        donator: donator,
        sum: bill._attributes.MerchantPaidAmount,
        currency: currency
    };
}

function getFormatDateTime(date) {
    date = moment(date).format();

    let year = date.substring(0, 4);
    let month = date.substring(5, 7);
    let day = date.substring(8, 10);
    let time = date.substring(11, 16);

    return `${day}.${month}.${year} ${time}`;
}