import React from 'react';
import {observer} from 'mobx-react';
import FundContacts from './FundContacts';
import {urlGenerate} from '../../actions';
import {globalSettingsStore} from '../../stores';
import DonateButton from '../user/donate/DonateButton';
import QRLink from './QRLink';

const InfoBlockFund = observer(props => {
    const {payeeId, information} = props.data;
    const mode = props.mode;

    const {titleFund, specialization} = information;
    const legal_address = (props.data.hasOwnProperty('requisites') && props.data.requisites.hasOwnProperty('legal_address')) ? props.data.requisites.legal_address : '';
    let logo = props.data.logoFund ? urlGenerate.photoUrl(payeeId, '', props.data.logoFund) : urlGenerate.photoUrl('', '', 'empty_logo.svg');

    const {getGlobalSettings} = globalSettingsStore;

    let qrLink = getGlobalSettings.links.qr_link + 'PayeeRid=' + props.data.payeeRid;

    return <React.Fragment>
        {information &&
            <div className={`info-fund-block mode-${mode}`}>
                <div className="logo-text-fund">
                    <div className="logo-fund">
                        <img src={logo} alt='logo of fund'/>
                    </div>
                    <div className="info-fund">
                        <div className="title">
                            <div>{titleFund}</div>
                        </div>
                        <div className="story">
                            <div>{specialization}</div>
                        </div>
                        <div className="story">
                            <div>{legal_address && legal_address}</div>
                        </div>
                        {mode !== 'admin' && <FundContacts payeeId={payeeId}/>}
                    </div>
                </div>
                <div className="donate-fund">
                    <QRLink data={{mobicashLink: qrLink}}/>
                    {mode !== 'admin' && <DonateButton
                        payeeId={payeeId}/>}
                </div>
            </div>
        }
    </React.Fragment>
});

export default InfoBlockFund;