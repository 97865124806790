import React, {useEffect} from 'react';
import {observer} from 'mobx-react';
import parse from 'html-react-parser';
import {notificationStore} from '../../stores';

const Notification = observer(props => {

    const {getNotificationObject, setNotificationObject} = notificationStore;

    let openedNotification = Object.keys(getNotificationObject).length > 0;
    const {message, type, activityButton} = getNotificationObject;

    useEffect(() => {
        let timeoutId;

        if (openedNotification) {
            timeoutId = setTimeout(() => setNotificationObject({}), 10000000)
        }
        return () => clearTimeout(timeoutId);
    });

    const containerClassName = type === 'cookies' ? 'cookies-notification-container' : 'notification-container'

    return (openedNotification &&
        <div className={containerClassName}>
            <div className="notification">
                <div className="notification-flex-container">
                    <div className={`notification-content ${type}`}>
                        <div className="notification-message">
                            {parse(message)}
                        </div>
                        {activityButton && activityButton.message &&
                        <div className="notification-button">
                            <button className={type}
                                onClick={() => {
                                    if (activityButton.isCloseNotification !== true) {activityButton.action.bind(Notification)();}
                                    setNotificationObject({})
                                }}>{activityButton.message}</button>
                        </div>}
                    </div>
                </div>
            </div>
        </div>
    )

});

export default Notification;

