import React from 'react';
import {Link} from 'react-router-dom';

export default function SearchResultBlock(props) {
    const {title, findText, link} = props.data;

    return (
        <div className="search-result">
            <div className="result">
                <Link to={link}>
                    <div className="search-result-title">
                        {title}
                    </div>
                    <div className="search-result-text">
                        {findText}
                    </div>
                </Link>
            </div>
        </div>
    )
}