import React from 'react';
import {observer} from 'mobx-react';
import {Tab, Tabs, TabList, TabPanel} from 'react-tabs';
import {getData} from '../../../actions';
import PageFooter from '../AdminMainPage/PageFooter';
import AddProgramButton from './AddProgramButton';
import EditPriorityButton from './EditPriorityButton';
import {languageStore, dictionariesStore, fundInformationStore} from '../../../stores';
import ProgramInfo from './ProgramInfo';

const ProgramListPage = observer(props => {
    const {payeeId} = props.match.params;
    const {getLanguageUsed} = languageStore;
    const {getLexemes} = dictionariesStore;
    const {getFundInformation} = fundInformationStore;

    const {ACTIVE, ARCHIVE, DRAFT, LIST_IS_EMPTY} = getLexemes[getLanguageUsed].admin_program_list;
    const storeFund = getFundInformation(payeeId, getLanguageUsed);
    let activeProgramsArr = getData.listProgramOnStatus(storeFund, 'active');
    const archiveProgramsArr = getData.listProgramOnStatus(storeFund, 'archive');
    const draftProgramsArr = getData.listProgramOnStatus(storeFund, 'draft');
    const {projectPriorityList} = storeFund;

    activeProgramsArr = getData.getActiveProjectsByPriority(activeProgramsArr, projectPriorityList);

    return <React.Fragment>
        <div className="admin-program-list">
            <Tabs>
                <TabList className="tab-list">
                    <Tab className="default-tab" selectedClassName="selected-tab">{ACTIVE}</Tab>
                    <Tab className="default-tab" selectedClassName="selected-tab">{ARCHIVE}</Tab>
                    <Tab className="default-tab" selectedClassName="selected-tab">{DRAFT}</Tab>
                </TabList>

                <TabPanel className="tab-panel">
                    {activeProgramsArr.length > 0 ?
                        activeProgramsArr.map(project => {
                            return <ProgramInfo key={project.depNo} data={project}/>
                        })
                        :
                        <h2>{LIST_IS_EMPTY}</h2>}
                </TabPanel>
                <TabPanel className="tab-panel">
                    {archiveProgramsArr.length > 0 ?
                        archiveProgramsArr.map(program => {
                            return <ProgramInfo key={program.depNo} data={program}/>
                        })
                        :
                        <h2>{LIST_IS_EMPTY}</h2>}
                </TabPanel>
                <TabPanel className="tab-panel">
                    {draftProgramsArr.length > 0 ?
                        draftProgramsArr.map(program => {
                            return <ProgramInfo key={program.depNo} data={program}/>
                        })
                        :
                        <h2>{LIST_IS_EMPTY}</h2>}
                </TabPanel>
            </Tabs>
        </div>
        <PageFooter>
            <AddProgramButton payeeId={payeeId}/>
            <EditPriorityButton payeeId={payeeId}/>
        </PageFooter>

    </React.Fragment>
});

export default ProgramListPage;