import React from 'react';
import {observer} from 'mobx-react';
import parse from 'html-react-parser';
import {languageStore, fundInformationStore, dictionariesStore} from '../../../stores';
import ErrorPage from '../../common/ErrorPage';
import PhotoCarousel from '../../common/PhotoCarousel';
import CalcTotals from '../../common/calcTotals/CalcTotals';
import DonateButton from '../donate/DonateButton';
import ShareStory from '../../common/ShareStory';
import ReadBillList from '../../common/readBillList/ReadBillList';
import {getData} from '../../../actions';

const ProjectContainer = observer(props => {
    const {payeeId, depNo} = props.match.params;

    const {getFundInformation} = fundInformationStore;
    const {getLanguageUsed} = languageStore;
    const {getLexemes} = dictionariesStore;
    const {STORY, SHARE_STORY, CHARITY_IS_OVER} = getLexemes[getLanguageUsed].project_page;

    const {settings, projects} = getFundInformation(payeeId, getLanguageUsed);
    let isRender = false;

    const project = projects.find(item => {return item.depNo === depNo});
    if (project && project.settings.status !== 'draft') isRender = true;

    let dataCalcTotalsOrReadBillList = {};
    let isRenderDonateButton = true;
    let isRenderReadBillList = settings.lastOper;
    let isShowPercent = true;

    if (isRender) {
        dataCalcTotalsOrReadBillList = getData.componentsCalcTotalsOrReadBillList(payeeId, depNo, settings, project.settings);
        if (project.settings.status === 'archive') {
            isRenderDonateButton = false;
            isShowPercent = false;
        }
    }

    return (
        !isRender ? <ErrorPage/>
            :
            <div className="content-block-template">
                <div className="photo-description-block">
                    <div className="photo">
                        <PhotoCarousel data={{payeeId: payeeId, depNo: depNo, logo: project.logoProg}}/>
                    </div>
                    <div className="description">
                        <div className="title-lexeme">
                            {STORY}
                        </div>
                        {parse(project.information.descriptionProg)}
                    </div>
                </div>
                <div className="info-statistic-block">
                    <div className="title-template">
                        {project.information.titleProg}
                    </div>
                    <div className="calc-totals">
                        <CalcTotals data={dataCalcTotalsOrReadBillList}
                                    format={{isFullDress: true, isShowPercent: isShowPercent}}/>
                    </div>
                    {isRenderDonateButton ? <div className="button-donate">
                        <DonateButton payeeId={payeeId} depNo={depNo}/>
                    </div>
                    :
                        <div className="not-donate-message">
                            {CHARITY_IS_OVER}
                        </div>
                    }
                    <div className="share-story">
                        <div className="title-share">{SHARE_STORY}</div>
                        <ShareStory data={{payeeId: payeeId, depNo: depNo}}/>
                    </div>
                    {isRenderReadBillList && <div className="recent-supporters">
                        <ReadBillList data={dataCalcTotalsOrReadBillList}/>
                    </div>}
                </div>
            </div>
    )

});

export default ProjectContainer;