import React, {useEffect, useRef, useState} from 'react';
import {utils} from '../../../actions';

export default function RegistrationCaptcha(props) {
    const {setPassedCaptcha, placeholder} = props.data;
    const captchaRef = useRef(null);
    const [stateCaptcha, setStateCaptcha] = useState('');

    useEffect(() => {
        generateCaptcha()
            .then(response => {
                if (!response.error) {
                    let placeSvg = captchaRef.current;
                    let svgElement = new DOMParser().parseFromString(response, 'text/xml');
                    placeSvg.appendChild(svgElement.firstElementChild);
                }
            })
    }, []);

    const regenerateCaptcha = () => {
        setPassedCaptcha({isPassedCaptcha: false});
        generateCaptcha()
            .then(response => {
                if (!response.error) {

                    let placeSvg = captchaRef.current;
                    let svgElement = new DOMParser().parseFromString(response, 'text/xml');

                    setStateCaptcha('');
                    placeSvg.removeChild(placeSvg.firstChild);
                    placeSvg.appendChild(svgElement.firstElementChild);
                }

            })
    };

    const changeInputCaptcha = (event) => {
        let textInput = event.target.value;
        setStateCaptcha(textInput);
        return checkCaptcha(textInput)
            .then(response => {
                setPassedCaptcha({isPassedCaptcha: response})
            });
    };

    return (<div className="captcha">
        <div className="container">
            <div className="svg-container" ref={captchaRef}/>
            <div className="input-container">
                <form>
                    <input onChange={changeInputCaptcha} placeholder={placeholder} value={stateCaptcha}/>
                </form>
            </div>
        </div>
        <div className="regenerate-captcha" onClick={regenerateCaptcha}/>
    </div>)
}

function generateCaptcha() {
    return new Promise((resolve, reject) => {
        return utils.sendRequest('GET', '/api/v2/captcha/generate')
            .then(response => {
                if (response.data.hasOwnProperty('captcha')) {
                    resolve(response.data.captcha);
                }
            })
            .catch(error => reject({error: error}))
    })
}

function checkCaptcha(text) {
    return new Promise((resolve, reject) => {
        return utils.sendRequest('POST', '/api/v2/captcha/check', {textCaptcha: text})
            .then(response => {
                if (response.data.hasOwnProperty('error')) throw new Error(response.data.error);
                resolve(response.data.check)
            })
            .catch(error => reject({error: error}))
    })
}