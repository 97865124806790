import React from 'react';
import {observer} from 'mobx-react';
import {languageStore, fundInformationStore} from '../../../stores';
import Account from './Account';
import ChangeLanguage from '../../common/ChangeLanguage';
import {urlGenerate} from '../../../actions';

const Header = observer(({payeeId, setPopupFeedbackIsOpened}) => {
    const {getLanguageUsed} = languageStore;
    const {getFundInformation} = fundInformationStore;
    let store = getFundInformation(payeeId, getLanguageUsed);
    let logoFund = '';
    if (store && store.hasOwnProperty('logoFund') && store.logoFund !== '') {
        logoFund = urlGenerate.photoUrl(payeeId, '', store.logoFund);
    }

    return <React.Fragment>
            <div className="admin-header">
                <div className="logo-service"/>
                <div className="account-logout-container">
                    <ChangeLanguage isAdminPage={true}/>
                    <Account logoFund={logoFund} setPopupFeedbackIsOpened={setPopupFeedbackIsOpened}/>
                </div>
            </div>
    </React.Fragment>
});

export default Header;