import React, {useState} from 'react';
import {observer} from 'mobx-react';
import {globalSettingsStore} from '../../stores';
import PopupWidget from './PopupWidget';
import BannerContainer from './banner/BannerContainer';
import {urlGenerate} from '../../actions';

const ShareStory = observer(props => {
    const {payeeId, depNo, newsId} = props.data;
    const {getGlobalSettings} = globalSettingsStore;
    const {social_networks_api} = getGlobalSettings.share_story;
    const {current_host} = getGlobalSettings;

    const [popupIsOpened, setPopupIsOpened] = useState(false);

    const urlStory = newsId ? urlGenerate.pageUrlNews(payeeId, newsId, current_host) : urlGenerate.pageUrl(payeeId, depNo, current_host);

    return <div className="share-container">
        <React.Fragment>
            {social_networks_api.map(socNet => {
                return (<a className={socNet.key.toLowerCase()} href={socNet.api_url + urlStory} key={socNet.key} target="_blank" rel="noopener noreferrer"/>)
            })}

            <a className="email" href={'mailto:?subject=""&body=' + urlStory} key="email"/>
            {!newsId && <React.Fragment>
                <a className="shareIcon" onClick={() => setPopupIsOpened(true)}/>
                <PopupWidget data={{isOpened: popupIsOpened, actionClose: setPopupIsOpened}}>
                    <BannerContainer data={{payeeId: payeeId, depNo: depNo}}/>
                </PopupWidget>
            </React.Fragment>}
        </React.Fragment>
    </div>
});

export default ShareStory;
