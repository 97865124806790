import React from 'react';
import {observer} from 'mobx-react';
import PageFooter from '../AdminMainPage/PageFooter';
import BannerContainer from '../../common/banner/BannerContainer';

const FundBannerPage = observer(props => {
    const {payeeId} = props.match.params;

    return <React.Fragment>
        <div className="funds-banner-container">
            <BannerContainer data={{payeeId: payeeId}}/>
        </div>
        <PageFooter/>
    </React.Fragment>
});

export default FundBannerPage;