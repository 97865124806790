import React from 'react';
import {Link} from 'react-router-dom';
import {observer} from 'mobx-react';
import {languageStore, dictionariesStore} from '../../../stores';

const ProjectComponentLinks = observer(({activeComponent}) => {
    const {data, page} = activeComponent;
    const {payeeId, depNo} = data;
    const {getLanguageUsed} = languageStore;
    const {getLexemes} = dictionariesStore;
    const {ABOUT_PROJECT} = getLexemes[getLanguageUsed].project_page;

    return <div className="program-links">
        <Link to={`/fund/${payeeId}/project/${depNo}/about`} className="links">
            <div
                className={page === 'about' ? "link link-active" : "link"}>{ABOUT_PROJECT.toUpperCase()}</div>
        </Link>
    </div>

})

export default ProjectComponentLinks;