import React, {useReducer, useRef} from 'react';
import {observer} from 'mobx-react';
import CurrencyFormat from 'react-number-format';
import {languageStore, dictionariesStore, notificationStore, authorisedAdminStore} from '../../../stores';
import {adminActions, getData, utils} from '../../../actions';

const FeedbackForm = observer(props => {
    const {setPopupFeedbackIsOpened} = props.data;
    const {getLanguageUsed} = languageStore;
    const {getLexemes} = dictionariesStore;
    const {FEEDBACK_EMAIL, QUERY, HINT_QUERY, REQUIRED_FIELDS, SEND_TO, PHONE_NUMBER, HINT_PHONE_NUMBER, TITLE, HINT_TITLE} = getLexemes[getLanguageUsed].admin_feedback;
    const {getAdmin} = authorisedAdminStore;

    const [stateFeedback, dispatchFeedback] = useReducer(adminActions.reducerDefault, {payeeId: getAdmin.payeeId});
    const isDisabledBtn = useRef(true);

    if (Object.keys(stateFeedback).length > 0) {
        const {phone, query, email} = stateFeedback;
        const regexpPhone = /((8|\+7)[\- ]?)?(\(?\d{3}\)?[\- ]?)?[\d\- ]{6,10}$/;
        const regexpEmail = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,5}$/
        const regexpQuery = /^[aA-zZаА-яЯ0-9][^\{\}\<\>\[\]\\\/\"\']{1,1001}$/

        isDisabledBtn.current = !((phone && phone !== '' && regexpPhone.test((phone)))
            && (query && query !== '' && regexpQuery.test(query))
            && (email && email !== '' && regexpEmail.test(email)));
    }

    const submitFeedbackForm = (event) => {
        event.preventDefault();

        return utils.sendRequest('POST', '/api/v2/app/send-email/feedback', stateFeedback)
            .then(response => {
                setPopupFeedbackIsOpened(false);
                if (response.data.isResult) {
                    getData.getSuccessfullyNotification({languageStore, dictionariesStore, notificationStore}, 'SUCCESSFUL_SEND_QUERY')
                } else {
                    getData.getUnsuccessfullyNotification({languageStore, dictionariesStore, notificationStore}, 'UNSUCCESSFUL_SEND_QUERY')
                }
            })
    }

    return <div className="feedback-form-container">
        <form onSubmit={submitFeedbackForm}>
            <div className="field-container">
                <label htmlFor="feedback-email-field">{FEEDBACK_EMAIL}</label>
                <input id="feedback-email-field" required={true} onChange={event => dispatchFeedback({email: event.target.value})}/>
            </div>
            <div className="field-container">
                <label htmlFor="phone-number" className="title-container">
                    {PHONE_NUMBER}
                </label>
                <CurrencyFormat id="phone-number" required={true} format="+# (###) ###-####" mask="_"
                                placeholder={HINT_PHONE_NUMBER}
                                onValueChange={(values) => {dispatchFeedback({phone: "+" + values.value})}}/>
            </div>
            <div className="field-container">
                <label htmlFor="title-field">{TITLE}</label>
                <input id="title-field" required={true} placeholder={HINT_TITLE} onChange={event => dispatchFeedback({title: event.target.value})}/>
            </div>
            <div className="field-container">
                <label htmlFor="query-field">{QUERY}</label>
                <textarea id="query-field" required={true} placeholder={HINT_QUERY} onChange={event => dispatchFeedback({query: event.target.value})}/>
            </div>
            <div className="message-container">
                {REQUIRED_FIELDS}
            </div>
            <button type="submit" disabled={isDisabledBtn.current}>{SEND_TO}</button>
        </form>

    </div>
});

export default FeedbackForm;