import React, {useEffect, useReducer, useRef, useState} from 'react';
import {observer} from 'mobx-react';
import moment from 'moment';
import PageFooter from '../AdminMainPage/PageFooter';
import EditTemplate from '../editing-templates/EditTemplate';
import SaveChangesButton from '../editing-templates/SaveChangesButton';
import CancelChangesButton from '../editing-templates/CancelChangesButton';
import {
    languageStore,
    fundInformationStore,
    readPayeeStore,
    notificationStore,
    dictionariesStore,
    globalSettingsStore,
    authorisedAdminStore
} from '../../../stores';
import PopupWidget from '../../common/PopupWidget';
import ConfirmAction from '../editing-templates/ConfirmAction';
import {adminActions, getData} from '../../../actions';

const FundEditPage = observer(props => {
    const _isMounted = useRef(true);
    const {payeeId} = props.match.params;
    const {getFundInformation, getFundPhotos} = fundInformationStore;
    const {getFundReadPayee} = readPayeeStore;
    let isCreatedFund = useRef(false);

    const initialState = useRef({payeeId: payeeId, information: {}, settings: {}});
    const [state, dispatch] = useReducer(adminActions.reducerEditedInformation, initialState.current);
    const [statePhotos, dispatchPhotos] = useReducer(adminActions.reducerEditedPhotos, {});
    const {getLanguageUsed} = languageStore;
    const {getLexemes} = dictionariesStore;
    const {CONFIRM_SAVING_CHANGES, CONFIRM_REVERTING_CHANGES} = getLexemes[getLanguageUsed].admin_edit;

    useEffect(() => {
        return () => {
            _isMounted.current = false;
        }
    }, []);

    useEffect(() => {
        let informationFund = getFundInformation(payeeId);
        if (!informationFund && !isCreatedFund.current) {
            isCreatedFund.current = true;
            getFundReadPayee(payeeId, getLanguageUsed)
                .then(response => {
                    const {information} = response;
                    const {Currency, CreateTime} = information._attributes;
                    const settings = {
                        defaultCurrency: Currency.toString(),
                        startDateFund: moment(CreateTime).format('YYYY-MM-DD'),
                        archProgram: false,
                        lastOper: false,
                        countLastOper: 0,
                        categoriesFund: ['00']
                    }

                    const {getGlobalSettings} = globalSettingsStore;
                    const {languages} = getGlobalSettings;
                    const informationFundInit = languages.reduce((resultObj, item) => {
                        const {lang} = item;
                        return {
                            ...resultObj,
                            [lang]: {
                                titleFund: '',
                                descriptionFund: ''
                            }
                        }
                    }, {});

                    if (_isMounted.current) {
                        let init = JSON.parse(JSON.stringify({
                            payeeId: payeeId,
                            information: informationFundInit,
                            settings: settings,
                            logoFund: ''
                        }))
                        initialState.current = Object.assign({}, init)
                        dispatch({
                            isSetInitialState: true,
                            initialData: initialState.current
                        });

                        dispatchPhotos({initial: {photos: [], logo: ''}});
                    }
                })
        } else if (informationFund && !isCreatedFund.current) {
            getFundPhotos(payeeId)
                .then(responsePhoto => {
                    if (_isMounted.current) {
                        const {settings, information, logoFund} = informationFund
                        initialState.current = Object.assign({}, {payeeId: payeeId}, {settings: settings}, {information: information}, {logoFund: logoFund})
                        dispatch({isSetInitialState: true, initialData: initialState.current});
                        dispatchPhotos({
                            initial: {
                                photos: responsePhoto,
                                logo: initialState.current.logoFund
                            }
                        });
                    }
                })

        }

    }, [fundInformationStore.informationList, fundInformationStore.photoList, getFundReadPayee])

    const [isOpenPopup, setOpenPopup] = useState(false);
    const namePopup = useRef('');
    const setNamePopup = name => {
        namePopup.current = name;
    }
    const getNamePopup = () => {
        return namePopup.current;
    }

    const notConfirm = () => {
        setOpenPopup(false);
        setNamePopup('');
    }

    const confirmSave = () => {
        let emptyLang = adminActions.checkEmptyTextInformation(state.information);
        submitForm(state, statePhotos, isCreatedFund.current)
            .then(response => {
                isCreatedFund.current = false;
                getData.getSuccessfullyNotificationWithLang({
                    languageStore,
                    notificationStore,
                    dictionariesStore
                }, emptyLang)
            })
            .catch(error => {
                if (error.message === 'TOKEN_EXPIRED_ERROR') {
                    const {setNotificationObject} = notificationStore;
                    const {getResponseCodes} = dictionariesStore;
                    const message = getResponseCodes[getLanguageUsed]['TOKEN_EXPIRED_ERROR'];
                    const {setAdmin} = authorisedAdminStore;
                    setNotificationObject({
                        type: 'warning',
                        message: message,
                        activityButton: {
                            message: 'Ok',
                            isCloseNotification: false,
                            action: () => {
                                setAdmin({});
                                props.history.push('/login');
                            }
                        }
                    });
                } else {
                    getData.getUnsuccessfullyNotification({
                        languageStore,
                        notificationStore,
                        dictionariesStore
                    }, 'CHANGES_SAVE_UNSUCCESSFULLY')
                }
            })
            .finally(() => {
                notConfirm()
            })
    }

    const confirmRevert = () => {
        dispatch({isSetInitialState: true, initialData: initialState.current})

        if (adminActions.checkChangesToDeletePhotos(statePhotos) || adminActions.checkChangesToDownloadPhotos(statePhotos)) {
            dispatchPhotos({isSetInitialState: true})
        }

        notConfirm();
    }

    return (Object.keys(state).length > 0 && <React.Fragment>
        <div className="edit-fund-container">
            <form id="edit-fund" onSubmit={(event) => {
                event.preventDefault();
                setOpenPopup(true);
                setNamePopup('save')
            }}>
                <EditTemplate data={state} setNewInformation={dispatch} isProgram={false}
                              isFirstOpen={isCreatedFund.current} editedPhotos={{statePhotos, dispatchPhotos}}/>
            </form>
        </div>
        <PopupWidget data={{isOpened: isOpenPopup, actionClose: setOpenPopup}}>
            {getNamePopup() === 'save' && <ConfirmAction
                data={{confirmMessage: CONFIRM_SAVING_CHANGES, actionYes: confirmSave, actionNo: notConfirm}}/>}
            {getNamePopup() === 'revert' && <ConfirmAction
                data={{confirmMessage: CONFIRM_REVERTING_CHANGES, actionYes: confirmRevert, actionNo: notConfirm}}/>}
        </PopupWidget>
        <PageFooter>
            <div className="edit-cancel-btns">
                <SaveChangesButton formName={"edit-fund"}
                                   stateIsDisabledBtn={isDisabledBtn(state, statePhotos)}/>
                <CancelChangesButton data={{
                    setOpenPopupRevert: setOpenPopup,
                    setNamePopup: setNamePopup,
                    stateIsDisabledBtn: isDisabledBtn(state, statePhotos)
                }}/>
            </div>
        </PageFooter>
    </React.Fragment>)
});

export default FundEditPage;

function saveImagesFromForm(payeeId, statePhotos) {
    if (adminActions.checkChangesToDownloadPhotos(statePhotos)) {
        const {saveNewPhotos} = fundInformationStore;
        const url = `/api/v2/fund-data/photos/fund/admin/${payeeId}`;
        return saveNewPhotos({payeeId, url, newPhotos: statePhotos.downloadFilesArr})
    } else {
        return Promise.resolve()
    }
}

function deleteImagesFromForm(payeeId, statePhotos) {
    if (adminActions.checkChangesToDeletePhotos(statePhotos)) {
        const {deletePhotos} = fundInformationStore;
        return deletePhotos({
            payeeId,
            deleteImages: statePhotos.deleteImages
        })
    } else {
        return Promise.resolve();
    }
}

function submitForm(state, statePhotos, isCreatedFund) {
    const {payeeId} = state;
    const {saveNewInformation, createFundStructure} = fundInformationStore;

    const saveInformationFromForm = () => {
        if (isCreatedFund) {
            return createFundStructure(state)
        } else {
            return saveNewInformation(state)
        }
    }
    return new Promise((resolve, reject) => {
        return saveInformationFromForm()
            .then(() => {
                return saveImagesFromForm(payeeId, statePhotos)
            })
            .then(() => {
                return deleteImagesFromForm(payeeId, statePhotos)
            })
            .then(() => resolve())
            .catch(error => {
                reject(error)
            })
    })
}

function isDisabledBtn(state, statePhotos) {
    if (Object.keys(state).length > 0 || Object.keys(statePhotos).length > 0) {
        if (!state.isInitial) {
            return false;
        } else {
            return !(
                adminActions.checkChangesToDownloadPhotos(statePhotos)
                ||
                adminActions.checkChangesToDeletePhotos(statePhotos))
        }
    } else {
        return true;
    }
}