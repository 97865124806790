import React from 'react';
import {observer} from 'mobx-react';
import {Link} from 'react-router-dom';
import {useHistory} from 'react-router-dom';
import errorImg from '../../resources/images/error.png';
import {languageStore, dictionariesStore, authorisedAdminStore} from '../../stores';

const ErrorPage = observer((props) => {
    const {getLanguageUsed} = languageStore;
    const {getLexemes} = dictionariesStore;

    let lexemes = getLexemes[getLanguageUsed];
    const {PAGE_NOT_FOUND, RETURN_MAIN_PAGE, GET_AUTHORISED} = lexemes.error_page;

    const checkParamAdmin = (props) => {
        return props.hasOwnProperty('match') && props.match.params.hasOwnProperty('admin') && props.match.params.admin === 'admin'
    }
    const {setAdmin} = authorisedAdminStore;

    const history = useHistory();
    let isAdmin = false;
    if (history.location.pathname.match(/\/admin\//) || checkParamAdmin(props)) {
        isAdmin = true;
    }

    return (
        <div className="error-container">
            <div className="error-img">
                <img src={errorImg} alt="error"/>
            </div>
            <div className="error-msg">
                <p>
                    {PAGE_NOT_FOUND}
                </p>
                <p>
                    <Link to="/" onClick={() => {
                        setAdmin({});
                    }}>{RETURN_MAIN_PAGE}</Link>
                </p>
                {isAdmin && <p>
                    <Link to="/login" onClick={() => {
                        setAdmin({});
                    }}>{GET_AUTHORISED}</Link>
                </p>}
            </div>
        </div>
    )
});

export default ErrorPage;
