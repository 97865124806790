import React from 'react';
import {observer} from 'mobx-react';
import {
    languageStore,
    dictionariesStore,
    globalSettingsStore,
    notificationStore
} from '../../../../stores';
import {urlGenerate, adminActions} from '../../../../actions';
import deleteBtn from '../../../../resources/images/delete_red.png';
import removeBtn from '../../../../resources/images/remove.png'

const DocumentsOrReportsEdit = observer(({payeeId, state, setState, isReports}) => {
    const {getGlobalSettings} = globalSettingsStore;
    const {max_size_document} = getGlobalSettings.download_files;
    const {getLanguageUsed, getSortedLanguageList} = languageStore;
    const {getLexemes} = dictionariesStore;
    const {admin_edit} = getLexemes[getLanguageUsed];
    const {UPLOAD, FILE_NAME, FILE_STATUS, ADD_STATUS, DOWNLOAD_STATUS} = admin_edit;

    return <React.Fragment>
        <div className="upload-container">
            <label className="custom-file-upload">
                <input type="file" accept="image/jpeg,image/png,application/pdf,application/msword" multiple={true}
                       onChange={(e) => {
                           let uploadFiles = adminActions.checkSizeUploadFiles([...e.target.files], max_size_document, {
                               notificationStore,
                               languageStore,
                               dictionariesStore
                           });

                           uploadFiles = uploadFiles.map(item => {
                               const labels = getSortedLanguageList.reduce((result, itemLang) => {
                                   Object.assign(result, {[itemLang.lang]: ''})
                                   return result;
                               }, {})
                               return {
                                   file: item,
                                   fileName: item.name,
                                   label: labels
                               }
                           })
                           if (uploadFiles.length > 0) setState({add: [...uploadFiles]})
                       }}/>
                <div className="upload-btn"><div>+ {UPLOAD}</div></div>
            </label>
        </div>
        <div className="table-container">
            <table>
                <thead>
                {(state.add.length > 0 || state.edit.length > 0) && <tr>
                    <th className="th-1">&nbsp;</th>
                    <th className="th-2">{FILE_NAME}</th>
                    <th className="th-3">{FILE_STATUS}</th>
                    {getSortedLanguageList.map(itemLang => {
                        return <th key={itemLang.lang}>{admin_edit[`SIGNATURE_${itemLang.lang.toUpperCase()}`]}</th>
                    })}
                    <th className="th-4">&nbsp;</th>
                </tr>}
                </thead>
                <tbody>
                {state.add.length > 0 && <React.Fragment>
                    {state.add.map((report, index) => {
                        return (<tr key={index}>
                            <td>{adminActions.getLineNumber(index)}</td>
                            <td>{report.fileName}</td>
                            <td>{DOWNLOAD_STATUS}</td>
                            {getSortedLanguageList.map(itemLang => {
                                return <td key={itemLang.lang}>
                                    <div className="edit-label">
                                        <input type="text" value={report.label[itemLang.lang]} onChange={(e) => {
                                            setState({editAddItem: {[index]: {[itemLang.lang]: e.target.value}}})
                                        }}/>
                                    </div>
                                </td>
                            })}
                            <td>
                                <div className="delete-btn" onClick={() => {
                                    setState({removeFromAdd: report.fileName})
                                }}>
                                    <img
                                        src={removeBtn} alt="delete file from add list"/>
                                </div>
                            </td>
                        </tr>)
                    })}
                </React.Fragment>}
                {state.edit.length > 0 && <React.Fragment>
                    {state.edit.map((report, index) => {
                        return (
                            <tr key={index} className={state.delete.includes(report.fileName) ? "check-delete" : ""}>
                                <td>{adminActions.getLineNumber(index, state.add.length)}</td>
                                <td><a href={isReports ? urlGenerate.reportUrl(payeeId, report.fileName) : urlGenerate.documentUrl(payeeId, report.fileName)} target="_blank"
                                       rel="noopener noreferrer">{report.fileName}</a></td>
                                <td>{ADD_STATUS}</td>
                                {getSortedLanguageList.map(itemLang => {
                                    return <td key={itemLang.lang}>
                                        <div className="edit-label">
                                            <input type="text" disabled={state.delete.includes(report.fileName)}
                                                   readOnly={state.delete.includes(report.fileName)} value={report.label[itemLang.lang]} onChange={(e) => {
                                                setState({editItem: {[index]: {[itemLang.lang]: e.target.value}}})
                                            }}/>
                                        </div>
                                    </td>
                                })}
                                <td>
                                    <div className="delete-btn"
                                         onClick={() => setToDeleteList(report.fileName, state.delete, setState)}><img
                                        src={state.delete.includes(report.fileName) ? removeBtn : deleteBtn} alt="delete button"/></div>
                                </td>
                            </tr>)
                    })}
                </React.Fragment>}
                </tbody>
            </table>
        </div>
    </React.Fragment>
});

export default DocumentsOrReportsEdit;

function setToDeleteList(file, deletedFiles, setEditReports) {
    if (deletedFiles.includes(file)) {
        setEditReports({removeFromDelete: file})
    } else {
        setEditReports({delete: file})
    }

}