import React, {useEffect, useRef, useState} from 'react';
import {observer} from 'mobx-react';
import {languageStore, dictionariesStore, fundInformationStore} from '../../../../stores';
import SelectNews from '../../../common/SelectNews';
import PreviewNews from './PreviewNews';
import {getData} from '../../../../actions';

const FundNewsListComponent = observer(props => {
    const _isMounted = useRef(true);
    const [arrNews, setNews] = useState([]);
    const {payeeId} = props.match.params;
    const {getNewsOnPayeeIdByLang} = fundInformationStore;
    const {languageUsed} = languageStore;
    const {getLexemes} = dictionariesStore;
    const {NO_FUND_NEWS, NEW_FIRST} = getLexemes[languageUsed].fund_page;
    const {months} = getLexemes[languageUsed];
    const [selectedSort, setSelectedSort] = useState({value: '0', label: NEW_FIRST});
    const [isList, setIsList] = useState(false);

    useEffect(() => {
        return () => {
            _isMounted.current = false;
        }
    }, []);

    useEffect(() => {
        getNewsOnPayeeIdByLang(payeeId, languageUsed)
            .then(newsFund => {
                if (_isMounted.current) {
                    const sortedArr = JSON.parse(JSON.stringify(getData.arrSorting(newsFund, selectedSort)))
                    setNews(sortedArr);
                }
            })
    }, [languageUsed])

    useEffect(() => {
            if (arrNews.length > 0) {
                const sortedArr = [...getData.arrSorting(arrNews, selectedSort)]
                setNews(sortedArr);
            }
        },
        [selectedSort])

    return (<div className="news-components">
        {arrNews.length === 0 ? <div className="no-news-message">
                {NO_FUND_NEWS}</div>
            : <React.Fragment>
                <div className="select-block">
                    <SelectNews selectedSort={selectedSort} setSelectedSort={setSelectedSort}/>
                    <div className="render-type">
                        <div className={isList ? "four-figures" : "four-figures active"} onClick={() => {
                            setIsList(!isList)
                        }
                        }>
                            <span className="square1"/>
                            <span className="square2"/>
                            <span className="square3"/>
                            <span className="square4"/>
                        </div>
                        <div className={isList ? "three-figures active" : "three-figures"} onClick={() => {
                            setIsList(!isList)
                        }
                        }>
                            <span className="stripes1"/>
                            <span className="stripes2"/>
                            <span className="stripes3"/>
                        </div>
                    </div>
                </div>
                <div className={isList ? "preview-news-flexbox" : "preview-news"}>{arrNews.map(item => {
                    return <PreviewNews key={item.newsId} data={item} payeeId={payeeId} lang={languageUsed}
                                        months={months}/>
                })}</div>
            </React.Fragment>
        }
    </div>)
});

export default FundNewsListComponent;