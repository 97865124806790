import React from 'react';
import {observer} from 'mobx-react';
import parse from 'html-react-parser';
import PhotoCarousel from '../../../../common/PhotoCarousel';
import {fundInformationStore, languageStore} from '../../../../../stores';

const Index = observer(props => {
    const {payeeId} = props.match.params;
    const {getLanguageUsed} = languageStore;
    const {getFundInformation} = fundInformationStore;
    const {information, logoFund} = getFundInformation(payeeId, getLanguageUsed);
    const {descriptionFund} = information;

    return <>
        <div id="description-fund" className="description-fund">
            {parse(descriptionFund)}
        </div>
        <div className="carousel-fund">
            <PhotoCarousel data={{payeeId: payeeId, logo: logoFund}}/>
        </div>
    </>
});

export default Index;
