import React, {useEffect, useReducer, useRef, useState} from 'react';
import {observer} from 'mobx-react';
import {
    languageStore,
    dictionariesStore,
    fundInformationStore,
    authorisedAdminStore,
    notificationStore
} from '../../../stores';
import {adminActions, getData} from '../../../actions';
import DocumentsOrReportsEdit from '../editing-templates/DocumentsOrReportsEdit';
import PopupWidget from '../../common/PopupWidget';
import ConfirmAction from '../editing-templates/ConfirmAction';
import PageFooter from '../AdminMainPage/PageFooter';
import SaveChangesButton from '../editing-templates/SaveChangesButton';
import CancelChangesButton from '../editing-templates/CancelChangesButton';

const ReportsEditPage = observer(props => {
    const {payeeId} = props.match.params;
    const _isMounted = useRef(true);
    const reportInitialState = useRef({
        edit: [],
        delete: [],
        add: [],
        isEdited: false
    });
    const [editReports, setEditReports] = useReducer(adminActions.reducerEditedFiles, {...reportInitialState.current})
    const {getReportsOnPayeeId} = fundInformationStore;

    const {getLanguageUsed} = languageStore;
    const {getLexemes} = dictionariesStore;
    const {CONFIRM_SAVING_CHANGES, CONFIRM_REVERTING_CHANGES} = getLexemes[getLanguageUsed].admin_edit;

    useEffect(() => {
        return () => {
            _isMounted.current = false;
        }
    }, [])

    useEffect(() => {
        getReportsOnPayeeId(payeeId)
            .then(reports => {
                if (_isMounted.current) {
                    reportInitialState.current.edit = reports;
                    setEditReports({
                        setInitial: {...reportInitialState.current}
                    })
                }
            })
            .catch(error => {
                console.log(error)
            })
    }, [fundInformationStore.reports, getLanguageUsed])

    const [isOpenPopup, setOpenPopup] = useState(false);
    const namePopup = useRef('');
    const setNamePopup = name => {
        namePopup.current = name;
    }
    const getNamePopup = () => {
        return namePopup.current;
    }

    const notConfirm = () => {
        setOpenPopup(false);
        setNamePopup('');
    }

    const confirmSave = () => {
        return submitForm(editReports)
            .then(() => {
                notConfirm();
            })
    }

    const confirmRevert = () => {
        setEditReports({
            setInitial: {...reportInitialState.current}
        })
        notConfirm();
    }

    const submitForm = (editReports) => {
        return editingReports(payeeId, editReports.edit, editReports.isEdited)
            .then(() => {
                return deletingReports(payeeId, editReports.delete)
            })
            .then(() => {
                return uploadingReports(payeeId, editReports.add)
            })
            .then(() => {
                getData.getSuccessfullyNotification({
                    languageStore,
                    notificationStore,
                    dictionariesStore
                }, 'CHANGES_SAVE_SUCCESSFULLY')
            })
            .catch(error => {
                if (error.message === 'TOKEN_EXPIRED_ERROR') {
                    const {setNotificationObject} = notificationStore;
                    const {getResponseCodes} = dictionariesStore;
                    const message = getResponseCodes[getLanguageUsed]['TOKEN_EXPIRED_ERROR'];
                    const {setAdmin} = authorisedAdminStore;
                    setNotificationObject({
                        type: 'warning',
                        message: message,
                        activityButton: {
                            message: 'Ok',
                            isCloseNotification: false,
                            action: () => {
                                setAdmin({});
                                props.history.push('/login');
                            }
                        }
                    });
                } else {
                    getData.getUnsuccessfullyNotification({
                        languageStore,
                        notificationStore,
                        dictionariesStore
                    }, 'CHANGES_SAVE_UNSUCCESSFULLY')
                }
            })
    }

    return <React.Fragment>
        <div className="reports-edit-container">
            <form id="edit-reports" onSubmit={(event) => {
                event.preventDefault();
                setOpenPopup(true);
                setNamePopup('save')
            }}>
                <DocumentsOrReportsEdit payeeId={payeeId} state={editReports} setState={setEditReports} isReports={true}/>
            </form>
        </div>
        <PopupWidget data={{isOpened: isOpenPopup, actionClose: setOpenPopup}}>
            {getNamePopup() === 'save' && <ConfirmAction
                data={{confirmMessage: CONFIRM_SAVING_CHANGES, actionYes: confirmSave, actionNo: notConfirm}}/>}
            {getNamePopup() === 'revert' && <ConfirmAction
                data={{confirmMessage: CONFIRM_REVERTING_CHANGES, actionYes: confirmRevert, actionNo: notConfirm}}/>}
        </PopupWidget>
        <PageFooter>
            <div className="edit-cancel-btns">
                <SaveChangesButton formName="edit-reports"
                                   stateIsDisabledBtn={adminActions.checkChangesFromReportsOrDocuments(editReports)}/>
                <CancelChangesButton data={{
                    setOpenPopupRevert: setOpenPopup,
                    setNamePopup: setNamePopup,
                    stateIsDisabledBtn: adminActions.checkChangesFromReportsOrDocuments(editReports)
                }}/>
            </div>
        </PageFooter>
    </React.Fragment>
});

export default ReportsEditPage;

function editingReports(payeeId, editArr, isEdited) {
    if (editArr.length > 0 && isEdited) {
        const {savingEditedReports} = fundInformationStore;
        return savingEditedReports(payeeId, editArr)
    } else {
        return Promise.resolve();
    }
}

function deletingReports(payeeId, deleteArr) {
    if (deleteArr.length > 0) {
        const {deleteReports} = fundInformationStore;
        return deleteReports(payeeId, deleteArr)
    } else {
        return Promise.resolve();
    }
}

function uploadingReports(payeeId, addArr) {
    if (addArr.length > 0) {
        const {uploadNewReports} = fundInformationStore;
        return uploadNewReports(payeeId, addArr)
    } else {
        return Promise.resolve();
    }
}