import React from 'react';
import {observer} from 'mobx-react';
import {Route, Switch, useHistory} from 'react-router-dom';
import AboutFundLinks from '../Menu/AboutFundLinks';
import Information from './Information';
import Team from './Team';
import GetHelp from './GetHelp';
import Requisites from './Requisites';

const AboutFundComponent = observer((props) => {
    const {payeeId} = props.match.params;
    const history = useHistory();
    const {pathname} = history.location;

    if (/^\/fund\/\d*\/about(\/)?$/.test(pathname)) {
        if (pathname.endsWith('/')) {
            history.push(`${pathname}info`)
        } else {
            history.push(`${pathname}/info`)
        }
    }

    return (
        <div className="about-fund-container">
            <AboutFundLinks payeeId={payeeId} activeComponent={props.activeComponent}/>
            <Switch>
                <Route exact path="/fund/:payeeId/about/info" component={Information}/>
                <Route exact path="/fund/:payeeId/about/team" component={Team}/>
                <Route exact path="/fund/:payeeId/about/help" component={GetHelp}/>
                <Route exact path="/fund/:payeeId/about/requisites" component={Requisites}/>
            </Switch>
        </div>
    )
});

export default AboutFundComponent