import React from 'react';
import {observer} from 'mobx-react';
import {adminActions, urlGenerate, stringActions} from '../../../../actions';
import {
    languageStore,
    dictionariesStore,
    globalSettingsStore,
    notificationStore
} from '../../../../stores';
import Logo from '../../../common/Logo';
import ImageNewsContainer from './ImageNewsContainer';

const GalleryNewsContainer = observer(props => {
    const {payeeId, newsId, logo, photoList} = props.data;
    const {setState, setStateEditPhotos, stateEditPhotos} = props;
    const {getLanguageUsed} = languageStore;
    const {getLexemes} = dictionariesStore;
    const {
        LOGO_OF_NEWS,
        SELECT_FOR_COVER,
        DO_COVER,
        UPLOAD,
        UPLOAD_DOCUMENTS_MSG,
        PRESS_SAVE_FOR_ADD_FILES,
        FILES_FOR_UPLOAD
    } = getLexemes[getLanguageUsed].admin_edit;
    const {getGlobalSettings} = globalSettingsStore;
    const {max_size_image} = getGlobalSettings.download_files;

    let msg = stringActions.setVariablesToString(UPLOAD_DOCUMENTS_MSG, ['size'], {size: max_size_image});

    return <div className="gallery-container">
        <div className="image-logo-container">
            <div className="title-logo">
                {LOGO_OF_NEWS}
            </div>
            <div className="image-logo">
                {(logo && logo !== '') ?
                    <Logo url={urlGenerate.newsImgUrl(payeeId, newsId, logo)}/>
                    :
                    <div>{SELECT_FOR_COVER}</div>}
            </div>
            <div className="download-container">
                {
                    <label className="custom-file-upload">
                        <input type="file" accept="image/jpeg,image/png" multiple={true} onChange={(e) => {
                            const downloadFiles = adminActions.checkSizeUploadFiles([...e.target.files], max_size_image, {
                                notificationStore,
                                languageStore,
                                dictionariesStore
                            })
                            setStateEditPhotos({add: [...downloadFiles]})
                        }}/>
                        {UPLOAD}
                    </label>
                }
            </div>
            <div className="message-gallery">
                {msg}
            </div>
            <div className="message-gallery">
                {PRESS_SAVE_FOR_ADD_FILES}
            </div>
            {(adminActions.checkChangesToDownloadPhotosNews(stateEditPhotos)) && <div className="message-gallery">
                {stringActions.setVariablesToString(FILES_FOR_UPLOAD, ['count'], {count: stateEditPhotos.add.length})}
            </div>}
        </div>
        <div className="edit-images-container">
            <div className="all-images">
                {photoList.length > 0 && photoList.map(img => {
                    return <ImageNewsContainer key={img} data={{
                        payeeId,
                        newsId,
                        img,
                        isLogo: img === logo,
                        photoList,
                        setStateEditPhotos,
                        DO_COVER,
                        setState,
                        isRender: isRenderImageContainer(stateEditPhotos, img)
                    }}/>
                })}
            </div>
        </div>
    </div>
});

export default GalleryNewsContainer;

function isRenderImageContainer(stateEditPhotos, img) {
    if (adminActions.checkChangesToDeletePhotosNews(stateEditPhotos)) {
        const delImg = stateEditPhotos.delete.find(item => {
            return item === img
        });
        return !delImg;
    } else return true;
}