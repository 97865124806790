import React from 'react';
import {observer} from 'mobx-react';
import {Link} from 'react-router-dom';
import {languageStore, dictionariesStore} from '../../../stores';
import addIcon from '../../../resources/images/add-circle.png'

const AddProgramButton = observer(props => {
    const {payeeId} = props;
    const {getLanguageUsed} = languageStore;
    const {getLexemes} = dictionariesStore;
    const {ADD_PROGRAM} = getLexemes[getLanguageUsed].admin_program_list;

    return(<div className="add-new-program">
        <Link to={`/admin/${payeeId}/project/create`}>
            <img src={addIcon} alt="add icon"/>
            <div>{ADD_PROGRAM}</div>
        </Link>
    </div>)
});

export default AddProgramButton;