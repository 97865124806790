import React from 'react';
import {observer} from 'mobx-react';
import {languageStore, dictionariesStore} from '../../../stores';

const CancelChangesButton = observer(props => {
    const {setOpenPopupRevert, setNamePopup, stateIsDisabledBtn} = props.data;
    const {getLanguageUsed} = languageStore;
    const {getLexemes} = dictionariesStore;

    const {CANCEL_CHANGES} = getLexemes[getLanguageUsed].admin_edit;

    return (<button className="remove-button" disabled={stateIsDisabledBtn} onClick={() => {setNamePopup('revert'); setOpenPopupRevert(true)}}>
        {CANCEL_CHANGES}
    </button>)
});

export default CancelChangesButton;