import axios from 'axios';
export default class Utils {

    sendRequest(method, route, data = {}, headerOptions = {}) {
        return new Promise((resolve, reject) => {
            let myHeaders = {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }

            if (Object.keys(headerOptions).length > 0) {
               Object.assign(myHeaders, headerOptions)
            }

            return axios({method: method, url: route, data: data, headers: myHeaders})
                .then(response => {
                    resolve(response);
                })
                .catch(error => reject(error))
        })
    }

    reducerRenderSortContainer(state, action) {
        let _state = {
            categoriesFromBlocks: state.categoriesFromBlocks,
            lang: action.hasOwnProperty('lang') ? action.lang : state.lang,
            selectedCategories: action.hasOwnProperty('selectedCategories') ? action.selectedCategories : state.selectedCategories,
            blockInformationArr: action.hasOwnProperty('blockInformationArr') ? action.blockInformationArr : state.blockInformationArr
        }

        const isRenderInfoBlockOnCategories = (categories, selectedCategories) => {
            let isRender = false;

            if (selectedCategories.length > 0) {

                categories.forEach(category => {
                    if (
                        selectedCategories.find(item => {
                            return item.value === category
                        })
                        ||
                        selectedCategories.find(item => {
                            return (item.value.endsWith('0') && item.value.substring(0, 1) === category.substring(0, 1))
                        })
                    ) {
                        isRender = true;
                    }
                })
            } else isRender = true;

            return isRender;
        }

        let renderBlocks = _state.blockInformationArr.filter(block => {
            let isRender = isRenderInfoBlockOnCategories(block.categories, _state.selectedCategories);
            if (isRender) return block
        })

        _state = {
            ..._state,
            renderBlocks: renderBlocks
        }

        return _state;
    }
}
