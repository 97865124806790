import {action, computed, observable, toJS} from 'mobx';

class AuthorisedAdminStore {
    /**
     * admin = {
     *     payeeId: '',
     *     login: '',
     *     token: ''
     * }
     * @type {{}}
     */
    @observable admin = {};

    @computed get getAdmin() {
        return toJS(this.admin);
    }

    @action updateToken = token => {
        this.admin.token = token;
    }

    @action setAdmin = admin => {
        this.admin = {
            ...admin
        }
    };
}

export default AuthorisedAdminStore;