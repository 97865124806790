export default class UrlGenerate {

    apiUrl(action, current_host, dataArr) {
        const apiRequest = `${current_host}/dbapi/${action}?`;

        return apiRequest + dataArr.reduce((result, item) => {
            return `${result}&${item.param}=${item.value}`;
        }, '');
    }

    documentUrl(payeeId, doc) {
        return `/resources/${payeeId}/documents/${doc}`;
    }

    newsImgUrl(payeeId, newsId, img) {
        return `/resources/${payeeId}/images/news/${newsId}/${img}`;
    }

    pageUrl(payeeId, depNo, host = '') {
        if (depNo) {
            return `${host}/fund/${payeeId}/project/${depNo}/about`
        } else {
            return `${host}/fund/${payeeId}/about`
        }
    }

    pageUrlNews(payeeId, newsId, host = '') {
        return `${host}/fund/${payeeId}/news/${newsId}/about`
    }

    photoUrl(payeeId, depNo, image) {
        const linkImg = '/resources/';

        if (depNo && depNo !== "") {
            return `${linkImg}${payeeId}/images/${depNo}/${image}`;
        } else if (payeeId && payeeId !== "") {
            return `${linkImg}${payeeId}/images/fund/${image}`;
        } else {
            return `/static/img/${image}`;
        }
    }

    reportUrl(payeeId, report) {
        return `/resources/${payeeId}/reports/${report}`;
    }

    teamImgUrl(payeeId, img) {
        return `/resources/${payeeId}/images/team/${img}`;
    }
}