import React, {useEffect, useState} from 'react';
import {Link, useLocation} from 'react-router-dom';
import {observer} from 'mobx-react';
import logoDb from '../../../resources/images/logoMobiCashDB_red.svg';
import searchImg from '../../../resources/images/find.png';
import {languageStore, dictionariesStore, mobileMenuStore} from '../../../stores';

const Header = observer(() => {
    const location = useLocation();
    const {isOpenMobileMenu, setIsOpenMobileMenu} = mobileMenuStore;
    const [isFundPage, setIsFundPage] = useState(false)
    const {getLanguageUsed} = languageStore;
    const {getLexemes} = dictionariesStore;
    const {SEARCH} = getLexemes[getLanguageUsed].search_page;

    useEffect(() => {
        setIsFundPage((/fund/).test(location.pathname))
    }, [location.pathname])

    const toggleMobileMenu = () => {
        setIsOpenMobileMenu(!isOpenMobileMenu)
    }

    return <div className="header">
        <div className={isFundPage ? "toggle-mobile-menu" : "is-hidden"} onClick={toggleMobileMenu}>
            <span/>
            <span/>
            <span/>
        </div>
        <div className="title-header">
            <div className="logo-service">
                <img src={logoDb} alt="logo service"/>
            </div>
            <div className="btn-header">
                <Link className="search-link" to="/search">
                    <img src={searchImg}/>
                    {SEARCH}
                </Link>
            </div>
        </div>
    </div>
});

export default Header;
