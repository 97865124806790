import React, {useEffect, useReducer, useRef, useState} from 'react';
import {Tab, TabList, TabPanel, Tabs} from 'react-tabs';
import {observer} from 'mobx-react';
import {
    authorisedAdminStore,
    dictionariesStore,
    fundInformationStore,
    languageStore,
    notificationStore
} from '../../../stores';
import PopupWidget from '../../common/PopupWidget';
import ConfirmAction from '../editing-templates/ConfirmAction';
import PageFooter from '../AdminMainPage/PageFooter';
import SaveChangesButton from '../editing-templates/SaveChangesButton';
import {getData} from '../../../actions';
import CancelChangesButton from '../editing-templates/CancelChangesButton';
import HowGetHelpTextEditTemplate from './HowGetHelpTextEditTemplate';

const HowGetHelpEditPage = observer((props) => {
    const _isMounted = useRef(true);
    const {payeeId} = props.match.params;
    const helpInitialState = useRef({isInitial: true, help: {}});
    const [helpState, setHelpState] = useReducer(getHelpReducer, {...helpInitialState.current});
    const {getFundInformation, savingEditedHowGetHelp} = fundInformationStore;
    const {getLanguageUsed, getSortedLanguageList} = languageStore;
    const {getLexemes} = dictionariesStore;
    const {admin_edit} = getLexemes[getLanguageUsed];
    const {CONFIRM_SAVING_CHANGES, CONFIRM_REVERTING_CHANGES} = admin_edit;

    useEffect(() => {
        return () => {
            _isMounted.current = false;
        }
    }, [])

    useEffect(() => {
        const {information} = getFundInformation(payeeId);
        const langArr = Object.keys(information);
        const getHelpOnLang = langArr.reduce((resultObj, lang) => {
            return {
                ...resultObj,
                [lang]: information[lang].howGetHelp ? information[lang].howGetHelp : ''
            }
        }, {})
        helpInitialState.current = {...helpInitialState.current, help: {...getHelpOnLang}};
        setHelpState({setInitial: helpInitialState.current})
    }, [fundInformationStore.informationList, getLanguageUsed])

    const [isOpenPopup, setOpenPopup] = useState(false);
    const namePopup = useRef('');
    const setNamePopup = name => {
        namePopup.current = name;
    }
    const getNamePopup = () => {
        return namePopup.current;
    }

    const notConfirm = () => {
        setOpenPopup(false);
        setNamePopup('');
    }

    const confirmSave = () => {
        return submitForm(helpState)
            .then(() => {
                notConfirm();
            })
    }

    const confirmRevert = () => {
        setHelpState({setInitial: {...helpInitialState.current}})
        notConfirm();
    }

    const submitForm = (helpState) => {
        return savingEditedHowGetHelp(payeeId, helpState.help)
            .then(() => {
                getData.getSuccessfullyNotification({
                    languageStore,
                    notificationStore,
                    dictionariesStore
                }, 'CHANGES_SAVE_SUCCESSFULLY')
            })
            .catch(error => {
                if (error.message === 'TOKEN_EXPIRED_ERROR') {
                    const {setNotificationObject} = notificationStore;
                    const {getResponseCodes} = dictionariesStore;
                    const message = getResponseCodes[getLanguageUsed]['TOKEN_EXPIRED_ERROR'];
                    const {setAdmin} = authorisedAdminStore;
                    setNotificationObject({
                        type: 'warning',
                        message: message,
                        activityButton: {
                            message: 'Ok',
                            isCloseNotification: false,
                            action: () => {
                                setAdmin({});
                                props.history.push('/login');
                            }
                        }
                    });
                } else {
                    getData.getUnsuccessfullyNotification({
                        languageStore,
                        notificationStore,
                        dictionariesStore
                    }, 'CHANGES_SAVE_UNSUCCESSFULLY')
                }
            })
    }

    return <React.Fragment>
        <div className="help-edit-container">
            <form id="edit-help" onSubmit={(event) => {
                event.preventDefault();
                setOpenPopup(true);
                setNamePopup('save')
            }}>
                <div className="edit-container">
                    <Tabs>
                        <TabList className="admin-edit-list">
                            {getSortedLanguageList.map(lang => {
                                return (<Tab className="default-edit-tab" selectedClassName="selected-edit-tab"
                                             key={`tab-${lang.lang}`}>{lang.lexeme}</Tab>)
                            })}
                        </TabList>
                        {getSortedLanguageList.map(item => {
                            const {lang} = item;
                            return (<TabPanel key={`tab-panel-${lang}`}>
                                <HowGetHelpTextEditTemplate helpState={helpState.help[lang]} lang={lang}
                                                            setHelpState={setHelpState}/>
                            </TabPanel>)
                        })}
                    </Tabs>
                </div>


            </form>
        </div>
        <PopupWidget data={{isOpened: isOpenPopup, actionClose: setOpenPopup}}>
            {getNamePopup() === 'save' && <ConfirmAction
                data={{confirmMessage: CONFIRM_SAVING_CHANGES, actionYes: confirmSave, actionNo: notConfirm}}/>}
            {getNamePopup() === 'revert' && <ConfirmAction
                data={{confirmMessage: CONFIRM_REVERTING_CHANGES, actionYes: confirmRevert, actionNo: notConfirm}}/>}
        </PopupWidget>
        <PageFooter>
            <div className="edit-cancel-btns">
                <SaveChangesButton formName="edit-help" stateIsDisabledBtn={helpState.isInitial}/>
                <CancelChangesButton data={{
                    setOpenPopupRevert: setOpenPopup,
                    setNamePopup: setNamePopup,
                    stateIsDisabledBtn: helpState.isInitial
                }}/>
            </div>
        </PageFooter>
    </React.Fragment>
});

export default HowGetHelpEditPage;

function getHelpReducer(state, actions) {
    let _state = {...state};

    if (actions.hasOwnProperty('setInitial')) {
        _state = JSON.parse(JSON.stringify(actions.setInitial))
    } else {
        const lang = Object.keys(actions)[0];
        _state.help[lang] = actions[lang];
        _state.isInitial = false;
    }

    return _state
}