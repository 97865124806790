import React from 'react';
import {observer} from 'mobx-react';
import {cookiesActions} from '../../actions';
import {languageStore, globalSettingsStore} from '../../stores';

import ru from '../../resources/images/langRu.svg';
import en from '../../resources/images/langEn.svg';

const ChangeLanguage = observer(props => {
    const {isAdminPage} = props;
    const {getGlobalSettings} = globalSettingsStore;

    let langList = getGlobalSettings.languages;
    const langImgArr = {'ru': ru, 'en': en};

    const {getLanguageUsed, setLanguageUsedAction} = languageStore;
    let defaultLang = getLanguageUsed;

    const changeLanguage = lng => {
        setLanguageUsedAction(lng);
        if (lng !== cookiesActions.getCookiesOnKey('data_lang_DB')) {
            cookiesActions.setCookiesOnKey('data_lang_DB', lng);
        }
    };

    return langList && defaultLang && (
        isAdminPage ?

            <div className="languages-admin">
                <div className="current-language"><img src={langImgArr[defaultLang]}/>
                    <div className="tick"/>
                </div>
                <div className="wrapper-language">
                    <div className="fields-language">
                        {langList.length > 0 &&
                        langList.map(langObj => {
                            const {lang, lexeme} = langObj;
                            return <div className="lang-field" data-lang={lang.lang} key={lang} onClick={() => changeLanguage(lang)}><img
                                src={langImgArr[lang]}/>{lexeme}</div>
                        })}
                    </div>
                </div>
            </div>

            :

            <div className="languages-not-admin">
                <ul>
                    {langList.length > 0 && langList.map(langObj => {
                        const {lang} = langObj;
                        return <li
                        className={defaultLang === lang ? 'is-selected' : ''} key={lang}
                        onClick={() => changeLanguage(lang)}>{lang.toUpperCase()}</li>})}
                </ul>
            </div>)

});

export default ChangeLanguage;