import Cookies from 'universal-cookie';

class CookiesActions {

    getCookiesOnKey(key) {
        const cookies = new Cookies();
        return cookies.get(key);
    }

    setCookiesOnKey(key, value) {
        let date = new Date();
        const cookies = new Cookies();
        date.setFullYear(date.getFullYear() + 1);
        cookies.set(key, value, {path: '/', expires: date})
    }
}

export default CookiesActions;