import React from 'react';
import {getData} from '../../../actions';

export default function ProgressBar(props) {
    let percent = parseFloat(props.data.percent);
    const colorBackground = '#F0E0E0';
    const minColorPercent = 1.5;

    let styleCollected = {
        background: getData.getColorPercent(percent)
    };
    let styleDefault = {
        background: colorBackground
    };

    let defaultPercent = 100 - percent;

    if (percent < 50) {
        if (percent < minColorPercent) {
            percent = minColorPercent;
        }

        styleDefault.width = defaultPercent + "%"
    } else if (percent === 100) {
        styleCollected.borderRadius = "6px";
    } else if (percent > 100) {
        styleCollected.borderRadius = "6px";
        styleCollected.width = 100 + "%";
    } else {
        if (defaultPercent < minColorPercent) {
            defaultPercent = minColorPercent;
            percent = 100 - defaultPercent;
        }

        styleDefault.width = defaultPercent + "%"
    }

    styleCollected.width = percent + "%";

    return <div className="progress-bar-container">
        <div className="progress-bar">
            <div className="collected" style={styleCollected}/><div className="default" style={styleDefault}/>
        </div>
    </div>
};
