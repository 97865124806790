import React, {useReducer, useState} from 'react';
import {observer} from 'mobx-react';
import CurrencyFormat from 'react-number-format';
import Select from 'react-select';
import parse from 'html-react-parser';
import {languageStore, dictionariesStore, fundInformationStore, notificationStore, globalSettingsStore} from '../../../stores';
import {cookiesActions, getData, stringActions, utils} from '../../../actions';
import logoMC from '../../../resources/images/mc.png'

const DonateForm = observer(({payeeId, depNo}) => {

    const {getLanguageUsed, getDefaultLang} = languageStore;
    const {getLexemes} = dictionariesStore;
    const {DONATE, NAME_PROJECT, NAME_DONATOR, HINT_NAME_DONATOR, DONATOR_MESSAGE, HINT_DONATOR_MESSAGE, PHONE_NUMBER, HINT_PHONE_NUMBER, SUM_HELP, HINT_SUM_NUMBER, PROCESSING_PERSONAL_DATA, ABOUT_DONATE, PAYMENT, DESCRIPTION_BILL_MSG} = getLexemes[getLanguageUsed].donate;

    const {getGlobalSettings} = globalSettingsStore;

    const {getFundInformation} = fundInformationStore;
    const {settings, information, projects} = getFundInformation(payeeId, getLanguageUsed);
    const {defaultCurrency} = settings

    const {currency_list} = getLexemes[getLanguageUsed];
    const {symbol} = currency_list.find(item => {return item.code === defaultCurrency});

    const donation = getDonateObj(information.titleFund, depNo, projects);

    const [stateDonate, dispatchDonate] = useReducer(reducerEditedDonate, getPurposeDonate(payeeId, donation.selectedDonate.value));

    const [stateSelectDonate, setStateSelectDonate] = useState(donation.selectedDonate)

    const submitDonateForm = (event) => {
        event.preventDefault();

        const prjPropName = getPrjPropName(stateDonate, getFundInformation(payeeId, getDefaultLang.lang));
        const descriptionBill = stringActions.setVariablesToString(DESCRIPTION_BILL_MSG, ['name_fund'], {name_fund: information.titleFund});

        const data = Object.assign({}, stateDonate, {description: descriptionBill, defaultCurrency: defaultCurrency})

        if (data.hasOwnProperty('paramValue')) {
            Object.assign(data.paramValue, {prjPropName: prjPropName})
        } else {
            Object.assign(data, {paramValue: {prjPropName: prjPropName}})
        }

        return utils.sendRequest('POST', '/api/v2/app/make-donate', data)
            .then(response => {
                if (response.data.hasOwnProperty('link')) {
                    redirectToPayment(response.data.link);
                } else {
                    getData.getUnsuccessfullyNotification({languageStore, dictionariesStore, notificationStore}, 'UNSUCCESSFUL_GENERATE_BILL')
                }
            })
    }

    const checkedProcessingPersonalData = (check) => {
        if (check) {
            if (!cookiesActions.getCookiesOnKey('pdata')) {
                cookiesActions.setCookiesOnKey('pdata', '1')
            }
            dispatchDonate({checkPersonalData: true})
        } else {
            dispatchDonate({checkPersonalData: false})
        }
    }

    return <div className="donate-form-container">
        <div className="donate-form-header">{DONATE}</div>
        <form className="donate-form" onSubmit={submitDonateForm}>
            <div className="input-container">
                <label htmlFor="name-project" className="title-container">
                    {NAME_PROJECT}
                </label>
                <Select id="name-project" options={donation.optionsDonate} value={stateSelectDonate} onChange={(event) => {
                    setStateSelectDonate(event);
                    dispatchDonate(getPurposeDonate(payeeId, event.value));
                }}/>
            </div>
            <div className="input-container">
                <label htmlFor="name-donator" className="title-container">
                    {NAME_DONATOR}
                </label>
                <input id="name-donator" type="text" placeholder={HINT_NAME_DONATOR}
                       onChange={(e) => {dispatchDonate({paramValue: {FIO: e.target.value}})}}/>
            </div>
            <div className="input-container">
                <label htmlFor="donator-message" className="title-container">
                    {DONATOR_MESSAGE}
                </label>
                <input id="donator-message" type="text" placeholder={HINT_DONATOR_MESSAGE}
                       onChange={(e) => {dispatchDonate({paramValue: {AdditionalMessage: e.target.value}})}}/>
            </div>
            <div className="input-container">
                <label htmlFor="phone-number" className="title-container">
                    {PHONE_NUMBER}
                </label>
                <CurrencyFormat id="phone-number" format="+# (###) ###-####" mask="_"
                                placeholder={HINT_PHONE_NUMBER}
                                onValueChange={(values) => {dispatchDonate({phone: '+' + values.value})}}/>
            </div>
            <div className="input-container">
                <label htmlFor="donation-sum" className="title-container">
                    {SUM_HELP}({symbol})
                </label>
                <CurrencyFormat id="donation-sum" required={true} placeholder={HINT_SUM_NUMBER} isNumericString={true}
                                thousandSeparator={true}
                                onValueChange={(values) => {dispatchDonate({donateSum: values.value})}}/>
            </div>
            <div className="consent-container">
                <input id="personal-data" type="checkbox"
                       onChange={(event) => {checkedProcessingPersonalData(event.target.checked)}}/>
                <label htmlFor="personal-data">
                    {parse(getMessageAgree(PROCESSING_PERSONAL_DATA, {getGlobalSettings, getLanguageUsed}))}
                </label>
            </div>
            <button className="payment" disabled={isDisabledButton(stateDonate)} type="submit">{PAYMENT}</button>
        </form>
        <div className="about-container">
            <img src={logoMC} alt="logo of MobiCash"/>
            <div className="about">{parse(getMessageAboutMobicash(ABOUT_DONATE, {getGlobalSettings, getLanguageUsed}))}</div>
        </div>
    </div>
})

export default DonateForm;

function reducerEditedDonate(state, action) {
    let _state = Object.assign({}, state)

    if (action.hasOwnProperty('paramValue') && _state.hasOwnProperty('paramValue')) {
        _state.paramValue = Object.assign({}, _state.paramValue, action.paramValue);
    } else {
        Object.assign(_state, action);
    }

    return _state;
}

function redirectToPayment(link) {
    window.location = link;
    return null;
}

function getPurposeDonate (payeeId, key) {
    let depNo = undefined;

    if (key !== '0') {
        depNo = key
    }

    return {payeeId, depNo}
}

function getMessageAgree (messageLexeme, stores) {
    const {getGlobalSettings, getLanguageUsed} = stores;
    const {documents} = getGlobalSettings;
    const documentsOnLang = documents[getLanguageUsed];
    const {template, keys} = messageLexeme;

    const obj = Object.keys(keys).reduce((resultObj, item) => {
        return {
            ...resultObj,
            [item]: '<a href="' + documentsOnLang[item] + '" target="_blank" rel="noopener noreferrer">' + keys[item] + '</a>'
        }
    }, {})

    return stringActions.setVariablesToString(template, Object.keys(keys), obj)
}

function getMessageAboutMobicash(messageLexeme, stores) {
    const {getGlobalSettings, getLanguageUsed} = stores;
    const {mobicash_site} = getGlobalSettings.links;
    const {template, keys} = messageLexeme;

    return stringActions.setVariablesToString(template, Object.keys(keys), {MobiCash: '<a href="' + mobicash_site[getLanguageUsed] + '" target="_blank" rel="noopener noreferrer" >' + keys.MobiCash + '</a>'});
}

function getDonateObj(titleFund, depNo, projects) {
    const result = {
        selectedDonate: {},
        optionsDonate: []
    };

    if (depNo) {
        const project = projects
            .find(item => {return item.depNo === depNo})

        Object.assign(result.selectedDonate, {value: depNo, label: project.information.titleProg});
        result.optionsDonate.push({value: depNo, label: project.information.titleProg})
    } else {
        const fundObj = {value: '0', label: titleFund};
        let donateArr = projects
            .filter(project => {
                if (project.hasOwnProperty('settings') && project.settings.status === 'active') {
                    return project;
                }
            })
            .map(project => {
                return {value: project.depNo, label: project.information.titleProg}
            });

        donateArr.unshift(fundObj);

        Object.assign(result.selectedDonate, fundObj);
        result.optionsDonate = [].concat(donateArr);
    }

    return result;
}

function getPrjPropName(state, store) {
    const {depNo} = state;

    if (depNo) {
        const project = store.projects.find(item => {return item.depNo === depNo});
        return project.information.titleProg;
    } else {
        return store.information.titleFund
    }

}

function isDisabledButton(state) {
    const {checkPersonalData, donateSum} = state;
    return !(checkPersonalData && donateSum && donateSum !== '');
}