import React from 'react';
import {Link} from 'react-router-dom';
import CalcTotals from '../../common/calcTotals/CalcTotals';
import Logo from '../../common/Logo';
import {urlGenerate, stringActions} from '../../../actions';

export default function ProgramInfo(props) {
    const {payeeId, depNo, title, description, dataCalcTotals, photo} = props.data;

    let logo = urlGenerate.photoUrl(payeeId, depNo, photo);
    let _description = stringActions.substringText(description, 150);

    return (<div className="admin-program-container">
        <Link to={`/admin/${payeeId}/project/${depNo}/edit`}>
            <div className="admin-program-info">
                <div className="admin-program-photo">
                    <Logo url={logo}/>
                </div>
                <div className="admin-program-title">
                    {title}
                </div>
                <div className="admin-program-description">
                    {_description}
                </div>
                <CalcTotals data={dataCalcTotals} format={{isFullDress: false}}/>
            </div>

        </Link>


    </div>)
}
