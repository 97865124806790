import {globalSettingsStore} from '../stores';
import {stringActions} from './index'

export default class GetData {

    getFundByLang(fund, lang) {
        const defaultLang = 'ru';
        const _fund = JSON.parse(JSON.stringify(fund));
        const replacer = (obj) => {
            if (typeof obj[defaultLang] === 'string') {
                if (lang !== defaultLang && obj[lang] === '') {
                    return obj[defaultLang];
                } else {
                    return obj[lang];
                }
            } else {
                const _obj = {};

                for (const [key, valueKey] of Object.entries(obj[lang])) {
                    const _valueKey = stringActions.replaceTagsInText(valueKey)
                    if (_valueKey === '') {
                        Object.assign(_obj, {[key]: obj[defaultLang][key]})
                    } else {
                        Object.assign(_obj, {[key]: valueKey})
                    }
                }

                return _obj;
            }
        }

        _fund.information = replacer(fund.information);

        if (fund.hasOwnProperty('specialization')) {
            _fund.specialization = replacer(fund.specialization)
        }

        if (fund.hasOwnProperty('requisites')) {
            const requisitesLangRules = ['legal_address', 'actual_address', 'state_registration_auth'];
            requisitesLangRules.forEach(rule => {
                if (fund.requisites.hasOwnProperty(rule)) {
                    _fund.requisites[rule] = replacer(fund.requisites[rule]);
                }
            })
        }

        if (_fund.hasOwnProperty('projects') && _fund.projects.length > 0) {
            _fund.projects = _fund.projects.map(project => {
                const _project = JSON.parse(JSON.stringify(project));
                _project.information = replacer(project.information);
                return _project;
            })
        }
        return _fund
    }

    getNewsByLang(news, lang, defaultLang) {
        const newsArr = news.map(item => {
            const _item = JSON.parse(JSON.stringify(item));

            if (lang !== defaultLang) {
                const {text} = _item;
                const newText = {text: text[lang]};

                if (text[lang].title === '') {
                    Object.assign(newText.text, {title: text[defaultLang].title})
                }

                if (stringActions.replaceTagsInText(text[lang].description) === '') {
                    Object.assign(newText.text, {description: text[defaultLang].description})
                }

                Object.assign(_item, newText)

            } else if (lang === defaultLang) {
                Object.assign(_item, {text: _item.text[lang]})
            }

            return _item;
        })

        return newsArr;
    }

    /**
     * для формирования массива программ из объектов, которые необходимы для компонента InfoBlock
     * @param data
     * @param status
     * @returns {Array}
     */
    listProgramOnStatus(data, status) {
        let list = [];
        if (data.projects.length > 0) {
            list = data.projects
                .filter(project => project.settings.status === status)
                .map(statusProgram => {
                    return this.componentInfoBlock(statusProgram.depNo, data);
                });
        }
        return list;
    }

    getActiveProjectsByPriority(activeProjects, priorityList) {
        const arr = [];

        priorityList.forEach(depNoPriority => {
            const programByPriority = activeProjects.find(program => {
                return program.depNo === depNoPriority;
            });

            if (programByPriority) {
                return arr.push(programByPriority);
            }
        })

        return arr;
    }

    /**
     * генерация объекта с информацией для компонента InfoBlock
     * @param depNo
     * @param data
     * @returns {{payeeId: string, depNo: string, title: string, description: string, photo: string, photoFund,
     *     titleFund, categories: (*|Array), dataCalcTotals: {payeeId, depNo, defaultCurrency, startDate, expDate, sum,
     *     lastOper, rowCount}}}
     */
    componentInfoBlock(depNo, data) {
        const {payeeId, logoFund} = data;
        const {titleFund, descriptionFund} = data.information;
        const {categoriesFund} = data.settings;

        let title = '';
        let description = '';
        let logo = '';
        let categories = [];
        let status = '';
        let settingsProject = {};

        if (depNo === '' || !depNo) {
            title = titleFund;
            description = descriptionFund;
            logo = logoFund;
            categories = categoriesFund;
        } else {
            let programInfo = data.projects.find(program => {
                return program.depNo === depNo
            });

            title = programInfo.information.titleProg;
            description = programInfo.information.descriptionProg;
            logo = programInfo.logoProg;
            categories = programInfo.settings.categoriesProg;
            status = programInfo.settings.status;
            settingsProject = programInfo.settings
        }

        return {
            payeeId: payeeId,
            depNo: depNo,
            title: title,
            description: description,
            photo: logo,
            photoFund: logoFund,
            titleFund: titleFund,
            categories: categories,
            status: status,
            dataCalcTotals: this.componentsCalcTotalsOrReadBillList(payeeId, depNo, data.settings, settingsProject)
        }
    }

    /**
     * объект для компонентов CalcTotals и ReadBillList
     * @param payeeId
     * @param depNo
     * @param settingsFund
     * @param settingsProject
     * @returns {{payeeId: *, depNo: *, defaultCurrency: *, startDate: (*|string), expDate: string, sum: string,
     *     lastOper: *, rowCount: *}}
     */
    componentsCalcTotalsOrReadBillList(payeeId, depNo, settingsFund, settingsProject = {}) {
        let startDate = '';
        let expDate = '';
        let sum = '';

        if (depNo === '' || !depNo) {
            startDate = settingsFund.startDateFund;
        } else {
            startDate = settingsProject.startDateProg;
            expDate = settingsProject.expDate;
            sum = settingsProject.sum;
        }

        return {
            payeeId: payeeId,
            depNo: depNo,
            defaultCurrency: settingsFund.defaultCurrency,
            startDate: startDate,
            expDate: expDate,
            sum: sum,
            lastOper: settingsFund.lastOper,
            rowCount: settingsFund.countLastOper
        }
    }

    usedCategories(stores, categoryIdsArr) {

        const {languageStore, dictionariesStore} = stores;
        const {getLanguageUsed} = languageStore;
        const {getCategoriesFormatted} = dictionariesStore;
        const allCategories = getCategoriesFormatted(getLanguageUsed);

        return categoryIdsArr.map(idCategory => {
            return allCategories.find(category => {
                return category.value === idCategory
            })
        })
    }

    getColorPercent(percent) {
        /* Сolors for progress-bar*/
        percent = parseFloat(percent);
        const colorPercentLessThanHalf = '#EC1310'; //Percent < 50%
        const colorPercentEqualToHalf = '#EE7A21'; //50% =< Percent < 100%
        const colorPercentEqualToOne = '#76B943'; //Percent = 100%
        const colorPercentMoreThanOne = '#004d00'; //Percent > 100%

        if (percent < 50) {
            return colorPercentLessThanHalf;
        } else if (percent === 100) {
            return colorPercentEqualToOne;
        } else if (percent > 100) {
            return colorPercentMoreThanOne;
        } else {
            return colorPercentEqualToHalf;
        }
    }

    getCategoryValues(event) {
        if (event) return event.reduce((result, item) => {
            result.push(item.value);
            return result
        }, []);
        else return [];
    }

    getSuccessfullyNotification(stores, nameMessage) {
        const {languageStore, notificationStore, dictionariesStore} = stores;
        const {getLanguageUsed} = languageStore;
        const {setNotificationObject} = notificationStore;
        const {getResponseCodes} = dictionariesStore;
        const message = getResponseCodes[getLanguageUsed][nameMessage];
        setNotificationObject({
            type: 'success',
            message: message,
            activityButton: {
                message: 'Ok',
                isCloseNotification: true
            }
        });
    }

    getSuccessfullyNotificationWithLang(stores, emptyLang) {
        const {languageStore, notificationStore, dictionariesStore} = stores;
        const {getLanguageUsed} = languageStore;
        const {setNotificationObject} = notificationStore;
        const {getResponseCodes} = dictionariesStore;
        const {CHANGES_SAVE_SUCCESSFULLY, TEXT_IS_NOT_FILLED} = getResponseCodes[getLanguageUsed];
        const {getGlobalSettings} = globalSettingsStore;
        const {languages} = getGlobalSettings;

        let message = CHANGES_SAVE_SUCCESSFULLY;

        if (emptyLang.length > 0) {
            message += String.fromCharCode(10);
            message += TEXT_IS_NOT_FILLED
            message += '<ul>';
            emptyLang.forEach(lang => {
                message += '<li>' + (languages.find(item => {
                    return item.lang === lang
                })).lexeme + '</li>'
            })
            message += '</ul>'
        }

        setNotificationObject({
            type: 'success',
            message: message,
            activityButton: {
                message: 'Ok',
                isCloseNotification: true
            }
        });
    }

    getUnsuccessfullyNotification(stores, nameMessage) {
        const {languageStore, notificationStore, dictionariesStore} = stores;
        const {getLanguageUsed} = languageStore;
        const {setNotificationObject} = notificationStore;
        const {getResponseCodes} = dictionariesStore;
        const message = getResponseCodes[getLanguageUsed][nameMessage];
        setNotificationObject({
            type: 'warning',
            message: message,
            activityButton: {
                message: 'Ok',
                isCloseNotification: true
            }
        });
    }

    arrSorting = (arr, sort) => {
        const value = sort.hasOwnProperty('value') ? sort.value : '0';
        if (value === '1') {
            return arr.sort((a, b) => new Date(a.date).getTime() - new Date(b.date).getTime())
        } else {
            return arr.sort((a, b) => new Date(b.date).getTime() - new Date(a.date).getTime())
        }
    }
}