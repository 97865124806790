import React, {useEffect, useRef, useState} from 'react';
import {observer} from 'mobx-react';
import {fundInformationStore, languageStore, readPayeeStore} from '../../stores';

const FundContacts = observer(props => {
    const _isMounted = useRef(true);
    const payeeId = props.payeeId;
    const [contacts, setContacts] = useState({});
    const {getLanguageUsed} = languageStore;
    const socialNetworkList = ['Email', 'Facebook', 'Instagram', 'Vkontakte', 'Twitter', 'Ok', 'Skype', 'Youtube', 'Telegram', 'LinkedIn'];

    useEffect(() => {
        return () => {
           _isMounted.current = false;
        }
    }, []);

    useEffect(() => {
        const {getFundInformation} = fundInformationStore;
        const {getFundReadPayee} = readPayeeStore;getFundReadPayee(payeeId, getLanguageUsed)
            .then(response => {
                if (Object.keys(response).length > 0) {
                    let url = response.information.Url ? response.information.Url._text : '';

                    let urlComponent = '';
                    if (url !== '') {
                        urlComponent = <a href={checkHostLink(url)}>{url}</a>
                    }

                    let networks = socialNetworkList.map(network => {
                        let link = response.information[network] ? response.information[network]._text : '';
                        if (network === 'Email' && link !== '') {
                            link = `mailto:${link}`
                        }

                        return {
                            key: network,
                            value: link
                        }
                    })
                        .filter(network => {
                            return network.value !== ''
                        });

                    let phone = response.information.Phone ? response.information.Phone._text : '';

                    if (_isMounted.current) {
                        setContacts({
                            urlComponent: urlComponent,
                            networks: networks,
                            phone: phone
                        })
                    }
                }
            });
    }, [getLanguageUsed])

    return(Object.keys(contacts).length > 0 && <div className="links">
            {contacts.phone !== '' && <div className="phone-fund">{contacts.phone}</div>}
            {contacts.urlComponent !== '' && <div className="link-fund">{contacts.urlComponent}</div>}
            <div className="social-network">
                {contacts.networks.map(socNet => {
                    return (<a className={socNet.key.toLowerCase()} href={checkHostLink(socNet.value)} key={socNet.key}/>)
                })}
            </div>
    </div>)
});

export default FundContacts;

function checkHostLink(link) {
    if (link.indexOf('http') < 0 && link.indexOf('mailto') < 0) {
        return `https://${link}`
    } else {
        return link
    }
}