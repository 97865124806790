import React from 'react';
import {Link} from 'react-router-dom';
import {authorisedAdminStore, dictionariesStore, languageStore} from '../../../stores';
import {tokenActions} from '../../../actions'
import accountImg from '../../../resources/images/account_circle.svg';

export default function Account({logoFund, setPopupFeedbackIsOpened}) {
    const {setAdmin, getAdmin} = authorisedAdminStore;
    const {getLanguageUsed} = languageStore;
    const {getLexemes} = dictionariesStore;

    const {SING_OUT} = getLexemes[getLanguageUsed].logon;
    const {FEEDBACK_FORM} = getLexemes[getLanguageUsed].admin_feedback;

    const removeAuth = () => {
        tokenActions.destroyToken({Authorization: getAdmin.token})
            .finally(result => {
                setAdmin({});
            })
    }

    return (<div className="account">
        <div className="account-info">
            <div className="account-img">
                <img src={logoFund === '' ? accountImg : logoFund} alt="logo of fund"/>
            </div>
            <div className="open-menu"/>
        </div>
        <div className="account-menu-wrapper">
            <div className="account-menu">
                <Link to={`/fund/${getAdmin.payeeId}/about/info`} onClick={removeAuth}>
                    <div className="field-menu">
                        {SING_OUT}
                    </div>
                </Link>
                <div className="field-menu" onClick={() => {setPopupFeedbackIsOpened(true)}}>
                    {FEEDBACK_FORM}
                </div>
            </div>
        </div>
    </div>)
}
