import React, {useEffect, useState} from 'react';
import {observer} from 'mobx-react';
import {Link} from 'react-router-dom';
import {languageStore, dictionariesStore, mobileMenuStore} from '../../../../stores';

const MobileMenu = observer(({payeeId, activeComponent}) => {
    const {isOpenMobileMenu, setIsOpenMobileMenu} = mobileMenuStore;
    const [activeLink, setActiveLink] = useState({...activeComponent});

    useEffect(() => {
        setIsOpenMobileMenu(false)
        setActiveLink({...activeComponent});
    }, [activeComponent, setIsOpenMobileMenu])

    const {getLanguageUsed} = languageStore;
    const {getLexemes} = dictionariesStore;
    const {ABOUT, PROJECTS, DOCUMENTS, NEWS, REPORTS} = getLexemes[getLanguageUsed].fund_page;
    const {fund_page} = getLexemes[getLanguageUsed];
    const pathRules = ['info', 'team', 'requisites', 'help'];

    return <div className={isOpenMobileMenu ? "mobile-menu mobile-menu-open" : "mobile-menu mobile-menu-close"}>
        <div className="links-mobile">
            <div className={(activeLink.page === 'about' && activeLink.component === 'fund')? "link-mobile active" : "link-mobile"}>
                {ABOUT.toUpperCase()}
                <div className="sub-links">
                    {pathRules.map(path => {
                        return <Link key={path} to={`/fund/${payeeId}/about/${path}`}>
                            <div className={activeLink.tab === path ? "active" : ""}>{fund_page[path.toUpperCase()]}</div>
                        </Link>
                    })}
                </div>
            </div>

            <Link to={`/fund/${payeeId}/news-list`} className="links">
                <div
                    className={activeLink.page === 'news-list' ? "link-mobile active" : "link-mobile"}>{NEWS.toUpperCase()}</div>
            </Link>
            <Link to={`/fund/${payeeId}/reports`} className="links">
                <div
                    className={activeLink.page === 'reports' ? "link-mobile active" : "link-mobile"}>{REPORTS.toUpperCase()}</div>
            </Link>
            <Link to={`/fund/${payeeId}/projects`} className="links">
                <div
                    className={(activeLink.page === 'projects' || activeLink.component === 'project') ? "link-mobile active" : "link-mobile"}>{PROJECTS.toUpperCase()}</div>
            </Link>
            <Link to={`/fund/${payeeId}/documents`} className="links">
                <div
                    className={activeLink.page === 'documents' ? "link-mobile active" : "link-mobile"}>{DOCUMENTS.toUpperCase()}</div>
            </Link>
        </div>
    </div>
});

export default MobileMenu;