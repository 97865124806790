import {action, computed, observable, runInAction, toJS} from 'mobx';
import {utils} from '../actions';

class GlobalSettingsStore {
    @observable globalSettings = {};

    constructor() {
        this.getInformation()
            .then(response => {
                runInAction(() => {
                    this.setGlobalSettingsAction(response)
                })
            })
    }

    @computed get getGlobalSettings() {
        return toJS(this.globalSettings, {recurseEverything: true});
    }

    @action setGlobalSettingsAction = (data) => {
        this.globalSettings = data;
    };

    getInformation() {
        return new Promise((resolve, reject) => {
            return utils.sendRequest('GET', '/api/v2/app/config')
                .then(response => {
                    if (response.data.hasOwnProperty('error')) throw new Error(response.data.error);

                    resolve(response.data);
                })
                .catch(error => reject(error))
        })
    }

}

export default GlobalSettingsStore;