import React from 'react';
import {observer} from 'mobx-react';
import ReactQuill, {Quill} from 'react-quill';
import {languageStore, dictionariesStore} from '../../../stores';
import {stringActions} from '../../../actions';

const icons = Quill.import('ui/icons');

const HowGetHelpTextEditTemplate = observer(({helpState, lang, setHelpState}) => {
    const {getLanguageUsed, getSortedLanguageListStr} = languageStore;
    const {getLexemes} = dictionariesStore;
    const {TITLE_OF_HELP, RECOMMENDED_MSG} = getLexemes[getLanguageUsed].admin_edit;

    const _RECOMMENDED_MSG = stringActions.setVariablesToString(RECOMMENDED_MSG, ['languages'], {languages: getSortedLanguageListStr})

    const modules = React.useMemo(() => ({
        toolbar: {
            container: [
                [{'header': [1, 2, 3, 4, 5, 6, false]}],
                [{'size': ['small', false, 'large', 'huge']}],
                ['bold', 'italic', 'underline', 'strike', 'blockquote'],
                [{'list': 'ordered'}, {'list': 'bullet'}, {'indent': '-1'}, {'indent': '+1'}],
                [{'color': []}, {'background': []}],
                [{'align': []}],
                ['link']
            ],
        },
        clipboard: {matchVisual: false}
    }), []);

    icons['code-block'] = '<svg viewBox="0 0 18 18">\n' +
        '<rect class="ql-stroke" height="10" width="12" x="3" y="4"></rect>' +
        '<line class="ql-stroke" x1="7" x2="11" y1="7" y2="11"></line>' +
        '        <path class="ql-even ql-stroke"\n' +
        '              d="M8.9,4.577a3.476,3.476,0,0,1,.36,4.679A3.476,3.476,0,0,1,4.577,8.9C3.185,7.5,2.035,6.4,4.217,4.217S7.5,3.185,8.9,4.577Z"></path>\n' +
        '        <path class="ql-even ql-stroke"\n' +
        '              d="M13.423,9.1a3.476,3.476,0,0,0-4.679-.36,3.476,3.476,0,0,0,.36,4.679c1.392,1.392,2.5,2.542,4.679.36S14.815,10.5,13.423,9.1Z"></path>\n' +
        '    </svg>';
    return <>
        <div className="description-container">
            <label htmlFor={`description-${lang}`}>{TITLE_OF_HELP}</label>

            <ReactQuill
                required={true}
                theme='snow'
                modules={modules}
                value={helpState}
                onChange={(val, delta, source) => {
                    if (source === 'user') {
                        setHelpState({[lang]: val})
                    }
                }}
            />
        </div>
        <div className="recommended-container">
            {_RECOMMENDED_MSG}
        </div>
    </>
})

export default HowGetHelpTextEditTemplate;