import React, {useState} from 'react'
import {observer} from 'mobx-react';
import {useHistory} from 'react-router-dom';
import {
    languageStore,
    fundInformationStore,
    dictionariesStore,
    authorisedAdminStore,
    notificationStore
} from '../../stores';
import {adminActions, tokenActions, utils} from '../../actions';

const FundListForAuth = observer(props => {
    const history = useHistory();

    const {fundListForAuth, login, hash, loginExtRid} = props.data;
    const {getLanguageUsed} = languageStore;
    const {getLexemes, getResponseCodes} = dictionariesStore;
    const {SING_IN, SELECT_FUND_FOR_AUTH, NO_BUSINESS_FOR_AUTH} = getLexemes[getLanguageUsed].logon;

    const {setNotificationObject} = notificationStore;
    const [ridForAuth, setRidForAuth] = useState('');

    return <>
        <div className="fund-list-for-auth">
            {
                fundListForAuth.length > 0 ?
                    <>
                        <div className="title">
                            {SELECT_FUND_FOR_AUTH}
                        </div>
                        <form className="fund-list" onChange={(event) => {
                            setRidForAuth(event.target.value)
                        }} onSubmit={(event) => {
                            event.preventDefault();
                            readEmployee({login, hash, loginExtRid, rid: ridForAuth})
                                .then(response => {
                                    return tokenActions.getAuthToken({login, hash})
                                        .then(responseToken => {
                                            const {payeeId} = response;
                                            const urlAdmin = adminActions.getRedirectUrlOnAdminPage(payeeId, fundInformationStore);
                                            history.push(urlAdmin);

                                            const {setAdmin} = authorisedAdminStore;
                                            setAdmin({payeeId, login, ...responseToken});
                                        })
                                        .catch(error => {
                                            setNotificationObject({
                                                type: 'warning',
                                                message: getResponseCodes[getLanguageUsed][error.message],
                                                activityButton: {
                                                    message: 'Ok',
                                                    isCloseNotification: true
                                                }
                                            });
                                        })
                                })
                                .catch(error => {
                                    setNotificationObject({
                                        type: 'warning',
                                        message: getResponseCodes[getLanguageUsed][error.message],
                                        activityButton: {
                                            message: 'Ok',
                                            isCloseNotification: true
                                        }
                                    });
                                })
                        }}>
                            {fundListForAuth.map(fund => {
                                const {Rid, Name} = fund;
                                return <div className="fund-auth" key={Rid._text}>
                                    <input type="radio" id={Rid._text} value={Rid._text} name="auth"/>
                                    <label htmlFor={Rid._text}>{Name._text}</label>
                                </div>
                            })}
                            <button className="btn-active" disabled={ridForAuth === ''}>
                                {SING_IN}
                            </button>
                        </form>
                    </>

                    :

                    <div>{NO_BUSINESS_FOR_AUTH}</div>
            }
        </div>
    </>
})

export default FundListForAuth;

function readEmployee(authData) {
    return new Promise((resolve, reject) => {
        return utils.sendRequest('POST', '/api/v2/app/authorisation', authData)
            .then(response => {
                if (response.data.hasOwnProperty('error')) throw new Error(response.data.error);
                resolve({result: true, payeeId: response.data.ReadEmployee._attributes.PayeeId});
            })
            .catch(error => reject(error))
    })
}
