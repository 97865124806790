import React from 'react';
import parse from 'html-react-parser';
import {urlGenerate} from '../../../../../actions';

export default function Member({lang, payeeId, member}) {
    const {info, photo} = member;
    const {fio, position} = info[lang]
    return <div className="member-container">
        <div className="photo">
            <img src={urlGenerate.teamImgUrl(payeeId, photo)} alt="member"/>
        </div>
        <div className="name">
            {parse(decorFio(fio))}
        </div>
        <div className="position">
            {position}
        </div>
    </div>
}

function decorFio(fio) {
    let arrStr = fio.split(/\s/);
    arrStr[0] = `<b>${arrStr[0]}</b>`
    return arrStr.join(' ');
}