import React, {useRef, useState} from 'react'
import {observer} from 'mobx-react';
import {useHistory} from 'react-router-dom';
import {languageStore, fundInformationStore, dictionariesStore} from '../../../stores';
import {urlGenerate, stringActions} from '../../../actions';
import SearchResultBlock from './SearchResultBlock';

const SearchContainer = observer(props => {
    const {getLanguageUsed} = languageStore;
    const {getLexemes} = dictionariesStore;
    const {SEARCH_INPUT, SEARCH, NOTHING_FOUND} = getLexemes[getLanguageUsed].search_page;

    const searchInputRef = useRef(null);
    const [searchResult, setSearchResult] = useState();

    let history = useHistory();
    if (history.location.state && !searchResult) { //переход по кнопкам браузера
        let searchText = history.location.state.question;
        let searchResults = searchForInformation(searchText);
        if (searchResults) setSearchResult({text: searchText, result: searchResults});
    } else if (!history.location.state && searchResult && !history.location.search) { //переход ко кнопкам браузера до пустого поиска
        setSearchResult(undefined);
    } else if (!history.location.state && history.location.search && !searchResult) { // обработка ссылки с поисковым запросом
        let searchText = decodeURI(history.location.search.slice(3));
        let searchResults = searchForInformation(searchText);
        if (searchResults) setSearchResult({text: searchText, result: searchResults});
    }

    const doSearch = () => {
        let searchText = searchInputRef.current.value;
        let searchResults = searchForInformation(searchText);
        if (searchResults) setSearchResult({text: searchText, result: searchResults});
        if (searchResults) history.push(`/search?q=${searchText}`, {question: searchText})
    };

    const handleKeyPress = event => {
        if (event.code === 'Enter' || event.key === 'Enter' || event.which === '13') {
            doSearch();
        }
    };

    return (<div className="search-container">
        <div className="search-field">
            <input maxLength="30" placeholder={searchResult ? searchResult.text : SEARCH_INPUT} ref={searchInputRef}
                   onKeyPress={handleKeyPress}/>
            <button onClick={doSearch}>{SEARCH}</button>
        </div>

        {searchResult && <div className="search-result-field">
            {searchResult.result.length > 0
                ?
                searchResult.result.map(item => {
                    return <SearchResultBlock data={item} key={item.link}/>
                })
                :
                <div className="nothing-found">{NOTHING_FOUND}</div>}
        </div>}
    </div>)
});

export default SearchContainer;

function searchForInformation(searchText) {
    const {getLanguageUsed} = languageStore;
    const {getAllFundInformationStore} = fundInformationStore;

    let regexpText = generateRegexpFromSearchText(searchText);

    if (regexpText) {
        const fundStore = getAllFundInformationStore(getLanguageUsed);

        return fundStore.reduce((resultArr, item) => {
            const {payeeId, information} = item;
            const {titleFund, descriptionFund, projects} = information;

            let searchInTitle = generateTextFromAllMatches(titleFund, regexpText);

            let searchInDescription = generateTextFromAllMatches(descriptionFund, regexpText);

            if (searchInTitle && !searchInDescription) {
                resultArr.push({
                    title: titleFund,
                    findText: stringActions.replaceTagsInText(descriptionFund).slice(0, 300),
                    link: urlGenerate.pageUrl(payeeId)
                });
            } else if (searchInDescription) {
                resultArr.push({
                    title: titleFund,
                    findText: searchInDescription,
                    link: urlGenerate.pageUrl(payeeId)
                });
            }

            if (projects && projects.length > 0) {
                const {settings} = item;
                const {archProgram} = settings;
                const programSettings = settings.projects;

                let programsForSearch = archProgram ?
                    programSettings.filter(sett => {
                        if (sett.status !== 'draft') return sett;
                    })
                    : programSettings.filter(sett => {
                        if (sett.status === 'active') return sett;
                    });
                programsForSearch = programsForSearch.map(item => {
                    return item.depNo
                });

                let searchingArrPrograms = projects
                    .filter(prog => {
                        if (programsForSearch.includes(prog.depNo)) return prog
                    })
                    .reduce((resultArrProgram, item) => {
                        const {depNo, titleProg, descriptionProg} = item;

                        let searchInTitle = generateTextFromAllMatches(titleProg, regexpText);
                        let searchInDescription = generateTextFromAllMatches(descriptionProg, regexpText);

                        if (searchInTitle && !searchInDescription) {
                            resultArrProgram.push({
                                title: titleProg,
                                findText: stringActions.replaceTagsInText(descriptionProg).slice(0, 300),
                                link: urlGenerate.pageUrl(payeeId, depNo)
                            });

                        } else if (searchInDescription) {
                            resultArrProgram.push({
                                title: titleProg,
                                findText: searchInDescription,
                                link: urlGenerate.pageUrl(payeeId, depNo)
                            });
                        }

                        return resultArrProgram;
                    }, []);

                resultArr.push(...searchingArrPrograms);
            }

            return resultArr;
        }, []);
    }
}

function generateRegexpFromSearchText(text) {
    if (text !== '' && text !== ' ') {
        let regExFromInput = text.split(' ');
        regExFromInput = regExFromInput
            .filter(item => {
                return item.length > 0
            })
            .map((item, i) => {
                let specChar = '\[ \\ \^ \$ \. \| \? \* \+ \( \)';
                item = item.split('')
                    .map(function (cha) {
                        cha = (specChar.indexOf(cha) > -1) ? '\\' + cha : cha;
                        return cha;
                    });

                if (item.length > 4) {
                    (i !== (regExFromInput.length - 1)) ? item.splice((item.length - 2), 2, '[a-zа-я0-9]{1,4}(\\s)*[\\s\\S]{0,15}') : item.splice((item.length - 2), 2, '[a-zа-я0-9]{1,4}(\\s)*');
                    item = item.join('');
                    return item;
                } else {
                    (i !== (regExFromInput.length - 1)) ? item.splice((item.length), 0, '[a-zа-я0-9]{0,4}(\\s)*[\\s\\S]{0,15}') : item.splice((item.length), 0, '[a-zа-я0-9]{0,4}(\\s)*');
                    item = item.join('');
                    return item;
                }
            });

        regExFromInput = new RegExp(regExFromInput.join(''), 'gi');

        return regExFromInput;
    }
}

function generateTextFromAllMatches(matchesText, regexp) {
    let allMatches = matchesText.match(regexp);

    if (allMatches) {
        return allMatches.map(item => {
            let position = matchesText.indexOf(item);
            let text = '';
            const constantPos = 50;
            if (position < constantPos) {
                text += stringActions.replaceTagsInText(matchesText).slice(0, position + constantPos);
            } else {
                text += stringActions.replaceTagsInText(matchesText).slice(position - constantPos, position + constantPos)
            }

            return text;
        })
            .reduce((result, item, index) => {
                return index === 0 ? result + item : result + '... ' + item;
            }, '');
    }
}

