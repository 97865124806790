import React from 'react';
import {observer} from 'mobx-react';
import {Link} from 'react-router-dom';
import PageFooter from '../AdminMainPage/PageFooter';
import InfoBlockFund from '../../common/InfoBlockFund';
import FundContacts from '../../common/FundContacts';
import {fundInformationStore, languageStore, dictionariesStore} from '../../../stores';

const AboutFundPage = observer(props => {
    const {payeeId} = props.match.params;
    const {getFundInformation} = fundInformationStore;
    const {getLanguageUsed} = languageStore;
    const {getLexemes} = dictionariesStore;
    const fundInformation = getFundInformation(payeeId, getLanguageUsed);
    const {FOOTNOTE_SOCIAL_NETWORK, TEAM, HELP, EDIT} = getLexemes[getLanguageUsed].admin_about_fund;

    return (<React.Fragment>
        <div className="about-fund-container-admin">
            <div className="about-fund block-about-link">
                <Link to={`/admin/${payeeId}/about/info/edit`}>
                    <InfoBlockFund data={fundInformation} mode="admin"/>
                </Link>
            </div>
            <div className="block-about block-about-link">
                <Link to={`/admin/${payeeId}/about/help/edit`}>
                    <div className="title-block-about">{HELP} / {EDIT}</div>
                </Link>
            </div>
            <div className="block-about block-about-link">
                <Link to={`/admin/${payeeId}/about/team/edit`}>
                    <div className="title-block-about">{TEAM} / {EDIT}</div>
                </Link>
            </div>
            <div className="block-about">
                <FundContacts payeeId={payeeId}/>
            </div>
            <div className="foot-note">
                <span>
                    {FOOTNOTE_SOCIAL_NETWORK}
                </span>
            </div>
        </div>
        <PageFooter/></React.Fragment>)
});

export default AboutFundPage;