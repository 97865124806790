import {action, observable, runInAction, toJS} from 'mobx';
import {utils, getData} from '../actions';

class FundInformationStore {
    @observable informationList = []; // {payeeId: '', payeeRid: '', requisites: {}, settings: {}, information: {[lang]: {}}, projects: [], specialization: {[lang]: {}}}
    @observable photoList = {}; // {[payeeId]: {photos: [], [depNo]: []}}
    @observable reports = {}; // {[payeeId]: []}
    @observable teams = {}; // {[payeeId]: []}
    @observable news = {}; // {[payeeId]: {[lang]: []}}
    @observable photoListNews = {}; // {[payeeId]: {[newsId]: []}}
    @observable documents = {}; // {[payeeId]: []}
    @observable tokenAuth = '';

    constructor() {
        this.getInformation
            .then(result => {
                runInAction(() => {
                    this.setInformationListAction(result)
                })
            })
    }

    // team START
    getTeamOmPayeeId = (payeeId) => {
        return new Promise(resolve => {
            if (this.teams.hasOwnProperty(payeeId)) {
                resolve(toJS(this.teams[payeeId], {recurseEverything: true}))
            } else {
                return this.getTeam(payeeId)
                    .then(teamFund => {
                        this.setTeamInStore(payeeId, teamFund);
                        resolve(toJS(this.teams[payeeId], {recurseEverything: true}))
                    })
            }
        })
    }

    getTeam = (payeeId) => {
        return new Promise((resolve, reject) => {
            return utils.sendRequest('GET', `/api/v2/fund-data/team/${payeeId}`)
                .then(response => {
                    if (response.hasOwnProperty('error')) throw new Error(response.error);
                    else {
                        resolve(response.data.team)
                    }
                })
                .catch(error => console.log(error))
        })
    }

    @action setTeamInStore = (payeeId, data) => {
        this.teams = Object.assign({}, {...this.teams, ...{[payeeId]: data}})
    }

    savingEditedMembersTeam = (payeeId, editArr) => {
        return new Promise((resolve, reject) => {
            return utils.sendRequest('PUT', `/api/v2/fund-data/team/admin/${payeeId}`, editArr, {Authorization: this.tokenAuth})
                .then(response => {
                    if (!response.data.isResult) throw new Error(response.data.message);
                    this.setTeamInStore(payeeId, response.data.message);
                    this.setNewTokenAuth(response.headers.authorization)
                    resolve();
                })
                .catch(error => {
                    console.log(error)
                    reject(error)
                })
        })

    }

    uploadPhotoNewMembersInTeam = (payeeId, files) => {
        const formData = new FormData();
        for (const [key, value] of Object.entries(files)) {
            formData.append('files', value);
        }

        let myHeaders = {
            'Accept': 'application/json, text/plain, */*',
            'Content-Type': 'multipart/form-data'
        }
        Object.assign(myHeaders, {Authorization: this.tokenAuth});
        return new Promise((resolve, reject) => {
            return utils.sendRequest('POST', `/api/v2/fund-data/team/admin/${payeeId}`, formData, myHeaders)
                .then(response => {
                    if (!response.data.isResult) throw new Error(response.data.message);
                    this.setNewTokenAuth(response.headers.authorization)
                    resolve(response.data.message);
                })
                .catch(error => {
                    console.log(error)
                    reject(error);
                })
        })
    }

    deleteMemberFromTeam = (payeeId, deleteArr) => {
        return new Promise((resolve, reject) => {
            return utils.sendRequest('DELETE', `/api/v2/fund-data/team/admin/${payeeId}`, deleteArr, {Authorization: this.tokenAuth})
                .then(response => {
                    if (!response.data.isResult) throw new Error(response.data.message);
                    this.setTeamInStore(payeeId, response.data.message);
                    this.setNewTokenAuth(response.headers.authorization);
                    resolve();
                })
                .catch(error => {
                    console.log(error);
                    reject(error)
                })
        })

    }
    // team END

    // news START
    getNewsOnPayeeIdByLang = (payeeId, lang) => {
        return new Promise(resolve => {
            if (this.news.hasOwnProperty(payeeId)) {
                const news = getData.getNewsByLang(this.news[payeeId], lang, 'ru');
                resolve(toJS(news, {recurseEverything: true}))
            } else {
                return this.getNews(payeeId)
                    .then(newsArr => {
                        this.setNewsInStore(payeeId, newsArr);

                        const news = getData.getNewsByLang(this.news[payeeId], lang, 'ru');

                        resolve(toJS(news, {recurseEverything: true}))
                    })
            }
        })
    }

    getNewsOnPayeeId = (payeeId) => {
        return new Promise(resolve => {
            if (this.news.hasOwnProperty(payeeId)) {
                const news = this.news[payeeId];
                resolve(toJS(news, {recurseEverything: true}))
            } else {
                return this.getNews(payeeId)
                    .then(newsArr => {
                        this.setNewsInStore(payeeId, newsArr);

                        const news = this.news[payeeId];

                        resolve(toJS(news, {recurseEverything: true}))
                    })
            }
        })
    }

    getNews = (payeeId) => {
        return new Promise((resolve, reject) => {
            return utils.sendRequest('GET', `/api/v2/fund-data/news/${payeeId}`)
                .then(response => {
                    if (response.hasOwnProperty('error')) throw new Error(response.error);
                    else {
                        resolve(response.data.news)
                    }
                })
                .catch(error => {
                    console.error(error)
                    reject(error);
                })
        })
    }

    getPhotosNews = (payeeId, newsId) => {
        return new Promise((resolve, reject) => {
            if (this.photoListNews.hasOwnProperty(payeeId) && this.photoListNews[payeeId].hasOwnProperty(newsId)) {
                resolve(this.photoListNews[payeeId][newsId])
            } else {
                return this.getPhotosNewsFromServer(payeeId, newsId)
                    .then(photosNews => {
                        this.setNewsPhotosInStore({payeeId, newsId, photos: photosNews})
                        resolve(photosNews);
                    })
                    .catch(error => {
                        console.error(error)
                        reject(error);
                    })
            }
        })
    }

    @action setNewsPhotosInStore = data => {
        const {payeeId, newsId, photos} = data;
        let newPhotos = {};
        if (this.photoListNews.hasOwnProperty(payeeId)) {
            newPhotos = {
                ...this.photoListNews[payeeId],
                [newsId]: photos
            }
        } else {
            newPhotos = {
                [newsId]: photos
            }
        }

        this.photoListNews = Object.assign({}, {...this.photoListNews, ...{[payeeId]: newPhotos}})
    }

    getPhotosNewsFromServer(payeeId, newsId) {
        return new Promise((resolve, reject) => {
            return utils.sendRequest('GET', `/api/v2/fund-data/photos/news/${payeeId}/${newsId}`)
                .then(response => {
                    if (response.data.hasOwnProperty('error')) throw new Error(response.data.error);
                    else resolve(response.data)
                })
                .catch(error => reject(error))
        })
    }

    @action setNewsInStore = (payeeId, newsArr) => {
        Object.assign(this.news, {[payeeId]: newsArr});
    }

    @action deletePhotosNewsFromStore = (payeeId, newsId) => {
        if (this.photoListNews.hasOwnProperty(payeeId) && this.photoListNews[payeeId].hasOwnProperty(newsId)) {
            let newPhotos = JSON.parse(JSON.stringify(this.photoListNews[payeeId]))
            delete newPhotos[newsId]

            this.photoListNews = Object.assign({}, {...this.photoListNews, ...{[payeeId]: newPhotos}})
        }
    }

    deletePhotosFromNewsById = (payeeId, newsId, deleteArr) => {
        return new Promise((resolve, reject) => {
            return utils.sendRequest('DELETE', `/api/v2/fund-data/photos/news/admin/${payeeId}/${newsId}`, deleteArr, {Authorization: this.tokenAuth})
                .then(response => {
                    if (!response.data.isResult) throw new Error(response.data.message);
                    this.setNewsPhotosInStore({payeeId, newsId, photos: response.data.message});
                    this.setNewTokenAuth(response.headers.authorization);
                    resolve();
                })
                .catch(error => {
                    console.log(error);
                    reject(error)
                })
        })
    }

    uploadPhotosForNewsById = (payeeId, newsId, addArr) => {
        const formData = new FormData();

        for (let k in addArr) {
            formData.append('file', addArr[k]); // appending file
        }

        let myHeaders = {
            'Accept': 'application/json, text/plain, */*',
            'Content-Type': 'multipart/form-data'
        }
        Object.assign(myHeaders, {Authorization: this.tokenAuth})

        return new Promise((resolve, reject) => {
            return utils.sendRequest('POST', `/api/v2/fund-data/photos/news/admin/${payeeId}/${newsId}`, formData, {Authorization: this.tokenAuth})
                .then(response => {
                    if (!response.data.isResult) throw new Error(response.data.message);
                    this.setNewsPhotosInStore({payeeId, newsId, photos: response.data.message});
                    this.setNewTokenAuth(response.headers.authorization);
                    resolve();
                })
                .catch(error => {
                    console.log(error);
                    reject(error)
                })
        })

    }

    @action deleteNews = data => {
        const {payeeId, newsId} = data;
        return new Promise((resolve, reject) => {
            return utils.sendRequest('DELETE', `/api/v2/fund-data/news/admin/${payeeId}/${newsId}`, {}, {Authorization: this.tokenAuth})
                .then(response => {
                    if (!response.data.isResult) throw new Error(response.data.message);
                    this.setNewsInStore(payeeId, response.data.message.news);
                    this.deletePhotosNewsFromStore(payeeId, newsId);
                    this.setNewTokenAuth(response.headers.authorization)
                    resolve({result: true});
                })
                .catch(error => {
                    reject(error)
                })
        })
    }

    savingEditedNews = (payeeId, newsId, data) => {
        return new Promise((resolve, reject) => {
            return utils.sendRequest('PUT', `/api/v2/fund-data/news/admin/${payeeId}/edit-news/${newsId}`, data, {Authorization: this.tokenAuth})
                .then(response => {
                    if (!response.data.isResult) throw new Error(response.data.message);
                    this.setNewsInStore(payeeId, response.data.message.news);
                    this.setNewTokenAuth(response.headers.authorization)
                    resolve({result: true});
                })
                .catch(error => {
                    reject(error)
                })
        })
    }

    createNews = (payeeId, data) => {
        return new Promise((resolve, reject) => {
            return utils.sendRequest('POST', `/api/v2/fund-data/news/admin/${payeeId}/create-news`, data, {Authorization: this.tokenAuth})
                .then(response => {
                    if (!response.data.isResult) throw new Error(response.data.message);
                    this.setNewsInStore(payeeId, response.data.message.news);
                    this.setNewTokenAuth(response.headers.authorization)
                    resolve({result: true, newsNewsId: response.data.message.newNewsId});
                })
                .catch(error => {
                    reject(error)
                })
        })
    }
    // news END

    // reports START
    getReportsOnPayeeId = (payeeId) => {
        return new Promise((resolve, reject) => {
            if (this.reports.hasOwnProperty(payeeId)) {
                resolve(toJS(this.reports[payeeId], {recurseEverything: true}))
            } else {
                return this.getReports(payeeId)
                    .then(reports => {
                        if (reports) {
                            this.setReportsInStore(payeeId, reports);
                            resolve(toJS(this.reports[payeeId], {recurseEverything: true}))
                        } else {
                            throw 'REPORTS_NOT_FOUND'
                        }
                    })
                    .catch(error => {
                        console.log(error)
                        reject(error);
                    })
            }
        })

    }

    getReports = (payeeId) => {
        return new Promise((resolve, reject) => {
            return utils.sendRequest('GET', `/api/v2/fund-data/reports/${payeeId}`)
                .then(response => {
                    if (response.hasOwnProperty('error')) throw new Error(response.error);
                    else {
                        resolve(response.data.reports)
                    }
                })
                .catch(error => {
                    console.log(error)
                    reject(error);
                })
        })
    }

    @action setReportsInStore = (payeeId, reports) => {
        const newReportsFund = {[payeeId]: JSON.parse(JSON.stringify(reports))};
        this.reports = Object.assign({}, {...this.reports, ...newReportsFund});
    }

    uploadNewReports = (payeeId, files) => {
        const formData = new FormData();
        const namesList = {};

        for (let k in files) {
            formData.append('file', files[k].file);
            Object.assign(namesList, {[files[k].fileName.replace(/\s+/g, '_')]: files[k].label})
        }
        formData.append('namesList', JSON.stringify(namesList))

        let myHeaders = {
            'Accept': 'application/json, text/plain, */*',
            'Content-Type': 'multipart/form-data'
        }
        Object.assign(myHeaders, {Authorization: this.tokenAuth});
        return new Promise((resolve, reject) => {
            return utils.sendRequest('POST', `/api/v2/fund-data/reports/admin/${payeeId}`, formData, myHeaders)
                .then(response => {
                    if (!response.data.isResult) throw new Error(response.data.message);
                    this.setReportsInStore(payeeId, response.data.message);
                    this.setNewTokenAuth(response.headers.authorization)
                    resolve();
                })
                .catch(error => {
                    console.log(error)
                    reject(error);
                })
        })
    }

    deleteReports = (payeeId, deleteArr) => {
        return new Promise((resolve, reject) => {
            return utils.sendRequest('DELETE', `/api/v2/fund-data/reports/admin/${payeeId}`, deleteArr, {Authorization: this.tokenAuth})
                .then(response => {
                    if (!response.data.isResult) throw new Error(response.data.message);
                    this.setReportsInStore(payeeId, response.data.message.reports);
                    this.setNewTokenAuth(response.headers.authorization);
                    resolve()
                })
                .catch(error => {
                    console.log(error);
                    reject(error);
                })
        })

    }

    savingEditedReports = (payeeId, editArr) => {
        return new Promise((resolve, reject) => {
            return utils.sendRequest('PUT', `/api/v2/fund-data/reports/admin/${payeeId}`, editArr, {Authorization: this.tokenAuth})
                .then(response => {
                    if (!response.data.isResult) throw new Error(response.data.message);
                    this.setReportsInStore(payeeId, response.data.message);
                    this.setNewTokenAuth(response.headers.authorization)
                    resolve();
                })
                .catch(error => {
                    console.log(error)
                    reject(error);
                })
        })
    }
    // reports END

    // documents START
    getDocumentsOnPayeeId = (payeeId) => {
        return new Promise((resolve, reject) => {
            if (this.documents.hasOwnProperty(payeeId)) {
                resolve(toJS(this.documents[payeeId], {recurseEverything: true}))
            } else {
                return this.getDocuments(payeeId)
                    .then(documents => {
                        if (documents) {
                            this.setDocumentsInStore(payeeId, documents);
                            resolve(toJS(this.documents[payeeId], {recurseEverything: true}))
                        } else {
                            throw 'DOCUMENTS_NOT_FOUND'
                        }
                    })
                    .catch(error => {
                        console.log(error)
                        reject(error);
                    })
            }
        })
    }

    getDocuments = (payeeId) => {
        return new Promise((resolve, reject) => {
            return utils.sendRequest('GET', `/api/v2/fund-data/documents/${payeeId}`)
                .then(response => {
                    if (response.hasOwnProperty('error')) throw new Error(response.error);
                    else {
                        resolve(response.data.documents)
                    }
                })
                .catch(error => {
                    console.log(error)
                    reject(error);
                })
        })
    }

    @action setDocumentsInStore = (payeeId, documents) => {
        const newDocumentsFund = {[payeeId]: JSON.parse(JSON.stringify(documents))}
        this.documents = Object.assign({}, {...this.documents, ...newDocumentsFund})
    }

    uploadNewDocuments = (payeeId, files) => {
        const formData = new FormData();

        const namesList = {};

        for (let k in files) {
            formData.append('file', files[k].file);
            Object.assign(namesList, {[files[k].fileName.replace(/\s+/g, '_')]: files[k].label})
        }
        formData.append('namesList', JSON.stringify(namesList))

        let myHeaders = {
            'Accept': 'application/json, text/plain, */*',
            'Content-Type': 'multipart/form-data'
        }
        Object.assign(myHeaders, {Authorization: this.tokenAuth});
        return new Promise((resolve, reject) => {
            return utils.sendRequest('POST', `/api/v2/fund-data/documents/admin/${payeeId}`, formData, myHeaders)
                .then(response => {
                    if (!response.data.isResult) throw new Error(response.data.message);
                    this.setDocumentsInStore(payeeId, response.data.message);
                    this.setNewTokenAuth(response.headers.authorization)
                    resolve();
                })
                .catch(error => {
                    console.log(error)
                    reject(error);
                })
        })
    }

    deleteDocuments = (payeeId, deleteArr) => {
        return new Promise((resolve, reject) => {
            return utils.sendRequest('DELETE', `/api/v2/fund-data/documents/admin/${payeeId}`, deleteArr, {Authorization: this.tokenAuth})
                .then(response => {
                    if (!response.data.isResult) throw new Error(response.data.message);
                    this.setDocumentsInStore(payeeId, response.data.message.documents);
                    this.setNewTokenAuth(response.headers.authorization)
                    resolve();
                })
                .catch(error => {
                    console.log(error)
                    reject(error);
                })
        })
    }

    savingEditedDocuments = (payeeId, editArr) => {
        return new Promise((resolve, reject) => {
            return utils.sendRequest('PUT', `/api/v2/fund-data/documents/admin/${payeeId}`, editArr, {Authorization: this.tokenAuth})
                .then(response => {
                    if (!response.data.isResult) throw new Error(response.data.message);
                    this.setDocumentsInStore(payeeId, response.data.message);
                    this.setNewTokenAuth(response.headers.authorization)
                    resolve();
                })
                .catch(error => {
                    console.log(error)
                    reject(error);
                })
        })
    }
    // documents END

    // informationList START
    /**
     * вернуть полное хранилище
     * @returns {Array}
     */
    getAllFundInformationStore = (language) => {
        if (language && this.informationList.length > 0) {
            const data = this.informationList.map(fund => {
                return getData.getFundByLang(toJS(fund, {recurseEverything: true}), language)
            })
            return data
        }
    };

    /**
     * Вернуть информацю по конкретному
     * @param payeeId фонду
     * @param language
     * @returns {{payeeId, information: *, settings: *}}
     */
    getFundInformation = (payeeId, language) => {
        if (this.informationList.length > 0 && payeeId) {
            let fund = this.informationList.find(fund => payeeId === fund.payeeId);
            if (fund) {
                if (language) {
                    const _fund = toJS(fund, {recurseEverything: true});
                    return getData.getFundByLang(_fund, language);
                } else {
                    return toJS(fund, {recurseEverything: true})
                }
            }
        }
    };

    @action setFundInfoInListStore = (data) => {
        const {payeeId} = data;
        let _newInformationList = [];
        let infoListByPayeeId = this.informationList.find(item => {
            return item.payeeId === payeeId
        });
        if (infoListByPayeeId) {
            _newInformationList = this.informationList.filter(item => {
                return item.payeeId !== payeeId
            });
            Object.assign(infoListByPayeeId, data)
            _newInformationList.push(infoListByPayeeId);
        } else {
            _newInformationList = [].concat(this.informationList);
            _newInformationList.push(data);
        }
        this.informationList = [].concat(_newInformationList);
    }

    savingEditedHowGetHelp = (payeeId, howGetHelp) => {
        return new Promise((resolve, reject) => {
            return utils.sendRequest('PUT', `/api/v2/fund-data/fund/help/admin/${payeeId}`, howGetHelp, {Authorization: this.tokenAuth})
                .then(response => {
                    if (!response.data.isResult) throw new Error(response.data.message);
                    this.setFundInfoInListStore(response.data.message);
                    this.setNewTokenAuth(response.headers.authorization);
                    resolve();
                })
                .catch(error => {
                    console.log(error);
                    reject(error);
                })
        })
    }

    /**
     * сохраняет новую информацию об редактированном фонде или программе
     * @param data
     * @returns {Promise<any>}
     */
    saveNewInformation = (data) => {
        return new Promise((resolve, reject) => {
            const {payeeId, depNo} = data;
            const url = depNo ? `/api/v2/fund-data/project/admin/${payeeId}/${depNo}` : `/api/v2/fund-data/fund/admin/${payeeId}`;
            return utils.sendRequest('PUT', url, data, {Authorization: this.tokenAuth})
                .then(response => {
                    if (!response.data.isResult) throw new Error(response.data.message);
                    this.setFundInfoInListStore(response.data.message);
                    this.setNewTokenAuth(response.headers.authorization)
                    resolve({isResult: true});
                })
                .catch(error => {
                    reject(error)
                })
        })
    };

    /**
     * записывает новую программу
     * @param data
     * @returns {Promise<any>}
     */
    saveNewProject = (data) => {
        return new Promise((resolve, reject) => {
            const {payeeId} = data;
            return utils.sendRequest('POST', `/api/v2/fund-data/project/create/admin/${payeeId}/`, data, {Authorization: this.tokenAuth})
                .then(response => {
                    if (!response.data.isResult) throw new Error(response.data.message);
                    this.setFundInfoInListStore(response.data.message.information);
                    this.setNewTokenAuth(response.headers.authorization)
                    resolve({
                        result: true,
                        depNo: response.data.message.depNo
                    });
                })
                .catch(error => {
                    reject(error)
                })
        })
    };

    /**
     * удаление программы
     * @param data
     * @returns {Promise<unknown>}
     */
    deleteProject = (data) => {
        return new Promise((resolve, reject) => {
            const {payeeId, depNo} = data;
            return utils.sendRequest('DELETE', `/api/v2/fund-data/project/admin/${payeeId}/${depNo}`, data, {Authorization: this.tokenAuth})
                .then(response => {
                    if (!response.data.isResult) throw new Error(response.data.message);
                    this.setFundInfoInListStore(response.data.message);
                    this.setNewTokenAuth(response.headers.authorization)
                    resolve({result: true});
                })
                .catch(error => {
                    reject(error)
                })
        })
    }

    /**
     * устанавливает значение хранилища
     * @param data
     */
    @action setInformationListAction = (data) => {
        this.informationList = data;
    };

    /**
     * обращение через route к данным из json
     * @returns {Promise<any>}
     */
    get getInformation() {
        return new Promise((resolve, reject) => {
            return utils.sendRequest('GET', '/api/v2/fund-data/fund/all')
                .then(response => {
                    if (response.data.hasOwnProperty('error')) throw new Error(response.data.error);
                    resolve(response.data);
                })
                .catch(error => reject(error))
        })
    }

    createFundStructure = (data) => {
        return new Promise((resolve, reject) => {
            utils.sendRequest('POST', `/api/v2/fund-data/fund/create/admin/${data.payeeId}`, data, {Authorization: this.tokenAuth})
                .then(response => {
                    if (!response.data.isResult) throw new Error(response.data.message);
                    this.setFundInfoInListStore(response.data.message);
                    this.setNewTokenAuth(response.headers.authorization)
                    resolve({isResult: true});
                })
                .catch(error => {
                    reject(error)
                })
        })
    }
    // informationList END

    // photoList START
    saveNewPhotos = (data) => {
        const {url, newPhotos, payeeId, depNo} = data;
        const formData = new FormData();

        for (let k in newPhotos) {
            formData.append('file', newPhotos[k]); // appending file
        }

        let myHeaders = {
            'Accept': 'application/json, text/plain, */*',
            'Content-Type': 'multipart/form-data'
        }
        Object.assign(myHeaders, {Authorization: this.tokenAuth})

        return new Promise((resolve, reject) => {
            return utils.sendRequest('post', url, formData, myHeaders)
                .then(response => {
                    if (!response.data.isResult) throw new Error(response.data.message);
                    if (depNo) {
                        this.setNewProgramPhotosInStore({payeeId, depNo, photos: response.data.message})
                    } else {
                        this.setNewFundPhotosInStore({payeeId, photos: response.data.message})
                    }
                    this.setNewTokenAuth(response.headers.authorization)
                    resolve();
                })
                .catch(error => {
                    reject(error)
                })
        })


    }

    getPhotos(url) {
        return new Promise((resolve, reject) => {
            return utils.sendRequest('GET', url)
                .then(response => {
                    if (response.data.hasOwnProperty('error')) throw new Error(response.data.error);
                    else resolve(response.data)
                })
                .catch(error => reject(error))
        })
    }

    @action setNewFundPhotosInStore = data => {
        const {payeeId, photos} = data;
        let newPhotos = {};

        if (this.photoList.hasOwnProperty(payeeId)) {
            newPhotos = Object.assign(newPhotos, this.photoList[payeeId], {photos: photos})
        } else {
            newPhotos = {photos: photos}
        }

        this.photoList = Object.assign({}, this.photoList, {[payeeId]: newPhotos})
    }

    //{[payeeId]: {photos: [], [depNo]: []}}
    @action setNewProgramPhotosInStore = data => {
        const {payeeId, depNo, photos} = data;
        let newPhotos = {};

        if (this.photoList.hasOwnProperty(payeeId) && this.photoList[payeeId].hasOwnProperty(depNo)) {
            newPhotos = Object.assign(newPhotos, this.photoList[payeeId], {[depNo]: photos})
        } else {
            newPhotos = {[depNo]: photos}
        }

        this.photoList = Object.assign({}, this.photoList, {[payeeId]: newPhotos})
    }

    getProgramPhotos = (data) => {
        const {payeeId, depNo} = data;
        return new Promise((resolve, reject) => {
            if (this.photoList.hasOwnProperty(payeeId) && this.photoList[payeeId].hasOwnProperty(depNo)) {
                resolve(this.photoList[payeeId][depNo])
            } else {
                return this.getPhotos(`/api/v2/fund-data/photos/project/${payeeId}/${depNo}`)
                    .then(photos => {
                        this.setNewProgramPhotosInStore({...data, photos: photos})
                        resolve([...photos]);
                    })
                    .catch(error => reject(error))
            }
        })
    }

    getFundPhotos = payeeId => {
        return new Promise((resolve, reject) => {
            if (this.photoList.hasOwnProperty(payeeId) && this.photoList[payeeId].hasOwnProperty('photos')) {
                resolve(this.photoList[payeeId].photos)
            } else {
                return this.getPhotos(`/api/v2/fund-data/photos/fund/${payeeId}`)
                    .then(photos => {
                        this.setNewFundPhotosInStore({payeeId: payeeId, photos: photos});
                        resolve(photos);
                    })
                    .catch(error => reject(error))
            }
        })
    }

    deletePhotos = (data) => {
        const {payeeId, depNo} = data;
        const url = depNo ? `/api/v2/fund-data/photos/project/admin/${payeeId}/${depNo}` : `/api/v2/fund-data/photos/fund/admin/${payeeId}`
        return new Promise((resolve, reject) => {
            return utils.sendRequest('DELETE', url, data, {Authorization: this.tokenAuth})
                .then(response => {
                    if (!response.data.isResult) throw new Error(response.data.message)
                    else {
                        if (depNo) {
                            this.setNewProgramPhotosInStore({payeeId, depNo, photos: response.data.message})
                        } else {
                            this.setNewFundPhotosInStore({payeeId, photos: response.data.message})
                        }
                        this.setNewTokenAuth(response.headers.authorization)
                        resolve();
                    }
                })
                .catch(error => {
                    reject(error)
                })
        })
    }
    // photoList END

    @action setNewTokenAuth = token => {
        this.tokenAuth = token;
    }
}

export default FundInformationStore;