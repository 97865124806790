import React, {useRef} from 'react'
import {useDrag, useDrop} from 'react-dnd';
import {observer} from 'mobx-react';
import {urlGenerate} from '../../../actions';
import {
    authorisedAdminStore
} from '../../../stores';

const ProjectDND = observer(({project, index, moveListItem}) => {

    const {getAdmin} = authorisedAdminStore;
    const {depNo, title, photo} = project;
    const logo = urlGenerate.photoUrl(getAdmin.payeeId, depNo, photo);


    const [{isDragging}, dragRef] = useDrag({
        type: 'item',
        item: {index},
        collect: (monitor) => ({
            isDragging: monitor.isDragging(),
        }),
    })

    const [spec, dropRef] = useDrop({
        accept: 'item',
        hover: (item, monitor) => {
            const dragIndex = item.index
            const hoverIndex = index
            const hoverBoundingRect = ref.current?.getBoundingClientRect()
            const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2
            const hoverActualY = monitor.getClientOffset().y - hoverBoundingRect.top

            if (dragIndex < hoverIndex && hoverActualY < hoverMiddleY) return
            if (dragIndex > hoverIndex && hoverActualY > hoverMiddleY) return
            moveListItem(dragIndex, hoverIndex)
            item.index = hoverIndex
        }
    })

    const ref = useRef(null)
    const dragDropRef = dragRef(dropRef(ref))

    const opacity = isDragging ? 0 : 1


    return (<tr ref={dragDropRef} style={{opacity}}>
            <td>
                <div className="dragging"/>
            </td>
            <td>
                <img className="project-logo" src={logo}
                     alt="project"/></td>
            <td>
                <div className="project-title">
                    {title}
                </div>
            </td>
        </tr>
    )
})

export default ProjectDND;