import React from 'react';
import {Helmet} from 'react-helmet';
import {observer} from 'mobx-react';
import {urlGenerate} from '../../actions';
import {languageStore, globalSettingsStore, dictionariesStore, fundInformationStore} from '../../stores';

const HeadTags = observer(props => {
    let payeeId = props.data ? props.data.payeeId : undefined;
    let depNo = props.data ? props.data.depNo : undefined;
    let isAdmin = props.data ? props.data.isAdmin : false;
    let isAuth = props.data ? props.data.isAuth : false;

    const {getLanguageUsed} = languageStore;
    const {getGlobalSettings} = globalSettingsStore;
    const {getLexemes} = dictionariesStore;

    const {TITLE_SITE, ADMIN_TITLE_SITE, AUTH_TITLE_SITE, DESCRIPTION_SITE, KEYWORDS} = getLexemes[getLanguageUsed].about_service;
    const {current_host} = getGlobalSettings;

    let title = (isAdmin && ADMIN_TITLE_SITE) || (isAuth && AUTH_TITLE_SITE) || TITLE_SITE;
    let description = DESCRIPTION_SITE;
    let url = current_host;
    let image = current_host + urlGenerate.photoUrl('', '', 'logoMobiCashDB_red.svg');
    let siteName = current_host.includes('https') ? current_host.substring(8) : current_host.substring(7);
    let type = 'website';

    const {getFundInformation} = fundInformationStore;
    const fundInformation  = getFundInformation(payeeId, getLanguageUsed);

    if (fundInformation) {
        if (payeeId && depNo) {
            const program = fundInformation.projects.find(program => depNo === program.depNo);
            if (program) {
                const {titleProg, descriptionProg} = program.information;
                title = titleProg;
                description = descriptionProg.substr(0, 100) + '...';
                url = urlGenerate.pageUrl(payeeId, depNo, current_host);
            }

        } else if (payeeId) {
            const {titleFund, descriptionFund} = fundInformation.information;
            title = titleFund;
            description = descriptionFund.substr(0, 100) + '...';
            url = urlGenerate.pageUrl(payeeId, '', current_host);
        }
    }

    return <Helmet>
        <title>{title}</title>

        <meta name="keywords" content={KEYWORDS}/>
        <meta name="description" content={description}/>

        <meta property="og:url" content={url}/>
        <meta property="og:site_name" content={siteName}/>
        <meta property="og:title" content={title}/>
        <meta property="og:description" content={description}/>
        <meta property="og:type" content={type}/>
        <meta property="og:image" content={image}/>

        <meta property="twitter:image" content={image}/>
        <meta property="twitter:title" content={title}/>
        <meta property="twitter:description" content={description}/>
    </Helmet>

});

export default HeadTags;