import React, {useEffect, useRef, useState} from 'react';
import {observer} from 'mobx-react';
import {dictionariesStore, fundInformationStore, languageStore, readPayeeStore} from '../../../../../stores';

const Requisites = observer(props => {
    const {payeeId} = props.match.params;
    const _isMounted = useRef(true);
    const {getLanguageUsed} = languageStore;
    const [stateRequisites, setStateRequisites] = useState({})

    useEffect(() => {
        return () => {
            _isMounted.current = false;
        }
    }, []);

    useEffect(() => {
        const {getFundInformation} = fundInformationStore;
        const {getFundReadPayee} = readPayeeStore;
        const requisitesFund = {};
        const store = getFundInformation(payeeId, getLanguageUsed);

        if (store.hasOwnProperty('requisites')) {
            const checkRequisites = rqsts => {
                const obj = {};

                for (const [key, value] of Object.entries(rqsts)) {
                    if (value && value.trim() !== '') {
                        Object.assign(obj, {[key]: value})
                    }
                }
                return obj;
            }

            Object.assign(requisitesFund, checkRequisites(store.requisites));
            setStateRequisites(requisitesFund)

        }

        getFundReadPayee(payeeId, getLanguageUsed)
            .then((response) => {
                const {TinId, KPP, BankAccounts} = response.information;

                if (TinId && Object.keys(TinId).length > 0) {
                    Object.assign(requisitesFund, {TinId: TinId._text})
                } else if (stateRequisites.hasOwnProperty('inn')) {
                    Object.assign(requisitesFund, {TinId: stateRequisites.inn})
                }

                if (KPP && Object.keys(KPP).length > 0) {
                    Object.assign(requisitesFund, {KPP: KPP._text})
                }

                if (BankAccounts && BankAccounts.hasOwnProperty('BankAccount')) {
                    let bank = {};
                    const {BankAccount} = BankAccounts
                    Object.keys(BankAccount).forEach(element => {
                        Object.assign(bank, {[element]: BankAccount[element]._text})
                    })

                    Object.assign(requisitesFund, {bank})
                } else if (BankAccounts && BankAccounts.length > 0) {

                    let bank = {};
                    const {BankAccount} = BankAccounts[0]
                    Object.keys(BankAccount).forEach(element => {
                        Object.assign(bank, {[element]: BankAccount[element]._text})
                    })

                    Object.assign(requisitesFund, {bank})

                    setStateRequisites(requisitesFund)
                }
            })

    }, [getLanguageUsed])

    const {getLexemes} = dictionariesStore;
    const {REQUISITES, OGRN, TIN_ID, BANK_ACCOUNT, KPP, OKVED, LEGAL_ADDRESS, ACTUAL_ADDRESS, DATE_REGISTRATION, STATE_REGISTRATION_AUTH, REQUISITES_NOT_FOUND} = getLexemes[getLanguageUsed].fund_page;

    return <div className="requisites-fund">
        {Object.keys(stateRequisites).length > 0 ?
            <React.Fragment>
                <div className="requisites-block">
                    <div className="requisites-fund-title">{REQUISITES}</div>
                    {stateRequisites.hasOwnProperty('TinId') &&
                    <div className="requisite">{TIN_ID} {stateRequisites.TinId}</div>}
                    {stateRequisites.hasOwnProperty('KPP') &&
                    <div className="requisite">{KPP} {stateRequisites.KPP}</div>}
                    {stateRequisites.hasOwnProperty('okved') &&
                    <div className="requisite">{OKVED} {stateRequisites.okved}</div>}
                    {stateRequisites.hasOwnProperty('ogrn') &&
                    <div className="requisite">{OGRN} {stateRequisites.ogrn}</div>}
                </div>
                <div className="requisites-block">
                    {stateRequisites.hasOwnProperty('state_registration_auth') && <div
                        className="requisite">{STATE_REGISTRATION_AUTH} {stateRequisites.state_registration_auth}</div>}
                    {stateRequisites.hasOwnProperty('date_state_registration') &&
                    <div className="requisite">{DATE_REGISTRATION} {stateRequisites.date_state_registration}</div>}
                    {stateRequisites.hasOwnProperty('legal_address') &&
                    <div className="requisite">{LEGAL_ADDRESS} {stateRequisites.legal_address}</div>}
                    {stateRequisites.hasOwnProperty('actual_address') &&
                    <div className="requisite">{ACTUAL_ADDRESS} {stateRequisites.actual_address}</div>}
                </div>
                {stateRequisites.hasOwnProperty('bank') && <div className="requisites-block">
                    <div className="requisites-fund-title">{BANK_ACCOUNT.title}</div>
                    {Object.keys(stateRequisites.bank).map((item, index) => {
                        return <div className="requisite" key={`bank-account-${index}`}>{BANK_ACCOUNT[item]} {stateRequisites.bank[item]}</div>
                    })}
                </div>}
            </React.Fragment>
            :
            <div>{REQUISITES_NOT_FOUND}</div>
        }
    </div>
});

export default Requisites;