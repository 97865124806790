import React, {useEffect, useState} from 'react';
import {observer} from 'mobx-react';
import {Link} from 'react-router-dom';
import DocumentLinks from '../../common/DocumentLinks';
import {languageStore, dictionariesStore, fundInformationStore} from '../../../stores';

const MenuLinks = observer(({payeeId, titleHeader}) => {
    const [activeLink, setActiveLink] = useState(titleHeader);
    useEffect(() => {
        setActiveLink(titleHeader);
    }, [titleHeader])

    let classLink = '';
    const {getFundInformation} = fundInformationStore;
    if (!getFundInformation(payeeId)) {
        classLink = 'no-link'
    }

    const {getLanguageUsed} = languageStore;
    const {getLexemes} = dictionariesStore;
    const {ABOUT, NEWS, REPORTS, PROJECTS, DOCUMENTS, BANNER} = getLexemes[getLanguageUsed].admin;

    return (<div className="admin-menu">
        <div className="links-account-container">
            <div className="panel-menu">
                <Link to={`/admin/${payeeId}/about`} className={classLink}><div
                    className={activeLink === 'about' ? 'link link-active' : 'link'}>{ABOUT}</div></Link>
                <Link to={`/admin/${payeeId}/news`} className={classLink}><div
                    className={activeLink === 'news' ? 'link link-active' : 'link'}>{NEWS}</div></Link>
                <Link to={`/admin/${payeeId}/reports/edit`} className={classLink}><div
                    className={activeLink === 'reports' ? 'link link-active' : 'link'}>{REPORTS}</div></Link>
                <Link to={`/admin/${payeeId}/projects`} className={classLink}><div
                    className={activeLink === 'projects' ? 'link link-active' : 'link'}>{PROJECTS}</div></Link>
                <Link to={`/admin/${payeeId}/documents/edit`} className={classLink}><div
                    className={activeLink === 'documents' ? 'link link-active' : 'link'}>{DOCUMENTS}</div></Link>
                <Link to={`/admin/${payeeId}/banner`} className={classLink}><div
                    className={activeLink === 'banner' ? 'link link-active' : 'link'}>{BANNER}</div></Link>
            </div>
        </div>

        <div className="lang-document-container">
            <DocumentLinks orientation="vertical"/>
            <p>{getLexemes[getLanguageUsed].footer.RIGHTS_RESERVED}</p>
        </div>
    </div>)
});

export default MenuLinks;