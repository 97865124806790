import React, {useRef} from 'react';
import {notificationStore} from '../../../stores';
import bannerComponents from './bannerComponents.json';
import {stringActions} from '../../../actions';

export default function BannerScript(props) {
    const {BUTTON_COPY, MESSAGE_SHARE, SUCCESSFUL_COPY} = props.lexemes;

    const {current_host, expDate} = props.data;

    const dataObj = {};
    Object.assign(dataObj, props.lexemes);
    Object.assign(dataObj, props.data);

    dataObj.pathImageBanner = current_host + dataObj.pathImageBanner;
    dataObj.pathLogoService = current_host + dataObj.pathLogoService;

    let keysObject = Object.keys(props.data);
    keysObject.push(...Object.keys(props.lexemes));

    let bannerJson = bannerComponents ? bannerComponents : {};
    const {style, script_function, content_banner_data, content_banner_not_data} = bannerJson.banner;

    let scriptBanner = stringActions.setVariablesToString(script_function, keysObject, dataObj);
    let contentBanner = expDate === '' ? stringActions.setVariablesToString(content_banner_not_data, keysObject, dataObj) : stringActions.setVariablesToString(content_banner_data, keysObject, dataObj);

    const htmlRef = useRef(null);
    const onCopyScriptBannerToClipboard = () => {
        let script = htmlRef.current.textContent;
        navigator.clipboard.writeText(script)
            .then(() => {
                const {setNotificationObject} = notificationStore;
                setNotificationObject({
                    type: 'success',
                    message: SUCCESSFUL_COPY,
                    activityButton: {
                        message: 'Ok',
                        isCloseNotification: true
                    }
                });
            })
    };

    return <div className="banner-script-container">
        <div className="bs-title">
            {MESSAGE_SHARE}
        </div>
        <div className="bs-script">
            <div className="script-container" ref={htmlRef}>
                {style + scriptBanner + contentBanner}
            </div>
        </div>
        <div className="bs-button-copy">
            <button onClick={onCopyScriptBannerToClipboard}>{BUTTON_COPY}</button>
        </div>
    </div>
}

