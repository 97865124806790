import React from 'react';
import {observer} from 'mobx-react';
import Select from 'react-select';
import {languageStore, dictionariesStore} from '../../stores';

const SelectCategories = observer(props => {
    const {categoriesFromBlocks, setSelectedCategories, selectedCategories} = props.data;

    const {getLanguageUsed} = languageStore;
    const {getCategoriesFormatted, getLexemes} = dictionariesStore;
    const {SORT_BY, ALL} = getLexemes[getLanguageUsed].sort_categories;

    let categoriesRender = [];
    if (categoriesFromBlocks.length > 0) {
        categoriesRender = getCategoriesFormatted(getLanguageUsed)
            .reduce((returnArray, category, index, array) => {

                if (categoriesFromBlocks.includes(category.value)) {
                    returnArray.push(category);

                    let parentCategoryId = (category.value.slice(0, 1) + '0');
                    let parentCategoryInReturnArray = returnArray.find(item => {
                        return item.value === parentCategoryId
                    });

                    if (!parentCategoryInReturnArray) {
                        let parent = array.find(cat => {
                            return cat.value === parentCategoryId
                        });
                        returnArray.push(parent)
                    }

                }
                return returnArray;
            }, [])
            .sort((a, b) => {
                return parseInt(a.value) % 100 - parseInt(b.value) % 100
            });
    }

    return (categoriesFromBlocks.length > 0 &&
        <div className="sort-container">
            <div className="sort-title">
                {SORT_BY}
            </div>
            <div className="sort">
                <div className="select-categories">
                    <Select options={categoriesRender} value={selectedCategories} isMulti={true} placeholder={ALL} onChange={(event) => {
                        const newCategories = event ? event : [];
                        setSelectedCategories({selectedCategories: newCategories})
                    }}/>
                </div>
            </div>
        </div>
    )
});


export default SelectCategories;