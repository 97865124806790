import React from 'react'
import ProjectInfo from './ProjectInfo';

export default function ProjectList(props) {
    const {blockInformationArr} = props.data;

    return <React.Fragment>
        {blockInformationArr.length > 0 &&
        blockInformationArr.map(block => {
            return <ProjectInfo key={`${block.payeeId}-${block.depNo}`} data={block}/>
        })}
    </React.Fragment>

};

