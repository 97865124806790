import React, {useState} from 'react';
import {urlGenerate} from '../../../../actions';
import deleteBtn from '../../../../resources/images/delete.png';

export default function ImageNewsContainer(props) {
    const {img, payeeId, newsId, isLogo, isRender, setStateEditPhotos, setState, DO_COVER} = props.data;
    const [isRenderState, setIsRenderState] = useState(isRender);
    const [isShowBtnSetLogo, setIsShowBtnSetLogo] = useState(false)

    if (isRender !== isRenderState) {
        setIsRenderState(isRender);
    }

    return (isRenderState &&
        <div className={isLogo ? "image-container-logo image-container" : "image-container"} key={img}
             onMouseEnter={() => setIsShowBtnSetLogo(true)}
             onMouseLeave={() => setIsShowBtnSetLogo(false)}>
            <div className="blur-container"/>
            <div className="image-position">
                <img className="image" src={urlGenerate.newsImgUrl(payeeId, newsId, img)} alt="item"/>
            </div>
            {(isShowBtnSetLogo && !isLogo) && (
                <div className="btn-delete-position">
                    <div className="btn-delete-img"
                         onClick={(e) => setToDeleteList(img, setStateEditPhotos, setIsRenderState)}>
                        <img src={deleteBtn} alt="delete button"/>
                    </div>
                </div>
            )}
            {(isShowBtnSetLogo && !isLogo) && (
                <div className="btn-set-logo-position">
                    <div className="btn-set-logo" onClick={() => {
                        setState({logo: img});
                    }}>
                        {DO_COVER}
                    </div>
                </div>
            )}
        </div>)
}

function setToDeleteList(img, setStateEditPhotos, setIsRenderState) {
    setIsRenderState(false);
    setStateEditPhotos({delete: [img]})
}
