import React from 'react';

export default function BannerDemo(props) {
    const {title, description, pathImageBanner, expDate, total, pathLogoService, urlStory} = props.data;
    const {RAISED_FUNDS, LEARN_MORE, POWERED_BY, EXP_DATE} = props.lexemes;

    return <div className="banner-demo-container">
        <div className="banner-demo">
            <div className="banner-card">
                <div className="bd-blur" style={{background: `url(${pathImageBanner}) 50%/ cover border-box padding-box`}}/>
                <div className="bd-image">
                    <img src={pathImageBanner} alt="card"/>
                </div>
                <div className="bd-logo">
                    <img src={pathLogoService} alt="logo_of_service"/>
                </div>
            </div>

            <div className="banner-info">
                <div className="bd-title">
                    {title}
                </div>
                <div className="bd-description">
                    {description}
                </div>
                {expDate !== '' && <div className="bd-exp-date">
                    {EXP_DATE + expDate}
                </div>}
                <div className="bd-raised-funds">
                    <div className="raised-lexemes">
                        {RAISED_FUNDS}
                    </div>
                    <div className="raised-total">
                        <span>{total}</span>
                    </div>
                </div>
                <div className="bd-button">
                    <a href={urlStory} target="_blank">{LEARN_MORE}</a>
                </div>
            </div>

            <div className="banner-powered">
                {POWERED_BY}
            </div>
        </div>

    </div>
}