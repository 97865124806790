import React, {useCallback} from 'react';
import {observer} from 'mobx-react';
import MemberDND from './MemberDND';
import {dictionariesStore, languageStore} from '../../../stores';

const DragNDropContainer = observer(({editTeamState, setEditTeamState}) => {
    const {getLanguageUsed, getSortedLanguageList} = languageStore;
    const {getLexemes} = dictionariesStore;
    const {admin_edit} = getLexemes[getLanguageUsed];
    const {FILE_NAME} = admin_edit;

    const moveListItem = useCallback(
        (dragIndex, hoverIndex) => {
            setEditTeamState({dnd: {dragIndex, hoverIndex}})
        },
        [editTeamState.edit]
    );

    return <React.Fragment>
        <div className="table-container">
            <table>
                <thead>
                {(editTeamState.edit.length > 0 || editTeamState.add.length > 0) &&
                    <tr>
                        <th>&nbsp;</th>
                        <th>{FILE_NAME}</th>
                        {getSortedLanguageList.map(itemLang => {
                            return <th key={itemLang.lang}>{admin_edit[`SIGNATURE_${itemLang.lang.toUpperCase()}`]}</th>
                        })}
                        <th>&nbsp;</th>
                    </tr>}
                </thead>
                <tbody>
                {editTeamState.edit.map((member, index) => {
                    return <MemberDND key={member.memberId} index={index} member={member} moveListItem={moveListItem} setEditTeamState={setEditTeamState} editTeamState={editTeamState}/>
                })}
                </tbody>
            </table>
        </div>
    </React.Fragment>
});

export default DragNDropContainer;