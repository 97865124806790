import React from 'react';
import {urlGenerate} from '../../actions';

export default function LogoCircle({logo, payeeId}) {
    const url = logo !== '' ? urlGenerate.photoUrl(payeeId, '', logo) : urlGenerate.photoUrl('', '', 'empty_logo.svg');
    return <div className="info-fund">
        <div className="logo-circle-container">
            <div className="image-position">
                <img className="logo-circle" src={url}/>
            </div>
        </div>
    </div>
}