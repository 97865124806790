import React from 'react';
import {observer} from 'mobx-react';
import {dictionariesStore, languageStore} from '../../../stores';

const PageHeader = observer(({titleHeader}) => {
    const {getLanguageUsed} = languageStore;
    const {getLexemes} = dictionariesStore;
    let lexemes = getLexemes[getLanguageUsed];

    return (<div className="admin-page-header">
        <div className="page-name">
            {lexemes.admin[titleHeader.toUpperCase()]}
        </div>
    </div>)
});

export default PageHeader;