import React, {useEffect, useState} from 'react';
import {Link, Route, Switch, useLocation} from 'react-router-dom';
import AboutFundComponent from './AboutFund';
import DocumentsFundComponents from './Documents';
import SortContainer from './Projects';
import FundNewsListComponent from './News';
import OneNews from './News/OneNews';
import ReportsFundComponents from './Reports';
import HeadTags from '../../common/HeadTags';
import ProjectPage from '../projectPage/ProjectPage';
import FundComponentLinks from './Menu/FundComponentLinks';
import ProjectComponentLinks from '../projectPage/ProjectComponentLinks';
import InfoBlockFund from '../../common/InfoBlockFund';
import {languageStore, fundInformationStore, dictionariesStore} from '../../../stores';
import returnImg from '../../../resources/images/prev.png';
import ErrorPage from '../../common/ErrorPage';
import NewsComponentLinks from './Menu/NewsComponentLinks';
import MobileMenu from './Menu/MobileMenu';

export default function FundPage(props) {
    const location = useLocation();
    const [activeComponent, setActiveComponent] = useState(getActiveComponent(location));
    const {payeeId} = props.match.params;
    useEffect(() => {
        setActiveComponent(getActiveComponent(location))
    }, [location])

    return (
        <React.Fragment>
            <HeadTags data={{payeeId: payeeId}}/>
            <MobileMenu payeeId={payeeId} activeComponent={activeComponent}/>
            <div className="user-container">
                <div className="fund-page-container">
                    <div className="headers-fund-container">
                        <img className="full-img" src={`/headers-fund/${payeeId}.png`}/>
                        <div className="mobile-img-container"><img className="mobile-img" src={`/headers-fund/${payeeId}_m.png`}/></div>

                        {activeComponent.component === 'project' && getLinkToFund(payeeId)}
                        {activeComponent.component === 'news' && getLinkToNewsList(payeeId)}
                        {activeComponent.component === 'fund' && getInfoBlockFundComponent(payeeId)}
                    </div>

                    {activeComponent.component === 'fund' &&
                        <FundComponentLinks
                        data={{
                            payeeId: payeeId,
                            activeLink: activeComponent.page
                        }}/>}

                    {activeComponent.component === 'project' &&
                    <ProjectComponentLinks activeComponent={activeComponent}/>
                    }

                    {activeComponent.component === 'news' &&
                    <NewsComponentLinks/>
                    }

                    {getMobileTitle(activeComponent)}

                    <div className="common-container">
                        <Switch>
                            <Route path="/fund/:payeeId/about" render={(props) => <AboutFundComponent {...props}
                                                                                                      activeComponent={activeComponent}/>}/>
                            <Route exact path="/fund/:payeeId/projects"
                                   render={(props) => <SortContainer {...props}/>}/>
                            <Route exact path="/fund/:payeeId/documents" component={DocumentsFundComponents}/>
                            <Route path="/fund/:payeeId/project/:depNo" component={ProjectPage}/>
                            <Route exact path="/fund/:payeeId/news-list" component={FundNewsListComponent}/>
                            <Route exact path="/fund/:payeeId/news/:newsId/about" component={OneNews}/>
                            <Route exact path="/fund/:payeeId/reports" component={ReportsFundComponents}/>
                            <Route><ErrorPage/></Route>
                        </Switch>
                    </div>
                </div>
            </div>
        </React.Fragment>
    )
}

function getActiveComponent(location) {
    let active = {
        component: '',
        page: '',
        tab: '',
        data: {}
    }
    const arr = location.pathname.split('/');
    const pageName = arr[3];
    if (['about', 'news-list', 'reports', 'projects', 'documents'].includes(pageName)) {
        active.component = 'fund';
        active.page = pageName;

        if (pageName === 'about') {
            active.tab = arr[4]
        }

    } else {
        active.component = pageName;
        active.page = 'about';

        if (active.component === 'project') {
            Object.assign(active.data, {payeeId: arr[2], depNo: arr[4]})
        }
    }

    return active;
}

function getInfoBlockFundComponent(payeeId) {
    const {getFundInformation} = fundInformationStore;
    const {getLanguageUsed} = languageStore;
    let fundInformation = getFundInformation(payeeId, getLanguageUsed);

    return <div className="fund-container">
        <InfoBlockFund data={fundInformation} mode="user"/>
    </div>
}

function getLinkToFund(payeeId) {
    const {getLanguageUsed} = languageStore;
    const {getLexemes} = dictionariesStore;
    const {RETURN_TO_PROJECT_LIST} = getLexemes[getLanguageUsed].fund_page;

    return <div className="return-to-fund-about">
        <div className="return-flex-container">
            <Link to={`/fund/${payeeId}/projects`} className="links">
                <img src={returnImg}/>
                <div className="link-return">{RETURN_TO_PROJECT_LIST}</div>
            </Link>
        </div>
    </div>
}

function getLinkToNewsList(payeeId) {
    const {getLanguageUsed} = languageStore;
    const {getLexemes} = dictionariesStore;
    const {RETURN_TO_NEWS_LIST} = getLexemes[getLanguageUsed].fund_page;

    return <div className="return-to-fund-about">
        <div className="return-flex-container">
            <Link to={`/fund/${payeeId}/news-list`} className="links">
                <img src={returnImg}/>
                <div className="link-return">{RETURN_TO_NEWS_LIST}</div>
            </Link>
        </div>
    </div>
}

function getMobileTitle(activeComponent) {
    const {getLanguageUsed} = languageStore;
    const {getLexemes} = dictionariesStore;
    const {page, tab, component} = activeComponent;
    const {fund_page} = getLexemes[getLanguageUsed];
    const pageLexeme = page === 'news-list' ? fund_page.NEWS : fund_page[page.toUpperCase()];
    let lexeme = ''
    if (component === 'fund') {
        if (!tab) {
            lexeme = `${pageLexeme}`
        } else {
            lexeme = `${pageLexeme} / ${fund_page[tab.toUpperCase()]}`
        }
    }

    return lexeme && <div className="mobile-title-page">
        {lexeme}
    </div>

}