import React from 'react';
import {Tab, Tabs, TabList, TabPanel} from 'react-tabs';
import {observer} from 'mobx-react';
import {languageStore, dictionariesStore} from '../../../stores';
import ProgramSettings from './ProgramSettings';
import FundSettings from './FundSettings';
import TextInformation from './TextInformation';
import GalleryContainer from './GalleryContainer';
import {stringActions} from '../../../actions';

const EditTemplate = observer(props => {
    const {data, setNewInformation, isProgram, editedPhotos} = props;
    const {payeeId, depNo, isInitial} = data;
    const {getLanguageUsed, getSortedLanguageList, getSortedLanguageListStr} = languageStore;
    const {getLexemes} = dictionariesStore;
    const {SETTINGS, GALLERY, RECOMMENDED_MSG} = getLexemes[getLanguageUsed].admin_edit;
    let logo = isProgram ? data.logoProg : data.logoFund;

    const _RECOMMENDED_MSG = stringActions.setVariablesToString(RECOMMENDED_MSG, ['languages'], {languages: getSortedLanguageListStr})

    return (<div className="edit-container">
        <Tabs>
            <TabList className="admin-edit-list">
                {getSortedLanguageList.map(lang => {
                    return (<Tab className="default-edit-tab" selectedClassName="selected-edit-tab" key={`tab-${lang.lang}`}>{lang.lexeme}</Tab>)
                })}
                <Tab className="default-edit-tab" selectedClassName="selected-edit-tab">{SETTINGS}</Tab>
                <Tab className="default-edit-tab" selectedClassName="selected-edit-tab">{GALLERY}</Tab>
            </TabList>
            {getSortedLanguageList.map(item => {
                const {lang} = item;
                return (<TabPanel key={`tab-panel-${lang}`}>
                    <><TextInformation data={dataGenerateTextInformation(data, lang, isProgram)} setNewInformation={setNewInformation} isInitial={isInitial}/>
                        <div className="recommended-container">
                            {_RECOMMENDED_MSG}
                        </div>
                    </>
                </TabPanel>)
            })}
            <TabPanel>
                {isProgram ?
                    <ProgramSettings data={data} setNewInformation={setNewInformation} isInitial={isInitial}/>
                    :
                    <FundSettings data={data} setNewInformation={setNewInformation } isInitial={isInitial}/>}
            </TabPanel>
            <TabPanel>
                <GalleryContainer data={{payeeId: payeeId, depNo: depNo, logo: logo, isProgram: isProgram}} editedPhotos={editedPhotos} setNewInformation={setNewInformation} isInitial={isInitial}/>
            </TabPanel>
        </Tabs>
    </div>)
});

export default EditTemplate;

function dataGenerateTextInformation(data, lang, isProgram) {
    let title = '';
    let description = '';

    const getText = (obj, key) => {
        if (obj.hasOwnProperty(key)){
            return obj[key]
        } else {
            return ''
        }
    }

    if (data.hasOwnProperty('information')) {
        const information = data.information[lang];
        if (information) {
            title = isProgram ? getText(information, 'titleProg') : getText(information, 'titleFund');
            description = isProgram ? getText(information, 'descriptionProg') : getText(information, 'descriptionFund');
        }
    }

    return {title, description, lang, isProgram};
}