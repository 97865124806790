import React, {useEffect, useReducer, useRef, useState} from 'react';
import {observer} from 'mobx-react';
import SelectCategories from '../../../common/SelectCategories';
import ProjectList from './ProjectList';
import {languageStore, fundInformationStore, dictionariesStore} from '../../../../stores';
import {getData, utils} from '../../../../actions';
import PaginationWidget from '../../../common/PaginationWidget';
import InfoBlock from "../../mainPage/InfoBlock";
//todo initialization state in useEffect
const SortContainer = observer(props => {
    const {payeeId} = props.match.params;
    const {getLanguageUsed} = languageStore;
    const {getLexemes} = dictionariesStore;
    const {NON_PROJECTS} = getLexemes[getLanguageUsed].fund_page;
    const {getFundInformation} = fundInformationStore;
    const store = getFundInformation(payeeId, getLanguageUsed);

    let blockInformationArr = generateBlockInformationArr(store);

    // let categoriesFromBlocks = generateCategoriesArr(blockInformationArr);
    // const [renderState, setRenderState] = useReducer(utils.reducerRenderSortContainer, {selectedCategories: [], categoriesFromBlocks: categoriesFromBlocks, lang: getLanguageUsed, renderBlocks: blockInformationArr, blockInformationArr: blockInformationArr})

    // useEffect(() => {
    //     if (renderState.hasOwnProperty('selectedCategories') && renderState.selectedCategories.length > 0 && renderState.lang !== getLanguageUsed) {
    //         const {getCategoriesFormatted} = dictionariesStore;
    //
    //         const newCategories = renderState.selectedCategories.map(item => {
    //             const {value} = item;
    //             return getCategoriesFormatted(getLanguageUsed).find(cat => {return cat.value === value})
    //         });
    //
    //         setRenderState({lang: getLanguageUsed, selectedCategories: newCategories})
    //     } else if (renderState.lang !== getLanguageUsed) {
    //         const storeUpd = getFundInformation(payeeId, getLanguageUsed);
    //         let blockInformationArr = generateBlockInformationArr(storeUpd);
    //         setRenderState({lang: getLanguageUsed, blockInformationArr: blockInformationArr})
    //     }
    // }, [renderState, getLanguageUsed])

    // const infoBlockListRef = useRef(null);

    return (
        <React.Fragment>
            <div className="block-container-common-fund-page">
                {/*<div className="title-sort-container">*/}
                    {/*{store.map((fund, index) => {*/}
                    {/*    return <div className="block-fund" style={{width: 279}} key={`fund-${index}`}>*/}
                    {/*        <InfoBlock data={fund}/>*/}
                    {/*    </div>*/}
                    {/*})}*/}
                    {/*<SelectCategories*/}
                    {/*    data={{*/}
                    {/*        categoriesFromBlocks: renderState.categoriesFromBlocks,*/}
                    {/*        setSelectedCategories: setRenderState,*/}
                    {/*        selectedCategories: renderState.selectedCategories*/}
                    {/*    }}/>*/}
                {/*</div>*/}
                {/*ref={infoBlockListRef}*/}

                    {blockInformationArr.length > 0 ?
                        <div className="program-list">
                        <ProjectList
                            data={{blockInformationArr: blockInformationArr}}/> </div>
                        :
                        <div className="non-project">{NON_PROJECTS}</div>
                    }
            </div>

            {/*<PaginationWidget data={{ref: infoBlockListRef, blocks: renderState.renderBlocks, pageSize: 4}}/>*/}
        </React.Fragment>
    )
});

export default SortContainer;

function generateBlockInformationArr(store) {

    const activeProjects = getData.listProgramOnStatus(store, 'active');

    const blockInformationArr = getData.getActiveProjectsByPriority(activeProjects, store.projectPriorityList);

    if (store.settings.archProgram) {
        let archiveProgram = getData.listProgramOnStatus(store, 'archive');
        blockInformationArr.push(...archiveProgram)
    }

    return blockInformationArr;
}

// function generateCategoriesArr(blockInformationArr) {
//     return blockInformationArr
//         .reduce((arr, item) => {
//             if (arr.length > 0) {
//                 for (let category of item.categories) {
//                     if (!arr.includes(category)) {
//                         arr.push(category);
//                     }
//                 }
//             } else {
//                 arr.push(...item.categories)
//             }
//             return arr
//         }, []);
// }
