import React from 'react'
import {observer} from 'mobx-react';
import {Link} from 'react-router-dom';
import {dictionariesStore, languageStore} from '../../../stores';
import addIcon from '../../../resources/images/add-circle.png';

const AddNewsButton = observer(props => {
    const {payeeId} = props;
    const {getLanguageUsed} = languageStore;
    const {getLexemes} = dictionariesStore;
    const {ADD_NEWS} = getLexemes[getLanguageUsed].admin_news_list;

    return (<div className="add-new-news">
        <Link to={`/admin/${payeeId}/news/create`}>
            <img src={addIcon} alt="add icon"/>
            <div>{ADD_NEWS}</div>
        </Link>
    </div>)
});

export default AddNewsButton;