import React, {useState} from 'react';
import {useHistory} from 'react-router-dom';
import Logon from './Logon';
import PopupWidget from '../common/PopupWidget';
import HeadTags from '../common/HeadTags';

export default function LogonWidget(props) {
    const [popupIsOpened, setPopupIsOpened] = useState(true);
    let history = useHistory();

    if(!popupIsOpened) {
        history.push('/')
    }

    return <>
        <HeadTags data={{isAuth: true}}/><PopupWidget data={{isOpened: popupIsOpened, actionClose: setPopupIsOpened}}>
        <Logon/>
    </PopupWidget>
        </>
}