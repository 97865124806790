import React, {useReducer, useState} from 'react'
import {observer} from 'mobx-react';
import {useHistory} from 'react-router-dom';
import {
    languageStore,
    dictionariesStore,
    fundInformationStore,
    authorisedAdminStore,
    notificationStore
} from '../../stores';
import imgHidePassword from '../../resources/images/hidePswrd.png';
import imgShowPassword from '../../resources/images/showPswrd.png';
import {adminActions, stringActions, tokenActions, utils} from '../../actions';

const LogonByPhone = observer(props => {
    const {getLanguageUsed} = languageStore;
    const {getLexemes, getResponseCodes} = dictionariesStore;
    const {setNotificationObject} = notificationStore;

    const {LOGIN, PASSWORD, SING_IN} = getLexemes[getLanguageUsed].logon;

    const [showPassword, setShowPassword] = useState(false);
    const initialStateLogon = {login: '', hash: ''};
    const [stateLogon, dispatchLogon] = useReducer(adminActions.reducerDefault, {...initialStateLogon});
    let history = useHistory();

    return <>
        <form id="sing-in-form" onSubmit={(event) => {
            event.preventDefault();
            return authByLogin(stateLogon)
                .then(responseAuth => {
                    return tokenActions.getAuthToken(stateLogon)
                        .then(responseToken => {
                            const {payeeId} = responseAuth;
                            const urlAdmin = adminActions.getRedirectUrlOnAdminPage(payeeId, fundInformationStore);
                            history.push(urlAdmin);

                            const {setAdmin} = authorisedAdminStore;
                            setAdmin({payeeId, login: stateLogon.login, ...responseToken});
                        })
                        .catch(error => {
                            setNotificationObject({
                                type: 'warning',
                                message: getResponseCodes[getLanguageUsed][error.message],
                                activityButton: {
                                    message: 'Ok',
                                    isCloseNotification: true
                                }
                            });
                            dispatchLogon({...initialStateLogon});
                        })

                })
                .catch(error => {
                    console.error(error);
                    setNotificationObject({
                        type: 'warning',
                        message: getResponseCodes[getLanguageUsed][error.message],
                        activityButton: {
                            message: 'Ok',
                            isCloseNotification: true
                        }
                    });
                    dispatchLogon({...initialStateLogon});
                })
        }}>
            <div id="input-login" className="input-logon">
                <label htmlFor="login">{LOGIN}</label>
                <input id="login" type="text" className="login" required={true} maxLength="30" onChange={(event) => {
                    dispatchLogon({login: event.target.value})
                }} value={stateLogon.login}/>
            </div>
            <div className="input-logon">
                <label htmlFor="password">{PASSWORD}</label>
                <input id="password"
                       type={showPassword ? "text" : "password"} className="password"
                       value={stringActions.unmaskingPassword(stateLogon.hash)}
                       required={true} onChange={(event) => {
                    const hash = stringActions.maskingPassword(event.target.value)
                    dispatchLogon({hash: hash})
                }}/>
                <div className="show-icon"
                     onClick={() => showPassword ? setShowPassword(false) : setShowPassword(true)}>
                    <img src={showPassword ? imgShowPassword : imgHidePassword} alt="password is hide"/>
                </div>
            </div>

            <button className="btn-active" disabled={(stateLogon.login === '' && stateLogon.hash === '')}>
                {SING_IN}
            </button>
        </form>

    </>
});

export default LogonByPhone;

function authByLogin(authData) {
    return new Promise((resolve, reject) => {
        return utils.sendRequest('POST', '/api/v2/app/tx/CheckRegistration', {login: authData.login})
            .then(resCheckRegistration => {
                if (resCheckRegistration.data.hasOwnProperty('error')) throw new Error(resCheckRegistration.data.error);

                const data = resCheckRegistration.data.CheckRegistration;
                let status = data._attributes.Status;

                switch (status) {
                    case 'Exists': return authData;
                    case 'New':
                    case 'Blocked': throw new Error(`RESULT_REGISTRATION_${status.toUpperCase()}`);
                    default: throw new Error(`RESULT_REGISTRATION_ERROR`)
                }

            })
            .then(authData => {
                return utils.sendRequest('POST', '/api/v2/app/authorisation', {login: authData.login, hash: authData.hash})
                    .then(resReadEmployee => {
                        if (resReadEmployee.data.hasOwnProperty('error')) throw new Error(resReadEmployee.data.error);

                        resolve({result: true, payeeId: resReadEmployee.data.ReadEmployee._attributes.PayeeId});
                    })
                    .catch(error => reject(error))
            })
            .catch(error => reject(error))
    })
}

