import React, {useEffect, useRef, useState} from 'react';
import {observer} from 'mobx-react';
import {urlGenerate} from '../../../../actions';
import {languageStore, dictionariesStore, fundInformationStore} from '../../../../stores';

const DocumentsFundComponents = observer(props => {
    const _isMounted = useRef(true);
    const [documents, setDocuments] = useState([]);
    const {payeeId} = props.match.params;
    const {getLanguageUsed} = languageStore;
    const {getLexemes} = dictionariesStore;
    const {NOT_DOCUMENTS_FUND} = getLexemes[getLanguageUsed].fund_page;

    const {getDocumentsOnPayeeId} = fundInformationStore;

    useEffect(() => {
        return () => {
            _isMounted.current = false;
        }
    }, [])

    useEffect(() => {
        getDocumentsOnPayeeId(payeeId)
            .then(documents => {
                if (_isMounted.current) {
                    setDocuments(documents);
                }
            })

    }, [getLanguageUsed])


    const getDocumentWithLink = doc => {
        const {fileName, label} = doc;
        const docLink = urlGenerate.documentUrl(payeeId, fileName);
        const nameLink = label[getLanguageUsed] !== '' ? label[getLanguageUsed] : fileName

        return <div className="item" key={fileName}>
            <a href={docLink} target="_blank" rel="noopener noreferrer">{nameLink}</a>
        </div>
    }

    return <div className="documents-component-container">
        <div className="documents-container">
            <div className="content">
                {documents.length > 0 ?
                    documents.map(doc => {
                        return getDocumentWithLink(doc)
                    })
                :
                    NOT_DOCUMENTS_FUND}
            </div>
        </div>
    </div>
});

export default DocumentsFundComponents;