import React from 'react'
import {languageStore, dictionariesStore, globalSettingsStore} from '../../stores';

export default function DocumentLinks(props) {
    const {orientation} = props; //vertical, horizontal

    const {getLanguageUsed} = languageStore;
    const {getLexemes} = dictionariesStore;
    const {DOC_LINKS} = getLexemes[getLanguageUsed].footer
    const {getGlobalSettings} = globalSettingsStore;
    const {documents} = getGlobalSettings;
    const documentsLinksOnLang = documents[getLanguageUsed];

    return <div className="links-block">
        <div className={`link-${orientation}`}>
            {
                Object.keys(DOC_LINKS).map((doc, index) => (
                    <React.Fragment key={index}>
                        <div className="link">
                            <a href={documentsLinksOnLang[doc]} target="_blank" rel="noopener noreferrer">{DOC_LINKS[doc]}</a>
                        </div>
                        {orientation === "horizontal" && index !== (Object.keys(DOC_LINKS).length - 1) && <span>|</span>}
                    </React.Fragment>
                ))
            }
        </div>

    </div>

}