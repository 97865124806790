import React from 'react';
import {stringActions, urlGenerate} from '../../../actions';
import {Link} from 'react-router-dom';
import Logo from '../../common/Logo';

export default function NewsInfo({payeeId, data, lang, months}) {
    const {newsId, logo, date, text} = data;
    let _description = stringActions.substringText(text.description, 150);
    let _logo = urlGenerate.newsImgUrl(payeeId, newsId, logo);

    return (<div className="admin-news-container">
        <Link to={`/admin/${payeeId}/news/${newsId}/edit`}>
            <div className="admin-news-info">
                <div className="admin-news-photo">
                    <Logo url={_logo}/>
                </div>
                <div className="admin-news-date">
                    {stringActions.getTextDate(lang, months, date)}
                    <div className="admin-news-title">
                        {text.title}
                    </div>
                    <div className="admin-news-description">
                        {_description}
                    </div>
                </div>
            </div>
        </Link>
    </div>)
}