import React from 'react';
import {Route, Switch} from 'react-router-dom';
import AboutFundPage from '../AboutFundPage';
import ProgramListPage from '../ProgramListPage';
import FundBannerPage from '../FundBannerPage';
import ProgramEditPage from '../ProgramEditPage';
import FundEditPage from '../FundEditPage';
import ErrorPage from '../../common/ErrorPage';
import NewsListPage from '../NewsListPage';
import NewsEditPage from '../NewsEditPage';
import TeamEditPage from '../TeamEditPage';
import DocumentsEditPage from '../DocumentsEditPage';
import ReportsEditPage from '../ReportsEditPage';
import HowGetHelpEditPage from '../HowGetHelpEditPage';
import ProjectsPriorityEditPage from '../ProjectsPriorityEditPage';

export default function AdminRouter() {

    return (
        <Switch>
            <Route exact path="/admin/:payeeId/about" component={AboutFundPage}/>
            <Route path="/admin/:payeeId/projects" component={ProgramListPage}/>
            <Route path="/admin/:payeeId/documents/edit" component={DocumentsEditPage}/>
            <Route path="/admin/:payeeId/reports/edit" component={ReportsEditPage}/>
            <Route path="/admin/:payeeId/banner" component={FundBannerPage}/>
            <Route exact path="/admin/:payeeId/about/info/edit" component={FundEditPage}/>
            <Route path="/admin/:payeeId/about/team/edit" component={TeamEditPage}/>
            <Route path="/admin/:payeeId/about/help/edit" component={HowGetHelpEditPage}/>
            <Route exact path="/admin/:payeeId/project/priority/edit" component={ProjectsPriorityEditPage}/>
            <Route path="/admin/:payeeId/project/:depNo/edit" component={ProgramEditPage}/>
            <Route path="/admin/:payeeId/project/create" component={ProgramEditPage}/>
            <Route exact path="/admin/:payeeId/news" component={NewsListPage}/>
            <Route path="/admin/:payeeId/news/:newsId/edit" component={NewsEditPage}/>
            <Route path="/admin/:payeeId/news/create" component={NewsEditPage}/>
            <Route><ErrorPage/></Route>
        </Switch>)
}
