import React from 'react';
import {observer} from 'mobx-react';
import {dictionariesStore, languageStore} from '../../../stores';
import {stringActions} from '../../../actions';

const BlockNewsInformation = observer(props => {
    const {data, setNewInformation, lang} = props;
    const {title, description, date} = data;
    const {getLanguageUsed} = languageStore;
    const {getLexemes} = dictionariesStore;
    const {months} = getLexemes[getLanguageUsed];
    const {TITLE_OF_NEWS, DESCRIPTION_OF_NEWS, DATE_OF_NEWS} = getLexemes[getLanguageUsed].admin_edit;

    return (<div className="block-news-information-container">
        <div className="title-container">
            <label htmlFor={`title-${lang}`}>
                {TITLE_OF_NEWS}
            </label>
            <input type="text" required={true} id={`title-${lang}`}
                   value={title} onChange={(event) => {
                setNewInformation({text: {[lang]: {title: event.target.value}}})
            }}
            />
        </div>
        <div className="description-container">
            <label htmlFor={`description-${lang}`}>
                {DESCRIPTION_OF_NEWS}
            </label>
            <textarea required={true} id={`description-${lang}`}
                      value={description} onChange={(event) => {
                setNewInformation({text: {[lang]: {description: event.target.value}}})
            }}
            />
        </div>
        <div className="date-container">
            {DATE_OF_NEWS}: {stringActions.getTextDate(getLanguageUsed, months, date)}
        </div>
    </div>)
});

export default BlockNewsInformation;
