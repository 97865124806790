import React from 'react';
import {observer} from 'mobx-react';

const PopupWidget = observer(props => {
    const {isOpened, actionClose} = props.data;

    return (
        isOpened && (
            <div className="popup-widget">
                <div className="popup-container">
                    <div className="popup-flex-container">
                        <div className="popup-header">
                            <div className="popup-cancel" onClick={() => actionClose(false)}/>
                        </div>
                        <div className="popup-content">
                            {props.children}
                        </div>
                    </div>
                </div>
            </div>
        )
    )
});

export default PopupWidget;
