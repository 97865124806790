import React from 'react';
import {observer} from 'mobx-react';
import {languageStore, dictionariesStore} from '../../../../stores';

const NewsComponentLinks = observer(props => {
    const {getLanguageUsed} = languageStore;
    const {getLexemes} = dictionariesStore;
    const {ONE_NEWS} = getLexemes[getLanguageUsed].fund_page;

    return <>
        <div className="news-links">
            <div className="link link-active">{ONE_NEWS.toUpperCase()}</div>
        </div>
    </>
})

export default NewsComponentLinks;