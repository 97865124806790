import React, {useEffect, useState} from 'react';
import {observer} from 'mobx-react';
import {languageStore, dictionariesStore} from '../../../../stores';
import {Link} from 'react-router-dom';

const AboutFundLinks = observer(({payeeId, activeComponent}) => {
    const [activeLink, setActiveLink] = useState(activeComponent.tab);

    useEffect(() => {
        setActiveLink(activeComponent.tab);
    }, [activeComponent.tab])

    const {getLanguageUsed} = languageStore;
    const {getLexemes} = dictionariesStore;
    const {fund_page} = getLexemes[getLanguageUsed];
    const pathRules = ['info', 'team', 'requisites', 'help'];

    return <div className="about-fund-links">
            {pathRules.map(path => {
                return <Link key={path} to={`/fund/${payeeId}/about/${path}`} className="about-link">
                    <div className={activeLink === path ? "about-link-active" : ""}>{fund_page[path.toUpperCase()]}</div>
                </Link>
            })}

    </div>
});

export default AboutFundLinks;