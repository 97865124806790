import React from 'react';
import {observer} from 'mobx-react';
import DatePicker, {registerLocale} from 'react-datepicker';
import moment from 'moment';
import Select from 'react-select';
import {languageStore, dictionariesStore} from '../../../stores';
import {enGB, ru} from 'date-fns/locale';
import {getData} from '../../../actions';

registerLocale('en', enGB);
registerLocale('ru', ru);

const ProgramSettings = observer(props => {
    const {data, setNewInformation} = props;
    const {sum, startDateProg, expDate, status, categoriesProg} = data.settings;
    const {getLanguageUsed} = languageStore;
    const {getCategoriesFormatted, getLexemes} = dictionariesStore;

    const {START_DATE_FUNDRAISING, END_DATE_FUNDRAISING, TARGET_AMOUNT, PROGRAM_STATUS, statuses, CATEGORIES} = getLexemes[getLanguageUsed].admin_edit;

    const {ALL} = getLexemes[getLanguageUsed].sort_categories;

    const allCategories = getCategoriesFormatted(getLanguageUsed);

    return (<div className="program-settings-container">
        <div className="dates-status-container">
            <div className="all-dates-container">
                <div className="start-date">
                    <label htmlFor="start-date">{START_DATE_FUNDRAISING}</label>
                    <DatePicker locale={getLanguageUsed} value={new Date(startDateProg)}
                                selected={new Date(startDateProg)} id="start-date"
                                onChange={(date) => {
                                    setNewInformation({settings: {startDateProg: moment(date).format('YYYY-MM-DD')}})
                                }}/>
                </div>
                <div className="exp-date">
                    <label htmlFor="exp-date">{END_DATE_FUNDRAISING}</label>
                    <DatePicker locale={getLanguageUsed} value={new Date(expDate)} selected={new Date(expDate)}
                                id="exp-date"
                                onChange={(date) => {
                                    setNewInformation({settings: {expDate: moment(date).format('YYYY-MM-DD')}})
                                }}/>
                </div>
            </div>
            <div className="sum-status-container">
                <div className="target-sum">
                    <label htmlFor="target-sum">{TARGET_AMOUNT}</label>
                    <input inputMode="decimal" id="target-sum" required={true} value={sum} onChange={(event) => {
                        let amount = event.target.value;
                        if (/^-?[0-9]+(?:[\., \,])?(?:[0-9]{1,2})?$/.test(amount) || amount === 0 || amount === "") {
                            setNewInformation({settings: {sum: amount}})
                        }
                    }
                    }/>
                </div>
                <div className="status">
                    <label htmlFor="status-select">{PROGRAM_STATUS}</label>
                    <select value={status} id="status-select" onChange={(event) => {
                        setNewInformation({settings: {status: event.target.value}})
                    }}>
                        {statuses.map(item => {
                            return (<option key={item.code} value={item.code}>{item.lexeme}</option>)
                        })}
                    </select>
                </div>
            </div>
        </div>
        <div className="categories">
            <label htmlFor="select-categories">{CATEGORIES}</label>
            <Select options={allCategories}
                    value={getData.usedCategories({languageStore, dictionariesStore}, categoriesProg)} isMulti={true}
                    placeholder={ALL}
                    onChange={(event) => {
                        setNewInformation({settings: {categoriesProg: getData.getCategoryValues(event)}})
                    }}/>
        </div>
    </div>)
});

export default ProgramSettings;